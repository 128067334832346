import React from 'react'
import Typography from '@mui/material/Typography'

const H5 = ({ text, color, fontSize, fontWeight, style }) => {
  return (
    <Typography style={{
        fontSize: fontSize ?? 15,
        fontWeight: fontWeight ?? 300,
        color: color ?? '#FFF',
        ...style
    }}>
        {text}
    </Typography>
  )
}

export default H5