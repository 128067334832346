import React from 'react';

export default function Generic({ width }) {
	return (
		<svg width={width} viewBox="0 0 780 500" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M40 0h700c22 0 40 18 40 40v420c0 22-18 40-40 40H40c-22 0-40-18-40-40V40C0 18 18 0 40 0z"
				fill="#000C9D"
			/>
			<path fill="#9D9400" d="M67 107h110v100H67z" />
			<path
				d="M95 284v-4h-5v-11h-5v11H74l10-25-4-1-12 27v3h17v10h5v-10h5zm19-30c-4 0-8 1-11 4l3 3c2-2 5-4 8-4 4 0 8 3 8 7 0 5-4 7-8 7h-2l-1 4h3c5 0 9 2 9 7s-4 9-9 9c-3 0-7-2-9-4l-3 2c3 4 8 5 12 5 8 0 14-5 14-12 0-6-5-9-9-9 4-1 7-5 7-9 0-6-4-10-12-10zm35 0c-6 0-9 1-12 5l3 3c3-3 5-4 9-4s7 2 7 7c0 6-4 11-18 25v4h23l1-4h-19c13-12 18-18 18-25s-5-11-12-11zm49 36h-9v-36h-4l-12 7 2 4 9-6v31h-9v4h23v-4zm69-6v-4h-5v-11h-4l-1 11h-11l11-25-4-1-12 27v3h16v10h5v-10h5zm20-30c-4 0-8 1-12 4l3 3c3-2 5-4 8-4 5 0 8 3 8 7 0 5-4 7-8 7h-2v4h3c5 0 8 2 8 7s-3 9-9 9c-3 0-6-2-9-4l-3 2c3 4 8 5 12 5 9 0 14-5 14-12 0-6-4-9-9-9 4-1 8-5 8-9 0-6-5-10-12-10zm34 0c-5 0-9 1-12 5l3 3c3-3 5-4 9-4s7 2 7 7c0 6-3 11-18 25v4h23l1-4h-19c13-12 18-18 18-25s-4-11-12-11zm49 36h-8v-36h-5l-11 7 2 4 9-6v31h-10v4h23v-4zm69-6v-4h-5v-11h-4v11h-12l11-25-4-1-12 27v3h17v10h4v-10h5zm20-30c-4 0-8 1-11 4l2 3c3-2 5-4 9-4s7 3 7 7c0 5-3 7-7 7h-3v4h3c5 0 8 2 8 7s-3 9-8 9c-4 0-7-2-9-4l-3 2c3 4 7 5 12 5 8 0 13-5 13-12 0-6-4-9-9-9 5-1 8-5 8-9 0-6-5-10-12-10zm35 0c-6 0-9 1-13 5l4 3c2-3 4-4 8-4 5 0 7 2 7 7 0 6-3 11-18 25v4h24v-4h-18c13-12 17-18 17-25s-4-11-11-11zm48 36h-8v-36h-4l-12 7 2 4 9-6v31h-10v4h23v-4zm70-6v-4h-5v-11h-4l-1 11h-11l10-25-4-1-11 27v3h16v10h5v-10h5zm19-30c-4 0-7 1-11 4l3 3c2-2 5-4 8-4 4 0 8 3 8 7 0 5-4 7-8 7h-2l-1 4h4c4 0 8 2 8 7s-3 9-9 9c-3 0-7-2-9-4l-3 2c3 4 8 5 12 5 8 0 14-5 14-12 0-6-5-9-9-9 4-1 8-5 8-9 0-6-5-10-13-10zm35 0c-5 0-9 1-12 5l3 3c3-3 5-4 9-4s7 2 7 7c0 6-3 11-18 25v4h23l1-4h-19c13-12 18-18 18-25s-5-11-12-11zm49 36h-9v-36h-4l-12 7 3 4 9-6v31h-10v4h23v-4z"
				fill="#fff"
			/>
			<path
				d="M72 389h7v-22l-7 1v-3l7-2h4v26h6v4H72v-4zm26 0h6v-22l-7 1v-3l7-2h4v26h6v4H98v-4zm41 0h13v4h-18v-4a753 753 0 0114-18l-1-3-5-2-4 1-4 2v-4l5-2h3l8 2 2 6v4l-3 3a133 133 0 01-10 11zm34-12l4 2 2 5c0 3-1 5-3 7l-9 2h-4l-4-1v-4l4 1 4 1 6-2 2-4-2-4-5-1h-4v-4h4l4-1c2-1 2-2 2-3 0-2 0-3-2-4l-4-1-4 1-4 1v-4a38 38 0 018-1l7 2c2 1 3 3 3 5l-1 4-4 3zm26-14h15v4h-11v7a10 10 0 013-1c3 0 6 1 8 3s3 4 3 7-1 6-3 7c-2 2-5 3-9 3a23 23 0 01-7-1v-4l3 1 4 1 6-2c2-1 2-3 2-5l-2-5c-1-2-3-1-6-1a15 15 0 00-6 1v-15zm34 16l-5 1-1 4 1 4 5 2 4-2 2-4-2-4-4-1zm-4-2l-4-2-2-5c0-2 1-4 3-5l7-2 7 2 2 5-1 5-4 2c2 0 3 1 4 3l2 4c0 3-1 5-3 7l-7 2-8-2-2-7a7 7 0 016-7zm-2-6l2 3 4 2 4-2 1-3-1-4-4-1-4 1-2 4zM325 388h7v-22l-7 1v-3l7-2h4v26h6v4h-17v-4zm37-12l5 2 1 5c0 3-1 5-3 7l-8 2h-4l-4-1v-4l4 1 4 1 5-2c2-1 2-2 2-4s0-3-2-4l-5-1h-3v-4h4l4-1 2-3-2-4-5-1-3 1-4 1v-4a39 39 0 018-1l7 2c2 1 3 3 3 5l-2 5-4 2zm17 12h14v4h-19v-4a735 735 0 0115-18l-2-3-4-2-4 1-4 2v-4l4-2h4l7 2 3 6-1 4-2 3a129 129 0 01-11 11zm23 0h6v-22l-7 1v-3l7-2h4v26h7v4h-17v-4zm49-12l5 2 1 5c0 3-1 5-3 7l-8 2h-4l-4-1v-4l4 1 4 1 5-2c2-1 2-2 2-4l-1-4-5-1h-4v-4h4l4-1 2-3-2-4-4-1-4 1-4 1v-4a39 39 0 018-1l7 2c2 1 3 3 3 5l-2 5-4 2zm25-10l-10 15h10v-15zm-1-4h5v19h4v4h-4v7h-4v-7h-13v-4l12-19zm15 0h16v4h-12v7a10 10 0 013-1c4 0 6 1 8 3s3 4 3 7-1 6-3 8l-8 2a23 23 0 01-8-1v-4a15 15 0 008 2l5-2 2-5-2-5-5-1a15 15 0 00-7 1v-15zm26 0h15v4h-12v7a10 10 0 014-1c3 0 6 1 8 3s2 4 2 7l-2 8-9 2a23 23 0 01-7-1v-4a15 15 0 007 2l6-2 2-5-2-5-6-1a15 15 0 00-6 1v-15z"
				fill="#fff"
				fillOpacity=".8"
			/>
		</svg>
	);
}
