import React from 'react';
import { useDispatch } from 'react-redux';
import { addCustomerData } from '../../redux/reducers/customerReducer';
import styles from './styles.module.scss';

export default function Finish() {
	const dispatch = useDispatch();
	dispatch(addCustomerData({}));

	return (
		<div>
			<div className={styles.container}>
				<h1 className={styles.title}>Thanks for use Swaggin =)</h1>
				<p style={{ color: 'rgb(210, 206, 206)' }}>Your track has been added!</p>
			</div>
		</div>
	);
}
