import React from 'react';
import { Typography } from '@mui/material';

const ErrorText = ({ color, fontSize, fontWeight, fontFamily, style, ...props }) => {
	return (
		<Typography
			style={{
				color: color ? color : '#FF0000',
				fontSize: fontSize ? fontSize : '16px',
				fontWeight: fontWeight ? fontWeight : '300',
				fontFamily: fontFamily ? fontFamily : 'Inter',
				...style,
			}}
		>
			{props.children}
		</Typography>
	);
};

export default ErrorText;
