import * as React from 'react';
const DinersClub = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlSpace="preserve"
		style={{
			enableBackground: 'new 0 0 156 128',
		}}
		viewBox="0 0 156 128"
		{...props}
	>
		<defs>
			<path
				id="a"
				d="M14 30c0-3.3 2.7-6 6-6h116c3.3 0 6 2.7 6 6v68c0 3.3-2.7 6-6 6H20c-3.3 0-6-2.7-6-6V30z"
			/>
		</defs>
		<use
			xlinkHref="#a"
			style={{
				overflow: 'visible',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				fill: '#fff',
			}}
		/>
		<clipPath id="b">
			<use
				xlinkHref="#a"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M14 30c0-3.3 2.7-6 6-6h116c3.3 0 6 2.7 6 6v68c0 3.3-2.7 6-6 6H20c-3.3 0-6-2.7-6-6V30z"
			style={{
				clipPath: 'url(#b)',
				fill: '#fff',
				stroke: '#cbcbcb',
				strokeWidth: 2,
				strokeMiterlimit: 10,
			}}
		/>
		<defs>
			<path
				id="c"
				d="M39.9 79.8c0 1.4 1 1.5 1.8 1.5 3.8 0 5.1-2.9 5.1-5.5 0-3.3-2.1-5.7-5.5-5.7-.7 0-1.1.1-1.4.1v9.6zm-1.8-8.3c0-1.6-.8-1.5-1.6-1.5v-.5h5.2c4.6 0 7.1 3.1 7.1 6.2 0 1.7-1 6.1-7.3 6.1h-5v-.5c1.1-.1 1.6-.1 1.6-1.3v-8.5z"
			/>
		</defs>
		<clipPath id="d">
			<use
				xlinkHref="#c"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M31.5 64.5h22.2v22.3H31.5z"
			style={{
				clipPath: 'url(#d)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="e"
				d="M51.2 71.1c-.5 0-.9-.4-.9-.9 0-.4.4-.8.9-.8s.9.4.9.8c0 .5-.4.9-.9.9zm-1.9 10.3h.3c.5 0 .8 0 .8-.6V76c0-.8-.3-.9-.9-1.2v-.3c.8-.2 1.8-.6 1.9-.6.1-.1.2-.1.3-.1.1 0 .1.1.1.2v6.8c0 .6.4.6.9.6h.3v.5h-3.8v-.5z"
			/>
		</defs>
		<clipPath id="f">
			<use
				xlinkHref="#e"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M44.3 64.3h13.8v22.5H44.3z"
			style={{
				clipPath: 'url(#f)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="g"
				d="M54.8 76.1c0-.7-.2-.8-1-1.2v-.3c.8-.2 1.5-.5 2.3-.8.1 0 .1 0 .1.2v1.1c1-.7 1.9-1.3 3.1-1.3 1.5 0 2 1.1 2 2.5v4.6c0 .6.4.6.9.6h.3v.5h-3.8v-.5h.3c.5 0 .8 0 .8-.6v-4.6c0-1-.6-1.5-1.6-1.5-.6 0-1.5.5-2.1.8v5.2c0 .6.4.6.9.6h.3v.5h-3.8v-.5h.3c.5 0 .8 0 .8-.6v-4.7"
			/>
		</defs>
		<clipPath id="h">
			<use
				xlinkHref="#g"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M48.6 68.8h18.9v18.1H48.6z"
			style={{
				clipPath: 'url(#h)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="i"
				d="M67 76.4c.4 0 .5-.2.5-.4 0-.9-.5-1.6-1.5-1.6-1.1 0-1.8.8-2 2.1h3zm-3.2.6v1c.1 1.7 1.2 3.1 2.6 3.1 1 0 1.7-.5 2.4-1.2l.2.2c-.8 1.1-1.8 2-3.3 2-2.8 0-3.4-2.7-3.4-3.8 0-3.5 2.3-4.5 3.6-4.5 1.4 0 3 .9 3 2.8v.3l-.1.1h-5z"
			/>
		</defs>
		<clipPath id="j">
			<use
				xlinkHref="#i"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M57.4 68.8H74v18.3H57.4z"
			style={{
				clipPath: 'url(#j)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="k"
				d="M69.4 81.4h.5c.5 0 .8 0 .8-.6v-5c0-.5-.7-.7-.9-.8v-.3c1.3-.5 2-1 2.2-1 .1 0 .2.1.2.2v1.6c.4-.7 1.2-1.8 2.3-1.8.4 0 1 .3 1 .9 0 .5-.3.9-.8.9s-.5-.4-1.2-.4c-.3 0-1.3.4-1.3 1.5v4.1c0 .6.4.6.8.6h1v.5h-4.6v-.4"
			/>
		</defs>
		<clipPath id="l">
			<use
				xlinkHref="#k"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M64.4 68.8h16v18.1h-16z"
			style={{
				clipPath: 'url(#l)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="m"
				d="M76.2 79.4c.2 1.2.9 2.2 2.2 2.2 1 0 1.4-.6 1.4-1.3 0-2.1-3.8-1.4-3.8-4.3 0-1 .8-2.3 2.7-2.3.6 0 1.3.2 2 .5l.1 1.8h-.4c-.2-1.1-.8-1.7-1.9-1.7-.7 0-1.4.4-1.4 1.2 0 2.1 4.1 1.4 4.1 4.2 0 1.2-.9 2.4-3 2.4-.7 0-1.5-.2-2.2-.6l-.2-2 .4-.1"
			/>
		</defs>
		<clipPath id="n">
			<use
				xlinkHref="#m"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M70.8 68.8h15.4v18.3H70.8z"
			style={{
				clipPath: 'url(#n)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="o"
				d="M97.2 72.7h-.4c-.3-2.1-1.8-2.9-3.8-2.9-2 0-5 1.4-5 5.6 0 3.6 2.5 6.1 5.3 6.1 1.7 0 3.2-1.2 3.5-3.1l.4.1-.4 2.6c-.7.5-2.7.9-3.9.9-4.1 0-6.7-2.7-6.7-6.6 0-3.6 3.2-6.2 6.7-6.2 1.4 0 2.8.5 4.2.9l.1 2.6"
			/>
		</defs>
		<clipPath id="p">
			<use
				xlinkHref="#o"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M81.1 64.3h21.1v22.9H81.1z"
			style={{
				clipPath: 'url(#p)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="q"
				d="M97.8 81.4h.3c.5 0 .8 0 .8-.6V71c0-1.1-.3-1.2-.9-1.4v-.3c.7-.2 1.4-.5 1.8-.8.2-.1.3-.2.4-.2.1 0 .1.1.1.2v12.2c0 .6.4.6.9.6h.3v.5h-3.8v-.4"
			/>
		</defs>
		<clipPath id="r">
			<use
				xlinkHref="#q"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M92.8 63.4h13.8v23.5H92.8z"
			style={{
				clipPath: 'url(#r)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="s"
				d="M109.1 80.9c0 .3.2.3.5.3h.7v.4c-.8.1-2.2.4-2.5.5l-.1-.1v-1.4c-1.1.9-1.9 1.5-3.1 1.5-1 0-1.9-.6-1.9-2.1v-4.5c0-.5-.1-.9-1.1-1v-.3c.6 0 2-.1 2.3-.1.2 0 .2.1.2.5v4.6c0 .5 0 2 1.5 2 .6 0 1.4-.5 2.1-1.1v-4.8c0-.4-.8-.5-1.5-.7v-.3c1.6-.1 2.6-.2 2.8-.2.1 0 .1.1.1.3v6.5"
			/>
		</defs>
		<clipPath id="t">
			<use
				xlinkHref="#s"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M96.6 69h18.8v18.1H96.6z"
			style={{
				clipPath: 'url(#t)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="u"
				d="M112.7 79.9c0 .7.6 1.8 1.8 1.8 1.9 0 2.7-1.9 2.7-3.4 0-1.9-1.4-3.5-2.8-3.5-.7 0-1.2.4-1.7.8v4.3zm0-4.8c.7-.6 1.7-1.3 2.6-1.3 2 0 3.3 1.8 3.3 3.7 0 2.3-1.7 4.6-4.2 4.6-1.3 0-2-.4-2.5-.6l-.5.4-.4-.2c.2-1 .2-2.1.2-3.1V71c0-1.1-.3-1.2-.9-1.4v-.3c.7-.2 1.4-.5 1.8-.8.2-.1.3-.2.4-.2.1 0 .1.1.1.2v6.6z"
			/>
		</defs>
		<clipPath id="v">
			<use
				xlinkHref="#u"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M105.3 63.4h18.3v23.8h-18.3z"
			style={{
				clipPath: 'url(#v)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="w"
				d="M36.5 92.1h.1c.3 0 .7 0 .7-.5v-5c0-.5-.4-.5-.7-.5h-.1v-.3h2.9v.2h-.1c-.3 0-.7 0-.7.5v5c0 .5.4.5.7.5h.1v.3h-2.9v-.2"
			/>
		</defs>
		<clipPath id="x">
			<use
				xlinkHref="#w"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M31.5 80.7h12.9v16.7H31.5z"
			style={{
				clipPath: 'url(#x)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="y"
				d="M39.4 92.4v-.2h-.1c-.3 0-.8-.1-.8-.6v-5c0-.5.4-.6.8-.6h.1v-.2h-2.9v.2h.1c.3 0 .8.1.8.6v5c0 .5-.4.6-.8.6h-.1v.2h1.4c.5-.1 1 0 1.5 0zm0 0h-2.9V92h.2c.3 0 .7 0 .7-.5v-5c0-.5-.3-.5-.7-.5h-.2v-.4h2.9v.4h-.2c-.3 0-.7 0-.7.5v5c0 .5.3.5.7.5h.2v.4z"
			/>
		</defs>
		<clipPath id="z">
			<use
				xlinkHref="#y"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M31.5 80.7h13v16.8h-13z"
			style={{
				clipPath: 'url(#z)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="A"
				d="M46 90.5v-3.6c0-.8-.5-.9-.8-.9H45v-.3h2.5v.3h-.1c-.4 0-.9.1-.9 1.2v4.4c0 .3 0 .7.1 1h-.4L41.3 87v4c0 .8.2 1.1.9 1.1h.2v.3h-2.5v-.3h.1c.7 0 .9-.5.9-1.2v-4c0-.5-.4-.9-.9-.9h-.1v-.3h2l4.1 4.8"
			/>
		</defs>
		<clipPath id="B">
			<use
				xlinkHref="#A"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M34.8 80.7h17.7v16.9H34.8z"
			style={{
				clipPath: 'url(#B)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="C"
				d="M46 90.5zm0 0zm.2 2h.3c0-.3-.1-.6-.1-.9v-4.4c0-1.2.5-1.3.9-1.3h.1v-.2H45v.2h.2c.3 0 .9.1.9.9v3.6l-4.3-4.8h-2v.2h.1c.5 0 .9.3.9.9v4c0 .8-.2 1.3-.9 1.3h-.1v.2h2.5V92h-.1c-.8 0-.9-.3-.9-1.2v-4.1l4.9 5.8zm.4.1h-.4l-4.9-5.4V91c0 .8.1 1.1.9 1.1h.2v.4h-2.5v-.4h.2c.6 0 .8-.4.8-1.2v-4c0-.5-.4-.8-.8-.8h-.2v-.4h2l4.2 4.8V87c0-.8-.5-.9-.8-.9H45v-.4h2.5v.3h-.2c-.4 0-.8 0-.8 1.2v4.4c.1.3.1.7.1 1z"
			/>
		</defs>
		<clipPath id="D">
			<use
				xlinkHref="#C"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M34.7 80.7h17.8v16.9H34.7z"
			style={{
				clipPath: 'url(#D)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="E"
				d="M49 86.2c-.7 0-.7.2-.9.9h-.3c0-.3.1-.5.1-.8 0-.3.1-.5.1-.8h.2c.1.3.3.3.6.3h5c.3 0 .5 0 .5-.3h.2c0 .3-.1.5-.1.8v.8l-.3.1c0-.4-.1-.9-.7-.9h-1.6v5.1c0 .7.3.8.8.8h.2v.3h-3.2v-.3h.2c.5 0 .8 0 .8-.8v-5.1H49"
			/>
		</defs>
		<clipPath id="F">
			<use
				xlinkHref="#E"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M42.8 80.4h16.7v17H42.8z"
			style={{
				clipPath: 'url(#F)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="G"
				d="M52.7 92.4h-3.2V92h.2c.5 0 .7 0 .8-.8v-5.1H49V86h1.6v5.2c0 .8-.3.8-.8.8h-.1v.2h1.6c.4.1 1 .2 1.4.2v-.3h-.1c-.5 0-.8-.1-.8-.9V86h1.6c.6 0 .7.6.7.9l.2-.1V86c0-.2.1-.5.1-.7h-.1c0 .3-.3.3-.6.3h-5c-.2 0-.5 0-.6-.3H48c0 .3 0 .5-.1.8 0 .3-.1.5-.1.8h.2c.1-.7.2-.9.9-.9v.1c-.7 0-.7.1-.9.8h-.4c0-.3.1-.5.1-.8 0-.3.1-.5.1-.8h.3c.1.2.2.2.5.2h5c.3 0 .5 0 .5-.3h.3c0 .3-.1.5-.1.8v.8l-.3.4v-.1c0-.4-.1-.9-.7-.9h-1.5v5.1c0 .7.3.8.8.8h.2l-.1.3"
			/>
		</defs>
		<clipPath id="H">
			<use
				xlinkHref="#G"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M42.7 80.4h16.8v17.1H42.7z"
			style={{
				clipPath: 'url(#H)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="I"
				d="M54.8 92.1h.1c.3 0 .7 0 .7-.5v-5c0-.5-.4-.5-.7-.5h-.1v-.3h4.9v1.5l-.3.1c0-.6-.2-1.1-1.2-1.1h-1.3v2.5H58c.6 0 .7-.3.8-.8h.2v2.2l-.3.1c-.1-.6-.1-1-.7-1h-1.2v2.2c0 .6.6.6 1.2.6 1.2 0 1.7-.1 2-1.2l.3.1-.3 1.5h-5.2v-.4"
			/>
		</defs>
		<clipPath id="J">
			<use
				xlinkHref="#I"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M49.8 80.7h15.5v16.7H49.8z"
			style={{
				clipPath: 'url(#J)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="K"
				d="m59.9 92.4.3-1.5H60c-.3 1.1-.8 1.2-2 1.2-.6 0-1.2 0-1.2-.7v-2.3H58c.7 0 .7.4.8.9h.2v-2.2h-.2c-.1.5-.2.9-.8.8h-1.2V86h1.4c1 0 1.2.5 1.2 1.1l.2-.1v-1.4H54.9v.2h.1c.3 0 .8.1.8.6v5c0 .5-.4.6-.8.6h-.1v.2h2.5c.8.1 1.9.2 2.5.2zm0 0h-5.2V92h.2c.3 0 .7 0 .7-.5v-5c0-.5-.3-.5-.7-.5h-.2v-.4h4.9v1.5l-.3.1c-.1-.6-.1-1.1-1.1-1.1h-1.3v2.4H58c.6 0 .7-.3.7-.8h.4v2.2l-.3.1c-.1-.6-.1-.9-.7-.9H57v2.2c0 .6.5.6 1.1.6 1.2 0 1.6-.1 1.9-1.1l.3.1c-.1.5-.3 1-.4 1.5z"
			/>
		</defs>
		<clipPath id="L">
			<use
				xlinkHref="#K"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M49.7 80.7h15.6v16.8H49.7z"
			style={{
				clipPath: 'url(#L)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="M"
				d="M62.8 88.9h.5c1 0 1.5-.4 1.5-1.5 0-.9-.5-1.4-1.4-1.4h-.5v2.9zm-1.3-2.2c0-.7-.4-.7-.7-.7h-.2v-.3h3c1.2 0 2.3.3 2.3 1.7 0 .9-.6 1.4-1.3 1.7l1.6 2.5c.3.4.5.5.9.6v.3h-1.8c-.7-1-1.4-2-2-3.1h-.6v2.1c0 .7.3.8.8.8h.2v.3H60.6v-.3h.2c.4 0 .7-.2.7-.5v-5.1z"
			/>
		</defs>
		<clipPath id="N">
			<use
				xlinkHref="#M"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M55.7 80.7h16.6v16.7H55.7z"
			style={{
				clipPath: 'url(#N)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="O"
				d="M63.2 88.9c1 0 1.4-.3 1.4-1.5 0-.8-.5-1.4-1.4-1.4h-.5v2.8h.5zm-.4.1v-2.9h.6c.9 0 1.4.6 1.4 1.4 0 1.1-.6 1.5-1.5 1.5h-.5zm0 .3h.7c.6 1.1 1.3 2.1 2 3.1h1.8v-.2c-.5-.1-.7-.2-.9-.6l-1.7-2.5c.8-.3 1.3-.8 1.3-1.7 0-1.3-1-1.6-2.2-1.6h-2.9v.2h.1c.3 0 .7.1.7.8v4.9c0 .4-.4.6-.7.6h-.1v.2h3v-.2h-.1c-.4 0-.8-.1-.8-.8l-.2-2.2zm4.4 3.1h-1.8c-.7-1-1.4-2-2-3.1h-.6v2c0 .7.3.7.7.7h.2v.4H60.6V92h.2c.3 0 .6-.2.6-.5v-4.9c0-.7-.3-.7-.6-.7h-.2v-.4h3c1.2 0 2.3.3 2.3 1.7 0 .9-.6 1.4-1.3 1.7l1.6 2.4c.3.4.4.5.9.6l.1.5z"
			/>
		</defs>
		<clipPath id="P">
			<use
				xlinkHref="#O"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M55.6 80.7h16.6v16.8H55.6z"
			style={{
				clipPath: 'url(#P)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="Q"
				d="M73.8 90.5v-3.6c0-.8-.5-.9-.8-.9h-.2v-.3h2.5v.3h-.1c-.4 0-.9.1-.9 1.2v4.4c0 .3 0 .7.1 1H74L69 87v4c0 .8.2 1.1.9 1.1h.2v.3h-2.5v-.3h.1c.7 0 .9-.5.9-1.2v-4c0-.5-.4-.9-.9-.9h-.1v-.3h2l4.2 4.8"
			/>
		</defs>
		<clipPath id="R">
			<use
				xlinkHref="#Q"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M62.5 80.7h17.7v16.9H62.5z"
			style={{
				clipPath: 'url(#R)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="S"
				d="M73.7 90.5zm.1 0zm.2 2h.3c0-.3-.1-.6-.1-.9v-4.4c0-1.2.5-1.3.9-1.3h.1v-.2H72.8v.2h.2c.3 0 .9.1.9.9v3.6l-4.3-4.8h-2v.2h.1c.5 0 .9.3.9.9v4c0 .8-.2 1.3-.9 1.3h-.1v.2h2.5V92H70c-.8 0-.9-.3-.9-1.2v-4.1l4.9 5.8zm.3.1h-.4L69 87.1V91c0 .8.1 1.1.9 1.1h.2v.4h-2.5v-.4h.2c.6 0 .8-.4.8-1.2v-4c0-.5-.4-.8-.8-.8h-.2v-.4h2l4.2 4.8V87c0-.8-.5-.9-.8-.9h-.3v-.4h2.5v.3H75c-.4 0-.8 0-.8 1.2v4.4c.1.3.1.6.1 1z"
			/>
		</defs>
		<clipPath id="T">
			<use
				xlinkHref="#S"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M62.5 80.7h17.8v16.9H62.5z"
			style={{
				clipPath: 'url(#T)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="U"
				d="m78.2 86.8-.9 2.7h1.8l-.9-2.7zM76.8 91c-.1.3-.2.6-.2.7 0 .3.4.3.7.3h.1v.3h-2.2V92h.1c.4 0 .7-.2.8-.6l1.5-4.4c.1-.4.3-.8.4-1.2.3-.1.7-.3.9-.4h.2c0 .1.1.2.1.2l1.8 5c.1.3.2.7.4 1 .1.3.3.4.6.4h.1v.3h-2.7V92h.1c.2 0 .6 0 .6-.3 0-.1-.1-.4-.2-.7l-.6-1h-2.2l-.3 1z"
			/>
		</defs>
		<clipPath id="V">
			<use
				xlinkHref="#U"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M70.2 80.5h16.7v16.9H70.2z"
			style={{
				clipPath: 'url(#V)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="W"
				d="M78.2 86.8zm-.8 2.7H79l-.8-2.6-.8 2.6zm-.2 0 .9-2.8h.1l.9 2.8h-1.9zm2 2.9h2.7v-.2c-.3 0-.6-.1-.7-.4-.1-.3-.2-.6-.4-1l-1.8-5c0-.1-.1-.2-.1-.2h-.1c-.2.1-.6.3-.9.4-.1.4-.2.8-.4 1.2L76 91.6c-.1.4-.5.7-.9.7v.2h2.1v-.2h-.1c-.3 0-.8 0-.8-.4 0-.2.1-.4.2-.8l.3-1H79l.4 1.1c.1.3.2.6.2.7 0 .3-.4.3-.6.3h-.1v.2zm2.7 0h-2.7V92h.1c.2 0 .6 0 .6-.2 0-.1-.1-.4-.2-.7l-.4-1.1h-2.1l-.3 1c-.1.3-.2.6-.2.7 0 .2.4.3.7.3h.1v.4h-2.2V92h.1c.4 0 .7-.2.8-.6l1.5-4.4c.1-.4.3-.8.4-1.2.3-.1.7-.3.9-.4h.1s.1 0 .1.1.1.2.1.2l1.8 5c.1.3.2.7.4 1 .1.3.3.4.6.4H82l-.1.3z"
			/>
		</defs>
		<clipPath id="X">
			<use
				xlinkHref="#W"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M70.2 80.4H87v17H70.2z"
			style={{
				clipPath: 'url(#X)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="Y"
				d="M82.3 86.2c-.7 0-.7.2-.9.9h-.3c0-.3.1-.5.1-.8 0-.3.1-.5.1-.8h.2c.1.3.3.3.6.3h5c.3 0 .5 0 .5-.3h.2c0 .3-.1.5-.1.8v.8l-.3.1c0-.4-.1-.9-.7-.9h-1.6v5.1c0 .7.3.8.8.8h.2v.3h-3.2v-.3h.2c.5 0 .8 0 .8-.8v-5.1h-1.6"
			/>
		</defs>
		<clipPath id="Z">
			<use
				xlinkHref="#Y"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M76.2 80.4h16.7v17H76.2z"
			style={{
				clipPath: 'url(#Z)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="aa"
				d="M86.1 92.4h-3.2V92h.2c.5 0 .7 0 .8-.8v-5.1h-1.5V86H84v5.2c0 .8-.3.8-.8.8H83v.2h1.6c.5.1 1.1.2 1.5.2v-.3H86c-.5 0-.8-.1-.8-.9V86h1.6c.6 0 .7.6.7.9l.2-.1V86c0-.2.1-.5.1-.7h-.1c0 .3-.3.3-.6.3h-5c-.2 0-.5 0-.6-.3h-.2c0 .3 0 .5-.1.8 0 .3-.1.5-.1.8h.2c.1-.7.2-.9.9-.9v.1c-.7 0-.7.1-.9.8h-.4c0-.3.1-.5.1-.8 0-.3.1-.5.1-.8h.3c.1.2.2.2.5.2h5c.3 0 .5 0 .5-.3h.3c0 .3-.1.5-.1.8v.8l-.3.1v-.1c0-.4-.1-.9-.7-.9h-1.5V91c0 .7.3.8.8.8h.2v.6"
			/>
		</defs>
		<clipPath id="ab">
			<use
				xlinkHref="#aa"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M76.1 80.4h16.8v17.1H76.1z"
			style={{
				clipPath: 'url(#ab)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="ac"
				d="M88.2 92.1h.1c.3 0 .7 0 .7-.5v-5c0-.5-.4-.5-.7-.5h-.1v-.3h2.9v.2H91c-.3 0-.7 0-.7.5v5c0 .5.4.5.7.5h.1v.3h-2.9v-.2"
			/>
		</defs>
		<clipPath id="ad">
			<use
				xlinkHref="#ac"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M83.2 80.7h12.9v16.7H83.2z"
			style={{
				clipPath: 'url(#ad)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="ae"
				d="M91.1 92.4v-.2H91c-.3 0-.8-.1-.8-.6v-5c0-.5.4-.6.8-.6h.1v-.2h-2.9v.2h.1c.3 0 .8.1.8.6v5c0 .5-.4.6-.8.6h-.1v.2h1.4c.5-.1 1 0 1.5 0zm0 0h-2.9V92h.2c.3 0 .7 0 .7-.5v-5c0-.5-.3-.5-.7-.5h-.2v-.4h2.9v.4H91c-.4 0-.7 0-.7.5v5c0 .5.3.5.7.5h.2l-.1.4z"
			/>
		</defs>
		<clipPath id="af">
			<use
				xlinkHref="#ae"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M83.2 80.7h13v16.8h-13z"
			style={{
				clipPath: 'url(#af)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="ag"
				d="M95.1 92.1c1.9 0 2.2-1.6 2.2-3s-.8-3.1-2.4-3.1c-1.7 0-2.2 1.5-2.2 2.8.1 1.7.9 3.3 2.4 3.3m-.1-6.5c2 0 3.7 1.3 3.7 3.3 0 2.2-1.6 3.7-3.6 3.7s-3.6-1.4-3.6-3.4c-.1-2.1 1.5-3.6 3.5-3.6"
			/>
		</defs>
		<clipPath id="ah">
			<use
				xlinkHref="#ag"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M86.4 80.6h17.2v17H86.4z"
			style={{
				clipPath: 'url(#ah)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="ai"
				d="M92.7 88.8c0-1.3.5-2.8 2.2-2.8 1.6 0 2.4 1.7 2.4 3.1 0 1.4-.3 3.1-2.2 3.1v-.1c1.8 0 2.1-1.6 2.1-3s-.7-3.1-2.3-3.1c-1.6 0-2.1 1.5-2.2 2.8.1 1.7.9 3.3 2.4 3.3v.1c-1.6 0-2.4-1.7-2.4-3.4m-1.3.3c0-2 1.6-3.6 3.6-3.6v.1c-2 0-3.5 1.5-3.6 3.5.1 2 1.6 3.4 3.6 3.4s3.6-1.4 3.6-3.6c0-2-1.6-3.3-3.6-3.3v-.1c2.1 0 3.7 1.3 3.7 3.4 0 2.2-1.6 3.7-3.7 3.7-2 0-3.6-1.4-3.6-3.5"
			/>
		</defs>
		<clipPath id="aj">
			<use
				xlinkHref="#ai"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M86.4 80.5h17.3v17.1H86.4z"
			style={{
				clipPath: 'url(#aj)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="ak"
				d="M105.1 90.5v-3.6c0-.8-.5-.9-.8-.9h-.2v-.3h2.5v.3h-.1c-.4 0-.9.1-.9 1.2v4.4c0 .3 0 .7.1 1h-.4l-4.9-5.5v4c0 .8.2 1.1.9 1.1h.2v.3H99v-.3c.7 0 .9-.5.9-1.2v-4c0-.5-.4-.9-.9-.9h-.1v-.3h2l4.2 4.7"
			/>
		</defs>
		<clipPath id="al">
			<use
				xlinkHref="#ak"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M93.9 80.7h17.7v16.9H93.9z"
			style={{
				clipPath: 'url(#al)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="am"
				d="M105.1 90.5zm0 0zm.2 2h.3c0-.3-.1-.6-.1-.9v-4.4c0-1.2.5-1.3.9-1.3h.1v-.2H104.1v.2h.2c.3 0 .9.1.9.9v3.6l-4.3-4.8h-2v.2h.1c.5 0 .9.3.9.9v4c0 .8-.2 1.3-.9 1.3h-.1v.2h2.5V92h-.1c-.8 0-.9-.3-.9-1.2v-4.1l4.9 5.8zm.4.1h-.4l-4.9-5.4V91c0 .8.1 1.1.9 1.1h.2v.4H99v-.4c.6 0 .8-.4.8-1.2v-4c0-.5-.4-.8-.8-.8h-.2v-.4h2l4.2 4.8V87c0-.8-.5-.9-.8-.9h-.3v-.4h2.5v.3h-.2c-.4 0-.8 0-.8 1.2v4.4c.3.3.3.7.3 1z"
			/>
		</defs>
		<clipPath id="an">
			<use
				xlinkHref="#am"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M93.8 80.7h17.8v16.9H93.8z"
			style={{
				clipPath: 'url(#an)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="ao"
				d="m109.6 86.8-.9 2.7h1.8l-.9-2.7zm-1.4 4.2c-.1.3-.2.6-.2.7 0 .3.4.3.7.3h.1v.3h-2.2V92h.1c.4 0 .7-.2.8-.6L109 87c.1-.4.3-.8.4-1.2.3-.1.7-.3.9-.4h.2c0 .1.1.2.1.2l1.8 5c.1.3.2.7.4 1 .1.3.3.4.6.4h.1v.3h-2.7V92h.1c.2 0 .6 0 .6-.3 0-.1-.1-.4-.2-.7l-.4-1.1h-2.2l-.5 1.1z"
			/>
		</defs>
		<clipPath id="ap">
			<use
				xlinkHref="#ao"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M101.6 80.5h16.7v16.9h-16.7z"
			style={{
				clipPath: 'url(#ap)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="aq"
				d="M109.5 86.8zm-.8 2.7h1.7l-.8-2.6-.9 2.6zm-.1 0 .9-2.8.9 2.8h-1.8zm2 2.9h2.7v-.2c-.3 0-.6-.1-.7-.4-.1-.3-.2-.6-.4-1l-1.8-5c0-.1-.1-.2-.1-.2h-.1c-.2.1-.6.3-.9.4-.1.4-.2.8-.4 1.2l-1.5 4.4c-.1.4-.5.7-.9.7v.2h2.1v-.2h-.1c-.3 0-.8 0-.8-.4 0-.2.1-.4.2-.8l.3-1h2.2l.4 1.1c.1.3.2.6.2.7 0 .3-.4.3-.6.3h-.1v.2zm2.7 0h-2.7V92h.1c.2 0 .6-.1.6-.2s-.1-.4-.2-.7l-.4-1.1h-2.1l-.3 1c-.1.3-.2.6-.2.7 0 .2.4.3.7.3h.1v.4h-2.2V92h.1c.4 0 .7-.2.8-.6l1.5-4.4c.1-.4.3-.8.4-1.2.3-.1.7-.3.9-.4h.1s.1 0 .1.1.1.2.1.2l1.8 5c.1.3.2.7.4 1 .1.3.3.4.6.4h.1l-.3.3z"
			/>
		</defs>
		<clipPath id="ar">
			<use
				xlinkHref="#aq"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M101.5 80.4h16.8v17h-16.8z"
			style={{
				clipPath: 'url(#ar)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="as"
				d="M115.7 91.5c0 .4.3.5.6.5h1.3c.4 0 .7-.3.9-.5.2-.2.2-.5.3-.7h.3c-.1.5-.2 1.1-.4 1.6h-5v-.3h.1c.3 0 .7 0 .7-.6v-4.9c0-.5-.4-.5-.7-.5h-.1v-.3h3v.2h-.2c-.4 0-.7 0-.7.5v5"
			/>
		</defs>
		<clipPath id="at">
			<use
				xlinkHref="#as"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M108.6 80.7H124v16.7h-15.4z"
			style={{
				clipPath: 'url(#at)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="au"
				d="M118.7 92.4c.1-.5.2-1 .3-1.6h-.2c-.1.2-.1.5-.3.7-.2.2-.5.5-1 .5h-1.2c-.3 0-.6-.2-.6-.6v-5c0-.5.3-.6.7-.6h.2v-.2h-2.9v.2h.1c.3 0 .8.1.8.6v4.9c0 .6-.4.7-.8.7h-.1v.2h2.5c.8.1 1.6.2 2.5.2zm0 0h-5V92h.2c.3 0 .7 0 .7-.6v-4.9c0-.5-.3-.5-.7-.5h-.2v-.4h3v.4h-.3c-.4 0-.6 0-.6.5v5c0 .4.2.5.5.5h1.2c.4 0 .7-.3.9-.5.1-.2.2-.5.3-.7h.4v.1c-.1.4-.3 1-.4 1.5z"
			/>
		</defs>
		<clipPath id="av">
			<use
				xlinkHref="#au"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M108.6 80.7h15.5v16.8h-15.5z"
			style={{
				clipPath: 'url(#av)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="aw"
				d="M118.8 86.4h.1c.1 0 .2-.1.2-.3 0-.2-.1-.2-.2-.2h-.1v.5zm-.4.6.1-.1v-.7c0-.1 0-.1-.1-.1V86h.5c.2 0 .3.1.3.3 0 .2-.1.3-.2.3l.2.2.2.2h-.2c-.1 0-.2-.2-.4-.5h-.1v.3c0 .1 0 .1.1.1h-.4zm.5.3c.5 0 .8-.4.8-.8s-.3-.8-.8-.8-.8.4-.8.8.4.8.8.8zm0-1.9c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1c0-.5.5-1 1-1z"
			/>
		</defs>
		<clipPath id="ax">
			<use
				xlinkHref="#aw"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M112.9 80.4h12v12h-12z"
			style={{
				clipPath: 'url(#ax)',
				fill: '#1a1919',
			}}
		/>
		<defs>
			<path
				id="ay"
				d="M58.9 51.8c0-9 7.3-16.3 16.3-16.3s16.3 7.3 16.3 16.3-7.3 16.3-16.3 16.3-16.3-7.3-16.3-16.3"
			/>
		</defs>
		<clipPath id="az">
			<use
				xlinkHref="#ay"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M53.9 30.5h42.5V73H53.9z"
			style={{
				clipPath: 'url(#az)',
				fill: '#fffffe',
			}}
		/>
		<defs>
			<path
				id="aA"
				d="M75.2 68.3c-9.3 0-17-7.5-17-16.7 0-10 7.7-17 17-17h4.4c9.2 0 17.6 6.9 17.6 17 0 9.2-8.4 16.7-17.6 16.7h-4.4zm.1-32.3c-8.5 0-15.4 6.9-15.4 15.4s6.9 15.4 15.4 15.4 15.4-6.9 15.4-15.4S83.8 36 75.3 36zm-3.5 24.6V42.3c-3.7 1.4-6.3 5-6.3 9.1 0 4.2 2.6 7.8 6.3 9.2zM85 51.5c0-4.2-2.6-7.7-6.3-9.1v18.3c3.7-1.5 6.3-5.1 6.3-9.2z"
			/>
		</defs>
		<clipPath id="aB">
			<use
				xlinkHref="#aA"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M53.2 29.6h49v43.7h-49z"
			style={{
				clipPath: 'url(#aB)',
				fill: '#184977',
			}}
		/>
	</svg>
);
export default DinersClub;
