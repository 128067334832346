import { Box, CircularProgress, Container, Grid, Zoom } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import Input from '../../components/input/Input';
import Header from '../../components/header/Header';
import SongCard from '../../components/cards/SongCard';
import clone from 'just-clone';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-material-ui-carousel';
import { addTrackData } from '../../redux/reducers/trackReducer';
import VoidDialog from '../../components/dialog/VoidDialog';
import GeneralDialog from '../../components/dialog/GeneralDialog';
import { t } from 'i18next';
import H2 from '../../components/text/H2';
import H6 from '../../components/text/H6';
import { getFormatMMSS } from '../../utils/format-time';
import styles from './styles.module.scss';
import { ReactComponent as SwagginLogo } from '../../assets/svg/swaggin-searcher.svg';
import ActionButtons from '../../components/button/ActionButtons';
import http from '../../http/http';
import { checkInvalidParam } from '../../utils/check-invalid-param';
import H5 from '../../components/text/H5';

const AISearch = () => {
	const [size, setSize] = useState(window.innerWidth);
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useDispatch();
	const [inputValue, setInputValue] = useState('');
	const [tracks, setTracks] = useState([]);
	const [showSelectedTracksModal, setShowSelectedTracksModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedTracks, setSelectedTracks] = useState([]);
	const [showMissingTracksModal, setShowMissingTracksModal] = useState(false);
	const [showLimitTracksModal, setShowLimitTracksModal] = useState(false);
	const [inputTouch, setInputTouch] = useState(false);
	const [btnDisabled, setBtnDisabled] = useState(false);
	const [invalidSearch, setInvalidSearch] = useState(false);
	const { commerceData } = useSelector(state => state.commerce);
	const inputRef = useRef();

	const searchText = [
		t('AISEARCH_search_text_1'),
		t('AISEARCH_search_text_2'),
		t('AISEARCH_search_text_3'),
		t('AISEARCH_search_text_4'),
	];

	const modalTitleStyle = {
		fontWeight: '600',
	};

	const modalSubtitleStyle = {
		fontWeight: '300',
		fontSize: '22px',
	};

	const buttonStyle = {
		fontSize: size < 429 ? '16' : '24px',
	};

	useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	useEffect(() => {
		if (selectedTracks.length === 0) {
			setShowSelectedTracksModal(false);
		}
	}, [selectedTracks.length]);

	const onHandleSubmit = () => {
		setTracks([]);
		setBtnDisabled(true);
		setShowErrorMessage(false);
		setInvalidSearch(false);
		setErrorMessage('');
		if (inputValue.length === 0) {
			setShowErrorMessage(true);
			setErrorMessage(t('AISEARCH_error_text'));
			return;
		}
		setLoading(true);
		const path = `openai/recomendations`;
		http.instance
			.post(path, { content: inputValue, commerceId: params.commerceId })
			.then(res => {
				const tracksMapped = res.data.result.map(track => {
					const alreadySelectedTrack =
						commerceData.currentProvider === 'Spotify'
							? selectedTracks.find(
									selectedTrack => selectedTrack.spotifyId === track.spotifyId,
							  )
							: selectedTracks.find(
									selectedTrack => selectedTrack.youtubeId === track.youtubeId,
							  );
					if (alreadySelectedTrack) {
						return { ...track, isSelected: true };
					} else {
						return { ...track, isSelected: false };
					}
				});
				setLoading(false);
				setBtnDisabled(false);
				setTracks(tracksMapped);

				if (res.data.message === 'invalid/search') {
					setInvalidSearch(true);
				}
			})
			.catch(error => {
				setBtnDisabled(false);
				setLoading(false);
				setShowErrorMessage(true);
				setErrorMessage(t('AISEARCH_error_text'));
				setTracks([]);
				// if (error?.response?.data?.code === 'invalid/search') {
				// 	setShowErrorMessage(true);
				// 	setErrorMessage(t('AISEARCH_error_text'));
				// 	setTracks([]);
				// 	return;
				// }
				checkInvalidParam(error);
			});
	};

	const onCancelHandler = () => {
		const unSelectedTracks = tracks.map(track =>
			track.isSelected ? { ...track, isSelected: false } : { ...track },
		);
		setTracks(unSelectedTracks);
		setSelectedTracks([]);
	};

	const onSelectHandler = (songId, selectedTracks) => {
		const tracksUpdated = clone(tracks);
		const selectedTracksCopy = clone(selectedTracks);

		const search = tracks => {
			return commerceData.currentProvider === 'Spotify'
				? tracks.findIndex(track => track.spotifyId === songId)
				: tracks.findIndex(track => track.youtubeId === songId);
		};

		const filter = tracks => {
			return commerceData.currentProvider === 'Spotify'
				? tracks.filter(track => track.spotifyId !== songId)
				: tracks.filter(track => track.youtubeId !== songId);
		};

		const updateHandlerSelectedTracks = () => {
			tracksUpdated[trackSelectedIndex].isSelected =
				!tracksUpdated[trackSelectedIndex].isSelected;

			setTracks(tracksUpdated);

			let updatedSelectedTracks = [];

			if (tracksUpdated[trackSelectedIndex].isSelected) {
				updatedSelectedTracks = [...selectedTracksCopy, tracksUpdated[trackSelectedIndex]];
			} else {
				updatedSelectedTracks = filter(selectedTracksCopy);
			}
			setSelectedTracks(updatedSelectedTracks);
		};
		const trackSelectedIndex = search(tracks);

		const isAlreadyAdded = search(selectedTracks) !== -1;

		if (
			(selectedTracks.length + 1 <= 3 && !isAlreadyAdded) ||
			((selectedTracks.length + 1 <= 3 || selectedTracks.length + 1 > 3) && isAlreadyAdded)
		) {
			updateHandlerSelectedTracks();
		} else {
			setShowLimitTracksModal(true);
		}
	};

	const onDelete = songId => {
		const selectedTracksCopy = clone(selectedTracks);
		const updatedSelectedTracks =
			commerceData.currentProvider === 'Spotify'
				? selectedTracksCopy.filter(track => songId !== track.spotifyId)
				: selectedTracksCopy.filter(track => songId !== track.youtubeId);
		setSelectedTracks(updatedSelectedTracks);

		const tracksCopy = clone(tracks);
		const trackIndex =
			commerceData.currentProvider === 'Spotify'
				? tracksCopy.findIndex(track => track.spotifyId === songId)
				: tracksCopy.findIndex(track => track.youtubeId === songId);

		if (trackIndex !== -1) {
			tracksCopy[trackIndex].isSelected = false;
			setTracks(tracksCopy);
		}
	};

	const onSubmitHandler = async () => {
		const tracksCopy = clone(selectedTracks);
		const trackMapped = tracksCopy.map(track => {
			return {
				artist: track.artist,
				explicit: track.explicit,
				image: track.image,
				ms: track?.duration ?? track?.ms,
				name: track.name,
				uri: track.uri,
				...(track.spotifyId && { spotifyId: track.spotifyId }),
				...(track.youtubeId && { youtubeId: track.youtubeId }),
			};
		});
		dispatch(addTrackData(trackMapped));
		const haveRecentPhone = localStorage.getItem('phone');
		if (haveRecentPhone) {
			const path = `otp/verification/sms`;

			const code = await http.instance.post(path, { phone: haveRecentPhone });

			navigate(`/confirm-phone/${params.commerceId}`, {
				state: {
					isLinearPath: true,
					phone: localStorage.getItem('phone'),
					amount: 0,
					mode: 'free',
					code: code.data.result,
					isSwagginAI: true,
				},
			});
		} else {
			navigate(`/checkout/${params.commerceId}`, {
				state: {
					isLinearPath: true,
					amount: 0,
					mode: 'free',
					isSwagginAI: true,
				},
			});
		}
	};

	const time = () => {
		setTimeout(() => {});
	};

	const AISubtitles = ({ text }) => {
		return (
			<Typography
				sx={{
					color: '#FFFFFF',
					fontWeight: 700,
					fontSize: '18px',
				}}
				textAlign="center"
			>
				{text}
			</Typography>
		);
	};

	return (
		<Container style={{ marginTop: size < 429 ? 30 : 80, width: '100%', paddingBottom: 100 }}>
			<VoidDialog
				open={showSelectedTracksModal}
				crossButtonAction={() => setShowSelectedTracksModal(false)}
				handleClose={() => setShowSelectedTracksModal(false)}
				title={t('SEARCH_selected_body')}
			>
				<div style={{ width: '100%' }}>
					{selectedTracks.map((track, index) => (
						<SongCard
							key={index}
							songId={track?.spotifyId ? track.spotifyId : track.youtubeId}
							name={track.name}
							artist={track.artist}
							image={track.image}
							duration={''}
							large={true}
							selectable={false}
							canDelete={true}
							onDeleteHandler={onDelete}
						/>
					))}
				</div>
			</VoidDialog>
			<GeneralDialog
				open={showMissingTracksModal}
				button1Text={t('BUTTONS_text1')}
				button2Text={t('BUTTONS_text2')}
				title={t('SEARCH_add_minimun_songs_alert_title')}
				subtitle={t('SEARCH_add_minimun_songs_alert_subtitle')}
				titleStyle={modalTitleStyle}
				subtitleStyle={{
					marginBottom: '27px',
					lineHeight: '29.05px',
					...modalSubtitleStyle,
				}}
				button1Style={buttonStyle}
				button2Style={buttonStyle}
				crossButtonAction={() => setShowMissingTracksModal(false)}
				button1Action={() => setShowMissingTracksModal(false)}
				button2Action={() => setShowMissingTracksModal(false)}
			/>

			<GeneralDialog
				open={showLimitTracksModal}
				button1Text={t('BUTTONS_text_accept')}
				title={t('SEARCH_dialog_limit_tracks_title')}
				subtitle={t('SEARCH_dialog_limit_tracks_subtitle')}
				titleStyle={{ marginBottom: '25px', ...modalTitleStyle }}
				subtitleStyle={{ marginBottom: '20px', ...modalSubtitleStyle }}
				button1Style={buttonStyle}
				button2Style={buttonStyle}
				crossButtonAction={() => setShowLimitTracksModal(false)}
				button1Action={() => setShowLimitTracksModal(false)}
				handleClose={() => setShowLimitTracksModal(false)}
				dialogPaperStyle={{ width: '750px' }}
			/>
			<Header onClick={() => navigate(`/mode/${params.commerceId}`)} />
			<Box marginX="auto">
				<Box
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Box
						className="heartBeat"
						style={{
							background: '#183363',
							width: 195,
							height: 195,
							marginBottom: 40,
							borderRadius: '50%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							animationPlayState: loading ? 'paused' : 'running',
						}}
					/>

					<Box
						onClick={!btnDisabled ? onHandleSubmit : () => {}}
						style={{
							background: '#202c41',
							width: 200,
							height: 200,
							marginBottom: 40,
							borderRadius: '50%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							cursor: !btnDisabled ? 'pointer' : 'auto',
							position: 'absolute',
						}}
					>
						{loading ? (
							<img src={require('../../assets/swag-load.gif')} alt=":p" />
						) : (
							<SwagginLogo />
						)}
					</Box>
				</Box>

				<div className={styles.searchInputContainer} style={{ marginTop: 0 }}>
					<Grid container>
						<Grid item xs={12} sx={{ paddingBottom: '15px' }}>
							{!loading && !inputTouch && tracks.length === 0 ? (
								<AISubtitles text={t('AISEARCH_subtitle1')} />
							) : null}
							{!loading && inputTouch && tracks.length === 0 ? (
								<AISubtitles text={t('AISEARCH_subtitle2')} />
							) : null}
							{loading ? (
								<Carousel
									autoPlay={true}
									indicators={false}
									animation="slide"
									duration={1500}
								>
									{searchText.map((text, index) => (
										<AISubtitles key={index} text={text} />
									))}
								</Carousel>
							) : null}

							{invalidSearch ? (
								<AISubtitles text={t('AISEARCH_search_not_found')} />
							) : (
								<>
									{!loading &&
									tracks.length > 0 &&
									selectedTracks.length === 0 ? (
										<AISubtitles text={t('AISEARCH_search_found_text')} />
									) : null}
								</>
							)}

							{!loading && tracks.length > 0 && selectedTracks.length > 0 ? (
								<AISubtitles text={t('AISEARCH_search_select_text')} />
							) : null}
						</Grid>
						<Grid item xs={12}>
							<Input
								label={''}
								placeholder={t('AISEARCH_placeholder')}
								inputRef={inputRef}
								inputStyle={{
									width: '100%',
									height: size < 429 ? '45px' : '',
									paddingTop: '20px',
									fontSize: size < 429 ? '18px' : '24px',
									borderRadius: 20,
								}}
								rightIcon={
									inputValue.length > 0
										? require('../../assets/Cancel.png')
										: require('../../assets/Search.png')
								}
								onClickRightIcon={
									inputValue.length > 0
										? () => {
												setInputValue('');
												inputRef.current.value = '';
										  }
										: () => null
								}
								rightIconStyle={
									size < 429 ? { width: '16px', height: '16px', top: 15 } : {}
								}
								onClickInput={() => setInputTouch(true)}
								onChange={event => {
									setInputValue(event.target.value);
								}}
								onKeyDown={e => {
									if (e.keyCode === 13) {
										onHandleSubmit();
									} else {
										setShowErrorMessage(false);
									}
								}}
							></Input>
						</Grid>
					</Grid>
				</div>

				{showErrorMessage && (
					<>
						<H6
							fontSize={size < 429 ? 12 : null}
							style={{ color: 'red', marginTop: 15 }}
							text={errorMessage}
						></H6>
						<div style={{ paddingLeft: 20 }}>
							<H5
								fontSize={size < 429 ? 12 : null}
								style={{ color: 'red', marginTop: 15 }}
								text={'- Hoy me siento muy feliz'}
							></H5>
							<H5
								fontSize={size < 429 ? 12 : null}
								style={{ color: 'red', marginTop: 15 }}
								text={'- Me siento lleno de gloria y alegria'}
							></H5>
						</div>
					</>
				)}
				{selectedTracks.length > 0 && (
					<div
						style={{
							marginTop: 23,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							paddingRight: 35,
						}}
					>
						<div onClick={() => setShowSelectedTracksModal(true)}>
							<H2
								text={`${t('SEARCH_selected_body')} ${selectedTracks.length}`}
								fontSize={size < 429 ? 15 : null}
							/>
						</div>
						<div style={{ cursor: 'pointer' }}>
							<img
								src={require('../../assets/PlaylistArrow.png')}
								onClick={() => setShowSelectedTracksModal(true)}
							/>
						</div>
					</div>
				)}

				{!showErrorMessage && (
					<div
						style={{
							marginTop: 50,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							verticalAlign: 'center',
						}}
					>
						{loading ? (
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<CircularProgress
									color="primary"
									size={30}
									style={{ marginTop: 5 }}
								/>
							</div>
						) : (
							<>
								{tracks.map((track, index) => (
									<SongCard
										key={index}
										songId={
											track?.spotifyId ? track.spotifyId : track.youtubeId
										}
										name={track.name}
										artist={track.artist}
										duration={getFormatMMSS(track.duration)}
										description={track.description}
										image={track.image}
										large={true}
										isSelected={track.isSelected}
										isDisabled={false}
										onSelectHandler={trackId => {
											onSelectHandler(trackId, selectedTracks);
										}}
									/>
								))}
							</>
						)}

						{selectedTracks.length > 0 ? (
							<div className={styles.buttonsContainer}>
								<ActionButtons
									acceptText={`${selectedTracks.length} ${t(
										'TEXT_CONNECTOR1',
									)} 3`}
									cancelText={t('BUTTONS_text2')}
									onAccept={() => {
										if (selectedTracks.length === 3) {
											onSubmitHandler();
										} else {
											setShowMissingTracksModal(true);
										}
									}}
									onCancel={onCancelHandler}
									width={size < 429 ? 170 : 300}
								/>
							</div>
						) : null}
					</div>
				)}
			</Box>
		</Container>
	);
};

export default AISearch;
