import { eventBus } from './event-bus';

export const checkInvalidParam = error => {
	if (error.response?.status === 500 || error.response?.status === 400) {
		if (
			Array.isArray(error.response?.data?.message) &&
			error.response?.data?.message.includes('invalid-param')
		) {
			eventBus.dispatch('invalid-param');
		}
	}
};
