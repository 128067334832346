import * as React from 'react';
const SVGPhoneSMS = props => (
	<svg xmlns="http://www.w3.org/2000/svg" width={229} height={234} fill="none" {...props}>
		<path
			fill="#fff"
			d="M107.25 229.898c-.259 0-.792.032-1.568.032-1.115 0-2.311-.032-3.556-.097h-.436c-1.132 0-2.715-.161-5.059-.436a96.329 96.329 0 0 1-13.608-2.713c-10.166-2.795-25.358-8.722-40.033-21.11l-.26-.21a203.636 203.636 0 0 1-2.165-1.89c-12.218-10.692-21.98-24.227-28.235-39.102l-.065-.145c-.678-1.389-1.163-2.795-1.729-4.41l-1.002-2.842h.016a106.87 106.87 0 0 1-5.51-33.902c0-58.952 47.984-106.905 106.975-106.905 2.36 0 4.768.08 7.322.274l4.687-6.04c3.119-4.022 7.822-6.332 12.913-6.332 3.006 0 5.964.84 8.534 2.407l72.47 44.497a15.51 15.51 0 0 1 5.964 6.686c1.616 2.326 5.398 10.224-4.057 24.566-1.002 1.519-2.925 4.297-5.867 8.48a106.444 106.444 0 0 1 5.027 32.383c0 58.952-47.985 106.906-106.977 106.906l-3.781-.081v-.016Z"
		/>
		<path
			fill="#fff"
			d="M135.939 8.076c2.198 0 4.428.597 6.416 1.809l72.47 44.496a11.438 11.438 0 0 1 4.558 5.298s5.463 6.057-3.927 20.286c-1.148 1.744-3.588 5.25-6.966 10.046a102.612 102.612 0 0 1 5.462 33.046c0 56.82-46.094 102.867-102.935 102.867l-3.831-.08s-.533.032-1.503.032c-.856 0-2.068-.016-3.588-.113 0 0-29.204-.226-56.502-23.29-.032-.033-.065-.049-.097-.081-.049-.032-.097-.081-.145-.113-.712-.598-1.423-1.228-2.118-1.841-11.701-10.24-21.075-23.081-27.184-37.649-.032-.065-.065-.129-.08-.194-.744-1.55-1.213-2.972-1.908-4.942a102.742 102.742 0 0 1-5.98-34.612c0-56.82 46.094-102.868 102.936-102.868 3.087 0 6.141.162 9.18.436l6.012-7.769a12.291 12.291 0 0 1 9.73-4.764Zm0-8.076c-6.352 0-12.219 2.875-16.114 7.898l-3.378 4.36a103.834 103.834 0 0 0-5.43-.144C49.795 12.114 0 61.892 0 123.056c0 11.273 1.697 22.385 5.026 33.078l-.048.064 1.454 4.151.275.791c.582 1.648 1.115 3.198 1.94 4.91 6.48 15.393 16.598 29.38 29.253 40.459.711.63 1.455 1.276 2.214 1.922l.178.146h.016l.032.048.065.065c28.138 23.775 58.15 25.083 61.367 25.147 1.374.081 2.683.114 3.895.114.727 0 1.261 0 1.6-.033l3.572.081h.162c61.221 0 111.016-49.778 111.016-110.943 0-10.757-1.551-21.401-4.622-31.69 2.327-3.326 3.911-5.62 4.8-6.96 10.198-15.457 6.513-25.051 4.283-28.637-1.616-3.424-4.186-6.266-7.434-8.269L146.589 3.004A20.337 20.337 0 0 0 135.939 0Z"
		/>
		<path
			fill="#1C223A"
			d="M111.553 225.925c56.85 0 102.936-46.056 102.936-102.868S168.403 20.189 111.553 20.189c-56.85 0-102.936 46.056-102.936 102.868s46.086 102.868 102.936 102.868Z"
		/>
		<path
			fill="#15212E"
			d="M219.923 59.68s5.463 6.056-3.927 20.285c-9.39 14.23-104.439 145.976-104.439 145.976l-3.83-.081L219.923 59.695v-.016Z"
		/>
		<path
			fill="#15202E"
			d="M14.594 157.669 126.742 12.84c3.846-4.958 10.796-6.234 16.146-2.939l72.47 44.497c5.673 3.488 7.208 11.063 3.329 16.474L107.719 225.86s-59.04 3.489-91.218-63.232c-.744-1.551-1.212-2.972-1.907-4.943v-.016Z"
		/>
		<path
			fill="#183560"
			d="M16.5 162.611 129.731 21.74a8.053 8.053 0 0 1 10.635-1.728l67.056 43.156c3.847 2.487 4.865 7.672 2.214 11.402L102.628 225.764s-29.367-.21-56.745-23.484c-10.942-9.287-20.962-20.642-29.399-39.668h.016Z"
		/>
		<path
			fill="#162444"
			d="M138.624 138.441c14.319-22.972 11.812-50.38-5.598-61.218-17.41-10.838-43.132-1.002-57.45 21.969-14.32 22.971-11.814 50.379 5.597 61.217 17.41 10.839 43.132 1.003 57.451-21.968Z"
		/>
		<path
			fill="#1F8C89"
			d="M137.113 133.149c14.422-23.138 11.466-51.014-6.603-62.262-18.07-11.249-44.41-1.61-58.833 21.527-14.423 23.138-11.466 51.014 6.603 62.262 18.07 11.249 44.41 1.611 58.833-21.527Z"
		/>
		<path
			fill="#063"
			d="m72.219 150.87 6.707 4.199 6.125.808-12.832-5.007ZM130.398 70.823l-5.156-3.214 3.184 3.02 1.972.194Z"
		/>
		<path
			fill="#2FB5B6"
			d="M132.206 130.101c14.423-23.138 11.467-51.014-6.603-62.263-18.069-11.248-44.41-1.61-58.832 21.528-14.423 23.138-11.467 51.013 6.603 62.262 18.07 11.249 44.41 1.61 58.832-21.527Z"
		/>
		<path
			fill="#fff"
			d="M76.307 130.874a4.144 4.144 0 0 1-2.036-.549 4.025 4.025 0 0 1-1.988-3.36l-.695-24.097a4.042 4.042 0 0 1 3.927-4.151c2.214-.049 4.089 1.696 4.154 3.924l.5 17.654 47.42-24.776a4.025 4.025 0 0 1 5.447 1.712 4.024 4.024 0 0 1-1.714 5.443l-53.14 27.764a4.012 4.012 0 0 1-1.875.452v-.016Z"
		/>
	</svg>
);
export default SVGPhoneSMS;
