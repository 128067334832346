import './App.css';
import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import MainRoutes from './navigation/MainRoutes';
import { analytics, appCheck, getTokenFCM, onMessageListener } from './firebaseInit';
import { getToken } from 'firebase/app-check';
import { eventBus } from './utils/event-bus';
import { addTokensFCM } from './redux/reducers/customerReducer';
import { useDispatch } from 'react-redux';
import WebFont from 'webfontloader';
import NotConnectionPage from './pages/issues/NotConnectionPage';
import { Detector } from 'react-detect-offline';
import { useTranslation } from 'react-i18next';
import tokenSingleton from './http/tokenSingleton';
import { addToken } from './redux/reducers/appCheckReducer';
import { logEvent } from 'firebase/analytics';

function App() {
	const { t } = useTranslation();
	const [notification, setNotificacion] = React.useState({});
	const [isInvalidParam, setIsInvalidParam] = React.useState(false);
	const [appCheckToken, setAppCheckToken] = React.useState('');
	const dispatch = useDispatch();

	getTokenFCM()
		.then(result => {
			dispatch(addTokensFCM([result]));
		})
		.catch(error => console.log(error));
	onMessageListener();

	React.useEffect(() => {
		getToken(appCheck)
			.then(res => {
				dispatch(addToken(res.token));
				tokenSingleton.instance.setToken(res.token);
				setAppCheckToken(res.token);
			})
			.catch(error => console.log('error', error));
	}, []);

	React.useEffect(() => {
		eventBus.on('new-notification', data => {
			setNotificacion(data.notification);
		});
	}, []);

	React.useEffect(() => {
		eventBus.on('invalid-param', async () => {
			// setIsInvalidParam(true);
			setIsInvalidParam(false);
		});

		return () => {
			setIsInvalidParam(false);
		};
	}, []);

	React.useEffect(() => {
		if (Object.keys(notification).length > 0) {
			alert(notification.body);
		}
	}, [notification]);

	React.useEffect(() => {
		logEvent(analytics, 'SwagginAppWebWeb_ScanQRtheclient', {
			tenantId: process.env.REACT_APP_TENANT_ID,
		});
	}, []);

	React.useEffect(() => {
		WebFont.load({
			google: {
				families: ['Inter'],
			},
		});
	}, []);

	/* React.useEffect(() => {
		window.addEventListener(
			'popstate',
			() => {
				window.history.forward();
			},
			false,
		);
	}, []); */

	return (
		<div className="App">
			{appCheckToken ? (
				isInvalidParam ? (
					<div
						style={{
							width: '100%',
							height: '100vh',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							paddingTop: '10%',
						}}
					>
						<h1 style={{ color: '#6d7d8e' }}>{t('RESOURCE_NOT_FOUND_message')}</h1>
					</div>
				) : (
					<Detector
						polling={{ url: 'https://ipv4.icanhazip.com/' }}
						render={({ online }) =>
							online ? (
								<MainRoutes></MainRoutes>
							) : (
								<NotConnectionPage></NotConnectionPage>
							)
						}
					/>
				)
			) : (
				<></>
			)}
		</div>
	);
}

export default App;
