import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import valid from 'card-validator';
import { PatternFormat } from 'react-number-format';
import { Checkbox, Container, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from '../../components/button/Button';
import styles from './styles.module.scss';
import CreditCardIcon from '../../components/credit-card-icon/CreditCardIcon';
import Input from '../../components/input/Input';
import Header from '../../components/header/Header';
import H3 from '../../components/text/H3';
import { colors } from '../../utils/colors';
import OTPdialog from '../../components/dialog/OTPdialog';
import Discount_Icon from '../../assets/Discount_Icon.svg';
import Check_Discount from '../../assets/Check_Discount.svg';
import { useSelector } from 'react-redux';
import http from '../../http/http';
import GeneralDialog from '../../components/dialog/GeneralDialog';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebaseInit';
import { ReactComponent as QuestionIcon } from '../../assets/svg/question_icon.svg';
import { ReactComponent as CVVIcon } from '../../assets/svg/cvv.svg';
import VoidDialog from '../../components/dialog/VoidDialog';

export default function ProcessPayment() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [size, setSize] = React.useState(window.innerWidth);
	const [showLoader] = React.useState(false);
	const location = useLocation();
	const params = useParams();
	const [cardNumber, setCardNumber] = React.useState('');
	const [cardExpiricy, setCardExpiricy] = React.useState('');
	const [cardCVV, setCardCVV] = React.useState('');
	const [cardType, setCardType] = React.useState('');
	const [cardName, setCardName] = React.useState('');
	const [tokenizeCard, setTokenizeCard] = React.useState(false);
	const [cardAddress, setCardAddress] = React.useState('');
	const [cardPostal, setCardPostal] = React.useState('');
	const [discountModal, setDiscountModal] = React.useState(false);
	const [discountCode, setDiscountCode] = React.useState('');
	const [discountPercentage, setDiscountPercentage] = React.useState(0);
	const [discountActive, setDiscountActive] = React.useState(location.state.discountActive);
	const [discountError, setDiscountError] = React.useState(false);
	const [discountErrorText, setDiscountErrorText] = React.useState();
	const { customerData } = useSelector(state => state.customer);
	const [amount, setAmount] = React.useState(location.state.amount);
	const [promoId, setPromoId] = React.useState(null);
	const [discountValidModal, setDiscountValidModal] = React.useState(false);
	const [showCVV, setShowCVV] = React.useState(false);
	const { trackData } = useSelector(state => state.track);

	const onClickCancelHandler = () => {
		logEvent(analytics, 'cloudJukebox_cancel_payment', {
			tenantId: process.env.REACT_APP_TENANT_ID,
		});
		navigate(`../home/${params.commerceId}`, { replace: true });
	};

	const onChangeCardHandler = async event => {
		const cardNumber = event.target.value.replaceAll(' ', '');
		if (cardNumber.length >= 14 && cardNumber.length <= 19) {
			setFieldTouched('cardNumber', true);
			const info = valid.number(event.target.value);
			setFieldValue('cardNumber', cardNumber);
			if (info.isValid) {
				setCardType(info.card.type);
			} else {
				setCardType('');
			}
		} else {
			setCardType('');
		}

		if (cardNumber.length === 0) {
			setCardType('');
		}
	};

	const ProcessPaymentSchema = Yup.object().shape({
		cardNumber: Yup.string()
			.required(t('ERRORTEXT1'))
			.min(14, t('ERRORTEXT3'))
			.test('cardNumber', t('ERRORTEXT7'), function (value) {
				const info = valid.number(value);
				if (info.isValid) {
					return true;
				} else {
					return false;
				}
			}),
		cardName: Yup.string()
			.required(t('ERRORTEXT1'))
			.matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/, t('ERRORTEXT2'))
			.trim(),
		cardExpiricy: Yup.string()
			.required(t('ERRORTEXT1'))
			.min(5, t('VERIFYPAYMENT_declined_wrong_expiration')),
		cardCVV: Yup.string()
			.required(t('ERRORTEXT1'))
			.min(3, t('VERIFYPAYMENT_declined_wrong_cvv')),
		cardAddress: Yup.string().required(t('ERRORTEXT1')).trim(),
		cardPostal: Yup.string()
			.required(t('ERRORTEXT1'))
			.min(4, t('VERIFYPAYMENT_declined_wrong_postal')),
	});

	const onSubmitHandler = async values => {
		const body = {
			amount: amount,
			expiry: values.cardExpiricy,
			account: values.cardNumber,
			name: values.cardName.trim(),
			cvv2: values.cardCVV.trim(),
			address: values.cardAddress,
			postal: values.cardPostal,
		};

		navigate(`/verify-payment/${params.commerceId}`, {
			state: {
				...body,
				packId: location.state.packId,
				isLinearPath: true,
				tokenizeCard,
				cardType,
				discountActive,
				isNotTokenized: true,
				...(promoId && { promoId }),
			},
		});
	};

	const {
		setFieldTouched,
		values,
		setFieldValue,
		handleSubmit,
		errors,
		setErrors,
		touched,
		validateForm,
	} = useFormik({
		initialValues: {
			cardNumber: '',
			cardName: '',
			cardExpiricy: '',
			cardCVV: '',
			cardAddress: '',
			cardPostal: '',
		},
		onSubmit: onSubmitHandler,
		validationSchema: ProcessPaymentSchema,
	});

	useEffect(() => {
		if (!location.state || location.state.isLinearPath !== true) {
			//para validar que el usuario está entrando a través del flujo de la aplicació
			navigate(`/home/${params.commerceId}`);
		} else {
			setCardNumber(location.state.account);
			setFieldValue('cardNumber', location.state.account);
			setCardExpiricy(location.state.expiry);
			setFieldValue('cardExpiricy', location.state.expiry);
			setCardCVV(location.state.cvv2);
			setFieldValue('cardCVV', location.state.cvv2);
			setCardName(location.state.name);
			setFieldValue('cardName', location.state.name);
			setCardAddress(location.state.address);
			setFieldValue('cardAddress', location.state.address);
			setCardPostal(location.state.postal);
			setFieldValue('cardPostal', location.state.postal);

			location.state.tokenizeCard && setTokenizeCard(location.state.tokenizeCard);
			location.state.cardType && setCardType(location.state.cardType);
		}
	}, [location.state]);

	const checkDiscountCode = async () => {
		const dCodeSplited = discountCode.match(/.{1,4}/g);
		const dCode = `${dCodeSplited[0]}-${dCodeSplited[1]}`;

		try {
			const checkDiscountPath = `promotional-code/check`;

			const res = await http.instance.post(checkDiscountPath, {
				code: dCode,
				commerceId: params.commerceId,
				packId: location.state.packId,
				phone:
					Object.keys(customerData).length > 0
						? customerData.phone
						: localStorage.getItem('phone'),
			});

			if (res.data.result) {
				const amountWithDiscount = +amount - (+amount * res.data.result.discount) / 100;
				setAmount(amountWithDiscount.toFixed(2));
				setDiscountModal(false);
				setDiscountActive(true);
				setPromoId(res.data.result.promoId);
				setDiscountPercentage(res.data.result.discount);
				setDiscountModal(false);
				setDiscountValidModal(true);
			} else {
				setDiscountError(true);
				setDiscountErrorText(t('ERRORTEXT5'));
				setDiscountError(true);
				setTimeout(() => {
					setDiscountError(false);
				}, 5000);
			}
		} catch (error) {
			setDiscountError(true);
			if (error.response.data.message) {
				setDiscountErrorText(error.response.data.message);
			} else {
				setDiscountErrorText(t('ERRORTEXT5'));
			}
			setTimeout(() => {
				setDiscountError(false);
			}, 5000);
		}
	};

	return (
		<Container
			style={{ marginTop: size < 429 ? 30 : 80, width: '100%', maxWidth: '80%' }}
			maxWidth=""
		>
			<OTPdialog
				size={size}
				limit={451}
				open={discountModal}
				handleClose={() => setDiscountModal(false)}
				crossButtonAction={() => setDiscountModal(false)}
				icon={Discount_Icon}
				title={t('VERIFYPAYMENT_dialog_discount_title')}
				buttonText={t('BUTTONS_text_accept')}
				buttonAction={checkDiscountCode}
				code={discountCode}
				onChange={text => {
					setDiscountCode(text);
				}}
				isError={discountError}
				isErrorText={discountErrorText}
			/>

			<GeneralDialog
				open={discountValidModal}
				handleClose={() => setDiscountValidModal(false)}
				crossButtonAction={() => setDiscountValidModal(false)}
				button1Text={t('BUTTONS_text_accept')}
				icon={Check_Discount}
				title={t('VERIFYPAYMENT_dialog_success_discount_title')}
				titleStyle={{
					marginTop: 3,
				}}
				subtitle={`${discountPercentage}%`}
				subtitleStyle={{ marginBottom: 3 }}
				button1Action={() => setDiscountValidModal(false)}
			/>

			<VoidDialog
				open={showCVV}
				crossButtonAction={() => setShowCVV(false)}
				handleClose={() => setShowCVV(false)}
				title={t('PROCESSPAYMENT_cvv_modal_title')}
				dialogStyle={{ height: size < 541 ? '300px' : '400px' }}
				dialogPaperStyle={{ marginTop: size < 541 ? '100%' : '25%' }}
				childrenStyles={{ height: '60%' }}
			>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ width: '25%' }}>
						<CVVIcon
							style={{
								width: size < 541 ? 100 : 150,
								height: size < 541 ? 100 : 150,
							}}
						/>
					</div>
					<div style={{ width: '65%' }}>
						<Typography
							style={{
								fontSize: size < 541 ? 14 : 24,
								fontWeight: 400,
								color: '#FFF',
								textAlign: size < 541 ? 'center' : 'left',
								marginBottom: size < 541 ? 10 : 0,
							}}
						>
							{t('PROCESSPAYMENT_cvv_modal_description')}
						</Typography>
					</div>
				</div>
			</VoidDialog>

			<Header
				title={t('PROCESSPAYMENT_title')}
				size={size}
				onClick={() => navigate(`/home/${params.commerceId}`)}
			/>

			<div className={styles.container} style={{ maxWidth: '100%' }}>
				<Grid container columnSpacing={12} style={{ justifyContent: 'center' }}>
					{trackData.map(track => (
						<Grid item xs={6} md={4} lg={2}>
							<div style={{ display: 'flex' }}>
								<img src={track.image} width={'100%'}></img>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div style={{ width: '100%' }}>
									<p
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											fontWeight: 'bold',
											marginBottom: 0,
										}}
									>
										{track.name}
									</p>
									<p
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										{track.artist}
									</p>
								</div>
								<div
									style={{
										marginTop: 5,
										marginLeft: 5,
									}}
								>
									<img
										src={require('../../assets/Check.png')}
										height={'40%'}
									></img>
								</div>
							</div>
						</Grid>
					))}
				</Grid>
				<Grid container columnSpacing={5}>
					<Grid item xs={12} lg={6}>
						<Input
							size={size}
							label={t('PROCESSPAYMENT_form_input_card_holder')}
							inputContainerStyle={{
								marginBottom: errors.cardName && touched.cardName > 0 ? 0 : 30,
							}}
							inputStyle={{
								fontSize: size < 429 ? 18 : 24,
								fontWeight: 300,
								height: size < 429 ? '45px' : '',
							}}
							onChange={e => {
								if (e.target.value.length === 1) {
									setFieldTouched('cardName', true);
								}
								let capitalizedValue = e.target.value.toUpperCase();
								const withoutAccents = capitalizedValue
									.normalize('NFD')
									.replace(/[\u0300-\u036f]/g, '');

								setFieldValue('cardName', withoutAccents);
							}}
							onBlur={() => {
								setFieldTouched('cardName', true);
							}}
							placeholder={'Juan H'}
							showError={errors.cardName && touched.cardName}
							errorLabel={errors.cardName}
							value={values.cardName}
						/>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Input
							autocompleteGoogleMaps={true}
							size={size}
							label={t('PROCESSPAYMENT_form_input_address')}
							inputContainerStyle={{
								marginBottom:
									errors.cardAddress && touched.cardAddress > 0 ? 0 : 30,
							}}
							inputStyle={{
								fontSize: size < 429 ? 18 : 24,
								fontWeight: 300,
								height: size < 429 ? '45px' : '',
							}}
							onChange={value => {
								if (value.length === 1) {
									setFieldTouched('cardAddress', true);
								}

								setFieldValue('cardAddress', value);
							}}
							onBlur={() => {
								setFieldTouched('cardAddress', true);
							}}
							placeholder={'246 NW 5th St, Miami, FL Estados Unidos.'}
							showError={errors.cardAddress && touched.cardAddress}
							errorLabel={errors.cardAddress}
							value={values.cardAddress}
						/>
					</Grid>
					<Grid item xs={12} lg={6}>
						<div
							style={{
								width: '100%',
								display: 'flex',
								margin: '0 auto',
								flexDirection: 'column',
								marginBottom: errors.cardNumber && touched.cardNumber > 0 ? 0 : 30,
							}}
						>
							<label
								htmlFor=""
								style={{
									color: '#FFF',
									margin: 0,
									textAlign: 'left',
									marginBottom: '5px',
									fontSize: size < 429 ? 16 : 24,
									fontWeight: 400,
								}}
							>
								{t('PROCESSPAYMENT_form_input_account')}
							</label>
							<div
								style={{
									border: '1px solid #d9d9d9',
									width: '100%',
									padding: 8,
									borderRadius: '5px',
									display: 'flex',
									height: size < 429 ? '45px' : '73px',
								}}
							>
								<PatternFormat
									value={cardNumber}
									format="#### #### #### #### ###"
									placeholder="1111 2222 3333 4444 555"
									onInput={onChangeCardHandler}
									style={{
										fontSize: size < 429 ? 18 : 24,
										fontWeight: 300,
										border: 0,
										backgroundColor: 'transparent',
										width: '100%',
										color: '#FFF',
										marginLeft: 15,
									}}
									onBlur={() => {
										setFieldTouched('cardNumber', true);
									}}
								/>
								<div style={{ marginTop: size < 429 ? 0 : 5 }}>
									<CreditCardIcon brand={cardType} width={size < 429 ? 35 : 70} />
								</div>
							</div>
							{errors.cardNumber && touched.cardNumber ? (
								<p
									style={{
										color: 'red',
										fontSize: size < 429 ? 12 : 18,
										fontWeight: '300',
										marginTop: 5,
									}}
								>
									{errors.cardNumber}
								</p>
							) : null}
						</div>
					</Grid>

					<Grid item xs={12} lg={6}>
						<div
							style={{
								width: '100%',
								display: 'flex',
								margin: '0 auto',
								flexDirection: 'column',
								marginBottom: errors.cardNumber && touched.cardNumber > 0 ? 0 : 30,
							}}
						>
							<label
								htmlFor=""
								style={{
									color: '#FFF',
									margin: 0,
									textAlign: 'left',
									marginBottom: '5px',
									fontSize: size < 429 ? 16 : 24,
									fontWeight: 400,
								}}
							>
								{t('PROCESSPAYMENT_form_input_postal')}
							</label>
							<div
								style={{
									border: '1px solid #d9d9d9',
									width: '100%',
									padding: 8,
									borderRadius: '5px',
									display: 'flex',
									height: size < 429 ? '45px' : '73px',
								}}
							>
								<PatternFormat
									value={values.cardPostal}
									format="#####"
									placeholder="33101"
									onInput={e => {
										if (e.target.value.length === 4) {
											setFieldTouched('cardPostal', true);
										}
										if (e.target.value.length < 6) {
											setFieldValue('cardPostal', e.target.value);
										}
									}}
									style={{
										fontSize: size < 429 ? 18 : 24,
										fontWeight: 300,
										border: 0,
										backgroundColor: 'transparent',
										width: '100%',
										color: '#FFF',
										marginLeft: 15,
									}}
									onBlur={() => {
										setFieldTouched('cardPostal', true);
									}}
								/>
							</div>
							{errors.cardPostal && touched.cardPostal ? (
								<p
									style={{
										color: 'red',
										fontSize: size < 429 ? 12 : 18,
										fontWeight: '300',
										marginTop: 5,
									}}
								>
									{errors.cardPostal}
								</p>
							) : null}
						</div>
					</Grid>

					<Grid
						container
						item
						lg={6}
						justifyContent="flex-start"
						alignItems="flex-start"
						columnSpacing={5}
					>
						<Grid item xs={12} lg={5}>
							<div
								style={{
									width: '100%',
									display: 'flex',
									margin: '0 auto',
									flexDirection: 'column',
									marginBottom:
										errors.cardExpiricy && touched.cardExpiricy > 0 ? 0 : 30,
								}}
							>
								<label
									htmlFor=""
									style={{
										color: '#FFF',
										margin: 0,
										textAlign: 'left',
										marginBottom: '5px',
										fontSize: size < 429 ? 16 : 24,
										fontWeight: 400,
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										overflow: 'hidden',
									}}
								>
									{t('PROCESSPAYMENT_form_input_expiration')}
								</label>
								<div
									style={{
										border: '1px solid #d9d9d9',
										width: '100%',
										padding: 8,
										borderRadius: '5px',
										display: 'flex',
										height: size < 429 ? '45px' : '73px',
									}}
								>
									<PatternFormat
										format="##/##"
										placeholder="12/25"
										value={cardExpiricy}
										onInput={e => {
											const cardExpiricy = e.target.value.replaceAll(' ', '');

											if (cardExpiricy.length === 5) {
												setFieldTouched('cardExpiricy', true);
											}

											if (cardExpiricy.length < 6) {
												setFieldValue('cardExpiricy', cardExpiricy);
											}
										}}
										onBlur={() => {
											setFieldTouched('cardExpiricy', true);
										}}
										style={{
											width: '100%',
											borderRadius: '5px',
											border: 'none',
											backgroundColor: 'transparent',
											fontSize: size < 429 ? 16 : 24,
											fontWeight: 300,
											color: '#FFF',
										}}
									/>
								</div>
								{errors.cardExpiricy && touched.cardExpiricy ? (
									<p
										style={{
											color: 'red',
											fontSize: size < 429 ? 12 : 18,
											fontWeight: '300',
											marginTop: 5,
										}}
									>
										{errors.cardExpiricy}
									</p>
								) : null}
							</div>
						</Grid>

						<Grid item xs={12} lg={5}>
							<div
								style={{
									width: '100%',
									display: 'flex',
									margin: '0 auto',
									flexDirection: 'column',
									marginBottom: errors.cardCVV && touched.cardCVV > 0 ? 0 : 30,
								}}
							>
								<div style={{ display: 'flex' }}>
									<label
										htmlFor=""
										style={{
											color: '#FFF',
											margin: 0,
											textAlign: 'left',
											marginBottom: '5px',
											fontSize: size < 429 ? 16 : 24,
											fontWeight: 400,
											cursor: 'pointer',
										}}
									>
										{t('PROCESSPAYMENT_form_input_cvv')}
									</label>
									<QuestionIcon
										style={{
											marginTop: size < 429 ? 4 : 10,
											marginLeft: 5,
											cursor: 'pointer',
										}}
										onClick={() => setShowCVV(true)}
									/>
								</div>

								<div
									style={{
										border: '1px solid #d9d9d9',
										width: '100%',
										padding: 8,
										borderRadius: '5px',
										display: 'flex',
										height: size < 429 ? '45px' : '73px',
									}}
								>
									<PatternFormat
										format="####"
										value={cardCVV}
										placeholder="123"
										style={{
											width: '100%',
											borderRadius: '5px',
											border: 'none',
											backgroundColor: 'transparent',
											fontSize: size < 429 ? 16 : 24,
											fontWeight: 300,
											color: '#FFF',
										}}
										onInput={e => {
											const cardCVV = e.target.value.replaceAll(' ', '');

											if (cardCVV.length === 3) {
												setFieldTouched('cardCVV', true);
											}
											if (cardCVV.length < 5) {
												setFieldValue('cardCVV', cardCVV);
											}
										}}
										onBlur={() => {
											setFieldTouched('cardCVV', true);
										}}
									/>
								</div>
								{errors.cardCVV && touched.cardCVV ? (
									<p
										style={{
											color: 'red',
											fontSize: size < 429 ? 12 : 18,
											fontWeight: '300',
											marginTop: 5,
										}}
									>
										{errors.cardCVV}
									</p>
								) : null}
							</div>
						</Grid>
					</Grid>

					<Grid item xs={12} lg={6}>
						<div style={{ display: 'flex', marginBottom: 20 }}>
							<Checkbox
								value={tokenizeCard}
								checked={tokenizeCard}
								onChange={() => {
									setTokenizeCard(value => !value);
								}}
								inputProps={{
									'aria-label': 'controlled',
								}}
								sx={{
									color: '#FFF',
									'&.Mui-tokenizeCard': {
										color: '#FFF',
									},
								}}
								style={{
									transform: 'scale(1.3)',
									paddingLeft: 0,
								}}
							/>
							<button
								onClick={() => {
									setTokenizeCard(value => !value);
								}}
								style={{
									display: 'flex',
									background: 'transparent',
									border: 'none',
									marginTop: 6,
								}}
							>
								<H3
									text={t('PROCESSPAYMENT_checkbox')}
									fontSize={size < 429 ? 16 : 20}
									fontWeight={300}
									style={{ textAlign: 'left' }}
								/>
							</button>
						</div>

						<p
							style={{
								fontSize: size < 429 ? 16 : 24,
								fontWeight: 400,
								marginTop: 34,
							}}
						>
							{t('PROCESSPAYMENT_form_subtitle')}{' '}
							<span
								style={{
									padding: 10,
									backgroundColor: '#D9D9D929',
									borderRadius: '4px',
									fontWeight: 'bold',
									width: 74,
									height: 43,
									marginLeft: 10,
								}}
							>
								US${amount}
							</span>
						</p>

						<button
							style={{
								display: 'flex',
								background: 'transparent',
								border: 'none',
								marginTop: size < 429 ? 21 : 31,
							}}
							onClick={() => setDiscountModal(true)}
							disabled={discountActive}
						>
							<H3
								text={t('PROCESSPAYMENT_discount_button')}
								fontSize={size < 429 ? 16 : 20}
								fontWeight={300}
								style={{ textDecoration: 'underline', textAlign: 'left' }}
							/>
							{discountActive && (
								<img src={Check_Discount} s style={{ marginLeft: 10 }}></img>
							)}
						</button>
					</Grid>
				</Grid>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: 60,
					marginBottom: 52,
					width: '100%',
				}}
			>
				<Button
					buttonText={t('BUTTONS_text2')}
					height={size < 429 ? 52 : 60}
					width={size < 429 ? 170 : 300}
					backgroundColor={colors.pink}
					onClick={onClickCancelHandler}
					textStyle={{ fontSize: size < 429 ? 18 : 24 }}
					buttonStyle={{ marginRight: 20 }}
				/>
				<Button
					buttonText={t('BUTTONS_text_accept')}
					height={size < 429 ? 52 : 60}
					width={size < 429 ? 170 : 300}
					backgroundColor={colors.blue}
					onClick={() => {
						validateForm().then(errors => {
							if (Object.keys(errors).length > 0) {
								setFieldTouched('cardName', true);
								setFieldTouched('cardNumber', true);
								setFieldTouched('cardCVV', true);
								setFieldTouched('cardExpiricy', true);
								setFieldTouched('cardAddress', true);
								setFieldTouched('cardPostal', true);
							} else {
								handleSubmit();
							}
						});
					}}
					textStyle={{ fontSize: size < 429 ? 18 : 24 }}
					showLoader={showLoader}
				/>
			</div>
		</Container>
	);
}
