import React from 'react';
import { eventBus } from '../../utils/event-bus';
import PlaylistFooter from '../playlist-footer/PlaylistFooter';

const pathImage = process.env.REACT_APP_IMAGE_PATH;

const TheFooter = props => {
	const [progressBar, setProgressBar] = React.useState(props.progressBar);
	const [progressPercent, setProgressPercent] = React.useState(0);

	React.useEffect(() => {
		if (props.currentTrack !== undefined) {
			if (Object.keys(props.currentTrack).length > 0) {
				if (progressBar > 0 && Object.keys(props.currentTrack).length > 0) {
					if ((progressBar / props.currentTrack.duration_ms) * 100 > 100) {
						eventBus.dispatch('refresh-footer');
						setProgressBar(0);
					} else {
						setProgressPercent((progressBar / props.currentTrack.duration_ms) * 100);
					}
				}
			}
		}
	}, [progressBar, props.currentTrack]);

	React.useEffect(() => {
		setInterval(() => {
			setProgressBar(value => {
				return value + 1000;
			});
		}, 1000);
	}, []);

	return (
		<>
			{progressBar > 0 ? (
				<PlaylistFooter
					currentTrack={props.currentTrack}
					pathImage={pathImage}
					progressPercent={progressPercent}
					estimatedTime={props.estimatedTime}
				></PlaylistFooter>
			) : (
				<></>
			)}
		</>
	);
};

export default TheFooter;
