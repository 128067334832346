import { Dialog, DialogContent, DialogContentText, IconButton, Grid } from '@mui/material';

import Button from '../button/Button';
import { ReactComponent as CrossIcon } from '../../assets/Cross_Icon.svg';

const GeneralDialog = ({
	open,
	title,
	titleStyle,
	subtitle,
	subtitleStyle,
	button1Text,
	button1Action,
	button1Style,
	button2Text,
	button2Action,
	button2Style,
	dialogStyle,
	dialogPaperStyle,
	icon,
	iconStyle,
	crossButtonAction,
	crossStyle,
	handleClose,
}) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			sx={{
				'& .MuiDialog-paper': {
					backgroundColor: 'rgba(45, 108, 206, 0.2)',
					backdropFilter: 'blur(10px)',
					maxWidth: "100%",
					width: '680px',
					height: 'auto',
					borderRadius: '25px',
					border: '1px solid #DDDDDD',
					paddingBottom: '10px',
					...dialogPaperStyle,
				},
				background: 'rgba(21, 33, 47, 0.97)',
				...dialogStyle,
			}}
		>
			<DialogContent>
				<Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
					<Grid container justifyContent="flex-end" alignSelf="flex-start">
						<IconButton onClick={crossButtonAction}>
							<CrossIcon width={16} height={16} style={{ ...crossStyle }} />
						</IconButton>
					</Grid>
					{icon ? <img src={icon} alt="?" style={{ ...iconStyle }} /> : null}
					{title ? (
						<Grid item xs={12}>
							<DialogContentText
								sx={{
									color: '#FFF',
									textAlign: 'center',
									fontFamily: 'Inter',
									fontWeight: '500',
									fontSize: '22px',
									margin: 'auto',
									...titleStyle,
								}}
							>
								{title}
							</DialogContentText>
						</Grid>
					) : null}
					{subtitle ? (
						<Grid item xs={12}>
							<DialogContentText
								sx={{
									color: '#FFF',
									textAlign: 'center',
									fontFamily: 'Inter',
									fontWeight: '600',
									fontSize: '46px',
									margin: 'auto',
									...subtitleStyle,
								}}
							>
								{subtitle}
							</DialogContentText>
						</Grid>
					) : null}
					<Grid container justifyContent={button2Text ? 'space-evenly' : 'center'}>
						{button2Text ? (
							<Button
								onClick={button2Action}
								width={button1Text ? '45%' : '80%'}
								buttonText={button2Text}
								buttonStyle={{
									backgroundColor: '#FF4D82',
								}}
								textStyle={{ fontFamily: 'Inter', ...button2Style }}
							/>
						) : null}
						{(!button2Text && !button1Text) ||
						(!button2Text && button1Text) ||
						(button2Text && button1Text) ? (
							<Button
								onClick={button1Action}
								width={button2Text ? '45%' : '80%'}
								buttonText={button1Text}
								textStyle={{ fontFamily: 'Inter', ...button1Style }}
							/>
						) : null}
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default GeneralDialog;
