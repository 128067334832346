import React from 'react'
import Typography from '@mui/material/Typography'

const H4 = ({ text, color, fontSize, fontWeight, style }) => {
  return (
    <Typography style={{
        fontSize: fontSize ?? 16,
        fontWeight: fontWeight ?? 700,
        color: color ?? '#FFF',
        ...style
    }}>
        {text}
    </Typography>
  )
}

export default H4