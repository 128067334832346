import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute({ children }) {
	const { commerceId } = useParams();
	const { customerData } = useSelector(state => state.customer);
	let isAuth = false;

	if (Object.keys(customerData).length > 0) {
		isAuth = true;
	}

	return isAuth ? children : <Navigate to={`/home/${commerceId}`} />;
}

export default PrivateRoute;
