import React from 'react';

function TheItem(props) {
	const [selected, setSelected] = React.useState(false);

	const onClickHandler = item => {
		if (!selected) {
			if (props.counter + 1 < 2) {
				setSelected(!selected);
				props.onClickHandler({ item, type: 'plus' });
			} else {
				alert('You can only select one pack');
			}
		} else {
			if (props.counter - 1 < 2) {
				setSelected(!selected);
				props.onClickHandler({ item, type: 'minus' });
			} else {
				alert('You can only select one pack');
			}
		}
	};

	return (
		<div
			className={`${props.styles.pack} ${selected ? props.styles.active : ''} ${
				props.index % 2 === 0 ? props.styles.coloredPack : ''
			}`}
			onClick={() => onClickHandler(props.item)}
			key={props.item._id}
		>
			<p className={`${props.styles.packName}`}>{props.item.name}</p>
			<div
				className={`${props.styles.divisor} ${
					props.index % 2 === 0 ? props.styles.coloredDivisor : ''
				}`}
			></div>
			<p className={`${props.styles.packDescription}`}>{props.item.itemsQty} Tracks</p>
			<p
				className={`${props.styles.packPrice} ${
					props.index % 2 === 0 ? props.styles.coloredPrice : ''
				}`}
			>
				${props.item.price}
			</p>
		</div>
	);
}

export default TheItem;
