export const colors = {
	pink: '#FC507E',
	blue: '#486CCB',
	blueDark: '#0757A0',
	purple: '#9747FF',
	new: '#FF8D12',
	google: '#D9D9D9',
	disable: '#434B55',
	textActive: '#FFF',
	textDisable: '#333A43',
	chipColor: '#263649',
};
