import * as React from 'react';
const Discover = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		viewBox="0 0 512 512"
		{...props}
		style={{ position: 'relative', top: -8 }}
	>
		<path
			d="M512 402.282c0 16.716-13.55 30.267-30.265 30.267H30.265C13.55 432.549 0 418.996 0 402.282V109.717c0-16.716 13.55-30.266 30.265-30.266h451.469c16.716 0 30.265 13.551 30.265 30.266L512 402.282z"
			style={{
				fill: '#34495e',
			}}
		/>
		<path
			d="M21.517 402.282V109.717c0-16.716 13.552-30.266 30.267-30.266h-21.52C13.55 79.451 0 93.003 0 109.717v292.565c0 16.716 13.55 30.267 30.265 30.267h21.52c-16.715 0-30.268-13.553-30.268-30.267z"
			style={{
				opacity: 0.15,
				fill: '#202121',
				enableBackground: 'new',
			}}
		/>
		<path
			d="M309.389 255.801c.041-9.636-3.572-19.286-10.843-26.558-7.287-7.287-16.961-10.897-26.617-10.839-.046 0-.091-.003-.139-.003-20.968 0-37.6 16.628-37.6 37.602 0 20.767 16.837 37.599 37.6 37.599 20.974 0 37.604-16.631 37.604-37.599 0-.069-.005-.134-.005-.202z"
			style={{
				fill: '#f26e21',
			}}
		/>
		<path
			d="M227.198 271.909c-5.62 5.626-10.807 7.824-16.394 7.943-13.611-.122-23.618-10.202-23.618-24.573 0-7.234 2.739-13.163 7.078-18.228 4.069-3.863 9.311-6.359 15.339-6.359 6.507 0 11.571 2.169 17.352 7.954v-16.631c-5.78-2.891-10.846-4.338-17.352-4.338-9.192.657-17.859 4.371-24.507 10.203-1.916 1.724-3.752 3.627-5.309 5.805-4.856 6.294-7.791 14.001-7.791 22.32 0 20.967 16.637 36.875 37.606 36.875.102 0 .203-.009.302-.01.141.002.28.01.42.01 5.784 0 10.85-1.443 17.357-4.336l-.483-16.635c-.244.244.242.471 0 .702v-.702zM356.863 228.033l-16.376 40.262-18.802-47.729h-15.183l29.646 73.035h7.954l31.094-73.035h-15.183zM380.983 252.384v39.051H420.753v-11.574h-25.308v-19.519h24.588v-12.297h-24.588v-15.184h25.308v-12.295h-39.77zM54.135 220.566H33.884v70.869h20.25c10.845 0 18.798-2.895 25.306-7.957 7.953-6.508 13.017-16.629 13.017-27.474.001-20.976-15.187-35.438-38.322-35.438zm16.63 53.514c-4.339 3.614-10.124 5.781-18.802 5.781h-4.339V232.86h3.615c8.678 0 14.463 1.446 18.803 5.783 5.061 4.336 7.955 10.848 7.955 17.358.723 6.508-2.169 13.736-7.232 18.079zM98.97 220.56h13.739v70.867H98.97zM147.415 248.045c-8.676-2.892-10.848-5.063-10.848-8.677 0-4.339 4.339-7.954 10.124-7.954 4.339 0 7.954 1.447 11.57 5.786l7.233-9.4c-5.787-5.064-13.015-7.953-20.97-7.953-12.296 0-22.42 8.678-22.42 20.244 0 10.126 4.343 14.464 17.357 19.526 5.785 2.166 7.955 2.892 9.404 4.338 2.887 1.444 4.336 4.339 4.336 7.228 0 5.786-4.336 10.126-10.848 10.126-6.514 0-12.294-3.615-15.187-9.401l-8.678 8.678c6.511 9.4 14.465 13.738 24.589 13.738 14.461 0 24.58-9.4 24.58-23.141.002-12.29-4.333-17.352-20.242-23.138zM459.804 261.783c10.843-2.166 16.63-9.4 16.63-20.244 0-13.014-9.402-20.973-25.308-20.973h-20.972v70.869h13.739V263.23h2.172l19.519 28.205h16.634l-22.414-29.652zm-11.574-8.678h-4.336v-21.691h4.336c8.678 0 13.742 3.614 13.742 10.85 0 7.228-5.063 10.841-13.742 10.841z"
			style={{
				fill: '#e7e8e3',
			}}
		/>
	</svg>
);
export default Discover;
