import React from 'react';
import Typography from '@mui/material/Typography';

const H6 = ({ text, color, fontSize, fontWeight, style }) => {
	return (
		<Typography
			style={{
				fontSize: fontSize ?? 14,
				fontWeight: fontWeight ?? 700,
				color: color ?? '#FFF',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				...style,
			}}
		>
			{text}
		</Typography>
	);
};

export default H6;
