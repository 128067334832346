import { t } from 'i18next';
import React from 'react';
import H6 from '../text/H6';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function PlaylistFooter(props) {
	const [size, setSize] = React.useState(window.innerWidth);
	const { commerceData } = useSelector(state => state.commerce);

	useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	return (
		<footer
			className="flex-shrink-0 fixed-bottom pt-2 text-white-50"
			style={{
				borderRadius: '20px 20px 0 0',
				backgroundColor: '#50545c',
				marginTop: '100px',
				padding: 0,
			}}
		>
			{props.currentTrack && Object.entries(props.currentTrack).length > 0 ? (
				<div className={styles.playing_container}>
					<div className={styles.playing_details}>
						<div className={`${styles.track_image}`}>
							<img
								src={`${
									commerceData.currentProvider === 'Spotify'
										? props.currentTrack.album?.images[0]?.url
										: props.currentTrack.image
								}`}
								alt="img"
								style={{ width: '100%', height: '100%' }}
							/>
						</div>
						<div className={styles.track_details}>
							<p className={styles.track_name}>
								{size < 429
									? `${props.currentTrack.name.slice(0, 10)}...`
									: props.currentTrack.name}
							</p>
							<p className={styles.track_name_description}>
								{commerceData.currentProvider === 'Spotify'
									? props.currentTrack.artists[0].name
									: props.currentTrack.artist}
							</p>

							{commerceData.currentProvider === 'Spotify' && (
								<div
									style={{
										display: 'flex',
										marginLeft: '10px',
										marginTop: '10px',
										cursor: 'pointer',
									}}
									onClick={() => {
										window.open(props.currentTrack.uri);
									}}
								>
									<img
										src={require('../../assets/spotify-64.png')}
										alt="img"
										height={size < 500 ? 20 : 25}
									/>
									<p
										className={{
											...styles.spotify_label,
										}}
										style={{
											fontSize: size < 500 ? '12px' : '17px',
											marginLeft: 5,
										}}
									>
										PLAY ON SPOTIFY
									</p>
								</div>
							)}
						</div>
					</div>
					<div className={`${styles.track_gif}`}>
						<p style={{ margin: 0 }}>{props.estimatedTime}</p>
						<H6
							text={`${t('HOME_estimated_time')}`}
							style={{ fontSize: size < 429 ? '12px' : '14px' }}
						/>
					</div>
				</div>
			) : (
				<div className={styles.playing_container}>
					<div
						className={`${styles.track_image}`}
						style={{
							backgroundImage: `url(${props.pathImage}images/notrack.png)`,
						}}
					></div>
					<p className={styles.track_name}>No track playing</p>
				</div>
			)}

			<div
				style={{
					height: '5px',
					width: `${props.progressPercent}%`,
					backgroundColor: '#fff',
					marginTop: 25,
				}}
			></div>
		</footer>
	);
}
