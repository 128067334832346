/* eslint-disable */
import React from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { useEffect } from 'react';
import MaterialIcon from 'material-icons-react';

const windowSize = window.innerWidth;
const divSize = windowSize / 2;
const defaultHeight = '550px';

const sdk = new ChartsEmbedSDK({
	baseUrl: 'https://charts.mongodb.com/charts-cloud-jukebox-abecn', // ~REPLACE~ with the Base URL from your Embed Chart dialog.
});

const chartInit = sdk.createChart({
	chartId: '62d999c2-79ab-4342-8480-690f4ab66bc7', // ~REPLACE~ with the Chart ID from your Embed Chart dialog.
	height: defaultHeight,
	width: divSize,
	// Additional options go here
});

const chartTopInit = sdk.createChart({
	chartId: '62e2d7c1-e4bf-48de-8a46-175ce3a48bb3', // ~REPLACE~ with the Chart ID from your Embed Chart dialog.
	height: defaultHeight,
	width: windowSize - 30,
	filter: { commerceId: '6272e4db02860741d5ce9a3d' },
	maxDataAge: 30,
	// Additional options go here
});

const allChartTopInit = sdk.createChart({
	chartId: '62dab688-713e-4fa1-8096-6a557b087ac8', // ~REPLACE~ with the Chart ID from your Embed Chart dialog.
	height: defaultHeight,
	width: divSize - 30,
	// Additional options go here
});

const qtyCommerceByTrackInit = sdk.createChart({
	chartId: '6302a0b4-fc05-402f-85d8-74a4b53d69fa', // ~REPLACE~ with the Chart ID from your Embed Chart dialog.
	height: defaultHeight,
	width: divSize - 30,
	// Additional options go here
});

export default function Landing() {
	const [chart] = React.useState(chartInit);
	const [chartTop] = React.useState(chartTopInit);
	const [allChartTop] = React.useState(allChartTopInit);
	const [qtyCommerceByTrack] = React.useState(qtyCommerceByTrackInit);
	const [chartStyle, setChartStyle] = React.useState({ display: 'none', width: '95%' });
	const [allChartTopStyle, setAllChartTopStyle] = React.useState({
		display: 'flex',
		width: '95%',
	});
	const [btnBack, setBtnBack] = React.useState(false);

	const clickHandleAllChartTop = payload => {
		console.log(payload);

		if (payload.target.role !== 'frame') {
			setChartStyle({ display: 'flex', width: '95%' });
			setAllChartTopStyle({ display: 'none', width: '95%' });
			setBtnBack(true);
		}
		allChartTop.setHighlight(payload.selectionFilter);
		qtyCommerceByTrack.setHighlight(payload.selectionFilter);
	};

	const clickHandleCommerceByTrack = payload => {
		console.log(payload);
		qtyCommerceByTrack.setHighlight(payload.selectionFilter);
		allChartTop.setHighlight(payload.selectionFilter);
	};

	const clickHandle = payload => {
		console.log(payload);
	};

	useEffect(() => {
		const renderCharts = async () => {
			await chart.render(document.getElementById('chart'));
			await chartTop.render(document.getElementById('Topchart'));
			await allChartTop.render(document.getElementById('allTopchart'));
			await qtyCommerceByTrack.render(document.getElementById('qtyCommerceByTrack'));

			await chartTop.addEventListener('click', payload => clickHandle(payload, chartTop), {
				includes: [{ roles: ['mark', 'legend-entry', 'frame'] }],
			});

			await chart.addEventListener('click', payload => clickHandle(payload, chart), {
				includes: [{ roles: ['mark', 'legend-entry', 'frame'] }],
			});
			await allChartTop.addEventListener(
				'click',
				payload => clickHandleAllChartTop(payload, allChartTop),
				{
					includes: [{ roles: ['mark', 'legend-entry', 'frame'] }],
				},
			);

			await qtyCommerceByTrack.addEventListener(
				'click',
				payload => clickHandleCommerceByTrack(payload, qtyCommerceByTrack),
				{
					includes: [{ roles: ['mark', 'legend-entry', 'frame'] }],
				},
			);
		};

		renderCharts().catch(error => console.log(error));
	}, []);

	const handleFilterChange = async value => {
		await chartTop.setFilter({ commerceId: value });
		await allChartTop.setFilter({ commerceId: value });
	};

	useEffect(() => {
		window.location.href = 'https://swaggin.fun/';
	}, []);

	return (
		<div style={{ padding: '10px' }}>
			{/* <div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
					marginBottom: '50px',
				}}
			>
				<div style={{ width: '48%' }}>
					<div
						id="chart"
						style={{ ...chartStyle, borderRadius: '20px', overflow: 'hidden' }}
					></div>
					{btnBack && (
						<div
							style={{
								cursor: 'pointer',
								position: 'relative',
								bottom: '40px',
								height: '30px',
								width: '30px',
								borderRadius: '50px',
								backgroundColor: '#0266C8',
								paddingTop: '3px',
								margin: '0 auto',
							}}
							onClick={() => {
								setBtnBack(false);
								setChartStyle({ display: 'none', width: '95%' });
								setAllChartTopStyle({ display: 'flex', width: '95%' });
								qtyCommerceByTrack.setHighlight({});
								allChartTop.setHighlight({});
							}}
						>
							<MaterialIcon icon="arrow_back" color="#fff" />
						</div>
					)}
					<div
						id="allTopchart"
						style={{
							...allChartTopStyle,
							borderRadius: '20px',
							overflow: 'hidden',
							marginBottom: '50px',
						}}
					></div>
				</div>
				<div
					id="qtyCommerceByTrack"
					style={{
						borderRadius: '20px',
						overflow: 'hidden',
						marginBottom: '50px',
					}}
				></div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<label style={{ display: 'flex' }}>
					<p style={{ margin: 0, marginRight: '10px' }}>Filter by Commerce</p>
					<select
						id="country-filter"
						onChange={e => handleFilterChange(e.target.value)}
						style={{ width: '100px' }}
					>
						<option value="6272e4db02860741d5ce9a3d">Ikea</option>
						<option value="6287f37bc30433f4354b5f4c">Ikea2</option>
					</select>
				</label>

				<div id="Topchart" style={{ borderRadius: '10px', overflow: 'hidden' }}></div>
			</div> */}
		</div>
	);
}
