import {
	Dialog,
	DialogContent,
	DialogContentText,
	IconButton,
	Grid,
	Typography,
} from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';

import Button from '../button/Button';
import IconCard from '../cards/IconCard';
import { ReactComponent as CrossIcon } from '../../assets/Cross_Icon.svg';
import ErrorText from '../text/ErrorText';

const OTPdialog = ({
	open,
	title,
	titleStyle,
	buttonText,
	buttonAction,
	buttonStyle,
	dialogStyle,
	dialogPaperStyle,
	icon,
	iconStyle,
	iconContainerStyle,
	crossButtonAction,
	crossStyle,
	handleClose,
	code,
	onChange,
	onComplete,
	isError,
	isErrorText,
	size,
	limit,
}) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			sx={{
				'& .MuiDialog-paper': {
					backgroundColor: 'rgba(45, 108, 206, 0.2)',
					backdropFilter: 'blur(10px)',
					maxWidth: '100%',
					width: '750px',
					height: 'auto',
					borderRadius: '25px',
					border: '1px solid #DDDDDD',
					paddingBottom: '10px',
					...dialogPaperStyle,
				},
				background: 'rgba(21, 33, 47, 0.97)',
				...dialogStyle,
			}}
		>
			<DialogContent>
				<Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
					<Grid container justifyContent="flex-end" alignSelf="flex-start">
						<IconButton onClick={crossButtonAction}>
							<CrossIcon width={16} height={16} style={{ ...crossStyle }} />
						</IconButton>
					</Grid>
					{icon ? (
						<IconCard
							icon={icon}
							iconStyle={{
								height: size && size < (limit ? limit : 429) ? '40px' : '',
								width: size && size < (limit ? limit : 429) ? '40px' : '',
								...iconStyle,
							}}
							containerStyle={{
								width: size && size < (limit ? limit : 429) ? '70px' : '156px',
								height: size && size < (limit ? limit : 429) ? '70px' : '156px',
								marginBottom: '16px',
								...iconContainerStyle,
							}}
						/>
					) : null}
					{title ? (
						<Grid item xs={12}>
							<DialogContentText
								sx={{
									color: '#FFF',
									textAlign: 'center',
									fontFamily: 'Inter',
									fontWeight:
										size && size < (limit ? limit : 429) ? '300' : '400',
									fontSize:
										size && size < (limit ? limit : 429) ? '20px' : '22px',
									margin: 'auto',
									...titleStyle,
								}}
							>
								{title}
							</DialogContentText>
						</Grid>
					) : null}

					<Grid
						container
						justifyContent="center"
						sx={{
							paddingTop: '30px',
							paddingBottom: isError && isErrorText ? '' : '30px',
						}}
					>
						<MuiOtpInput
							value={code}
							onChange={onChange}
							onComplete={typeof onComplete === 'function' ? onComplete() : null}
							length={8}
							gap={size && size < (limit ? limit : 429) ? 1 : 2}
							sx={{
								overflowX: 'auto',

								'& .MuiTextField-root input': {
									color: '#FFF',
									fontSize: size && size < (limit ? limit : 429) ? 17 : 32,
									fontFamily: 'Inter',
									fontWeight: 300,
									width: size && size < (limit ? limit : 429) ? '22px' : '28px',
									height: size && size < (limit ? limit : 429) ? '22px' : '',
								},

								'& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
									borderWidth: 0,
								},

								'& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
									padding: 0,
								},

								'& .css-1x5jdmq': {
									padding: 0,
								},
							}}
							TextFieldsProps={{
								style: {
									backgroundColor: 'rgba(255, 255, 255, 0.04)',
									borderTop: 0,
									borderLeft: 0,
									borderRight: 0,
									borderBottom: '1.66667px solid #FFFFFF',
									justifyContent: 'center',
									minWidth: '22px',
								},
							}}
						/>
					</Grid>

					{isError && isErrorText ? (
						<ErrorText style={{ marginBottom: '10px', marginTop: '10px' }}>
							{isErrorText}
						</ErrorText>
					) : null}

					<Grid container justifyContent="center">
						<Button
							onClick={buttonAction}
							width="80%"
							buttonText={buttonText}
							textStyle={{ fontFamily: 'Inter', ...buttonStyle }}
						/>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default OTPdialog;
