import axios from 'axios';
import tokenSingleton from './tokenSingleton';

export default class Http {
	static instance = new Http();

	async get(resource, headers) {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/${resource}`, {
				headers: {
					...headers,
					'X-Firebase-AppCheck': tokenSingleton.instance.getToken(),
				},
			});
			return res;
		} catch (err) {
			throw err;
		}
	}

	async post(resource, data, headers) {
		try {
			const res = await axios.post(`${process.env.REACT_APP_API_URL}/${resource}`, data, {
				headers: { ...headers, 'X-Firebase-AppCheck': tokenSingleton.instance.getToken() },
			});
			return res;
		} catch (err) {
			throw err;
		}
	}

	async put(resource, data, headers) {
		try {
			const res = await axios.put(`${process.env.REACT_APP_API_URL}/${resource}`, data, {
				headers: { ...headers, 'X-Firebase-AppCheck': tokenSingleton.instance.getToken() },
			});
			return res;
		} catch (err) {
			throw err;
		}
	}

	async delete(resource, headers) {
		try {
			const res = await axios.delete(`${process.env.REACT_APP_API_URL}/${resource}`, {
				headers: { ...headers, 'X-Firebase-AppCheck': tokenSingleton.instance.getToken() },
			});
			return res;
		} catch (err) {
			throw err;
		}
	}
}
