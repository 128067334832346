import { useState, useEffect, forwardRef } from 'react';
import {
	Typography,
	Grid,
	ButtonGroup,
	Button,
	Dialog,
	Slide,
	TextField,
	Rating,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as StarFilled } from '../../assets/svg/rating_star_filled.svg';
import { ReactComponent as StarEmpty } from '../../assets/svg/rating_star_empty.svg';

import CustomButton from '../../components/button/Button';
import http from '../../http/http';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Rate = ({ open, setOpen, commerceId }) => {
	const { t } = useTranslation();
	const [size, setSize] = useState(window.innerWidth);
	const [stars, setStars] = useState(null);
	const [message, setMessage] = useState('');

	useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	const onSubmit = async () => {
		const path = 'rate/create';
		try {
			const payload = {
				phone: localStorage.getItem('phone') ?? 'unknown',
				message,
				stars,
				version: '1',
				commerce: commerceId,
			};
			await http.instance.post(path, payload).then(res => {
				localStorage.setItem('rateVersion', '1');
				setOpen(false);
			});
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Dialog
			sx={{ '& .MuiDialog-paper': { background: '#15212F' } }}
			fullScreen
			open={open}
			TransitionComponent={Transition}
		>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				sx={{ height: '100%', maxWidth: '650px', margin: 'auto', padding: '15px' }}
			>
				<Grid container justifyContent="center" rowGap={2}>
					<Grid item xs={12}>
						<Typography
							sx={{
								fontFamily: 'Inter',
								fontWeight: '700',
								fontSize: size > 600 ? '40px' : '23px',
								textAlign: size > 600 ? 'center' : 'left',
								color: '#FFFFFF',
							}}
						>
							{t('RATE_title')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={message}
							onChange={e => {
								setMessage(e.target.value);
							}}
							placeholder={t('RATE_message_input_placeholder')}
							fullWidth
							multiline
							rows={size > 600 ? 8 : 12}
							sx={{
								'& .MuiInputBase-root': {
									borderRadius: '15px',
									border: '1px solid rgba(217, 217, 217, 0.5)',
								},
								'& .MuiInputBase-input': {
									color: '#FFFFFF',
									fontWeight: 400,
									fontSize: '20px',
								},
							}}
							inputProps={{ maxLength: 400 }}
						/>
						<Typography textAlign="end" marginRight={2}>{`${message.length}/400`}</Typography>
					</Grid>
					<Grid item sx={{ paddingTop: '10px' }}>
						<Rating
							value={stars}
							onChange={(e, newValue) => setStars(newValue)}
							sx={{
								borderRadius: '10px',
								padding: '5px',
								'& .MuiRating-icon': {
									marginX: '8px',
								},
							}}
							size="large"
							icon={<StarFilled />}
							emptyIcon={<StarEmpty />}
							radioGroup=""
						/>
					</Grid>
				</Grid>
				<Grid container justifyContent="center">
					<Grid item xs={12}>
						<CustomButton
							disabled={stars === null}
							onClick={() => {
								if (stars) {
									onSubmit();
								} else {
									return;
								}
							}}
							buttonText={t('BUTTONS_text_accept')}
							buttonStyle={{
								width: '100%',
								margin: 'auto',
								height: size > 600 ? '60px' : '52px',
							}}
							textStyle={{ fontSize: size > 600 ? '24px' : '18px' }}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							onClick={() => {
								setOpen(false);
							}}
							sx={{
								display: 'flex',
								margin: 'auto',
								color: '#FFFFFF',
								fontSize: size > 600 ? '20px' : '18px',
								fontWeight: 400,
								textDecorationStyle: 'solid',
								textDecorationColor: '#FFFFFF',
								textDecorationLine: 'underline',
								textTransform: 'none',
								textUnderlinePosition: 'under',
								marginTop: '5px',
							}}
						>
							{t('RATE_skip_button_text')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Dialog>
	);
};

export default Rate;
