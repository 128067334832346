import { Avatar } from '@mui/material';

const IconCard = ({ containerStyle, iconStyle, icon, width, height, onClick }) => {
	return (
		<Avatar
			sx={{
				background: 'transparent',
				width: width ?? '120px',
				height: height ?? '120px',
				...containerStyle,
			}}
			variant="rounded"
			onClick={onClick}
		>
			<img src={icon} alt="?" style={{ ...iconStyle }} />
		</Avatar>
	);
};

export default IconCard;
