// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/performance';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { eventBus } from './utils/event-bus';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const performance = getPerformance(app);
export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);

if (process.env?.REACT_APP_NODE_ENV === 'local') {
	window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_DEBUG_CHECK_TOKEN;
}

export const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_SITE_KEY),
	isTokenAutoRefreshEnabled: true,
});

export const getTokenFCM = async setTokenFound => {
	let currentToken = '';

	try {
		currentToken = await getToken(messaging, {
			vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
		});
	} catch (error) {
		// console.log('An error occurred while retrieving token. ', error);
	}

	return currentToken;
};

export const onMessageListener = () => {
	onMessage(messaging, payload => {
		eventBus.dispatch('new-notification', payload);
	});
};
