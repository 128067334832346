import React from 'react';
import http from '../../http/http';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebaseInit';
import styles from './styles.module.scss';
import TrackCard from '../../components/track-card/TrackCard';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import { red } from '@mui/material/colors';
import Icon from '@mui/material/Icon';
import Swal from 'sweetalert2';

const pathImage = process.env.REACT_APP_IMAGE_PATH;
const perPage = 4;

export default function Playlist() {
	const [search, setSearch] = React.useState('');
	const [tracks, setTracks] = React.useState([]);
	const [tracksFiltered, setTracksFiltered] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [maxPage, setMaxPage] = React.useState(0);
	const [currentPlaylist, setCurrentPlaylist] = React.useState([]);
	const [spotifyIds, setSpotifyIds] = React.useState([]);
	const [spotifyAuth, setSpotifyAuth] = React.useState({});
	const [searchError, setSearchError] = React.useState();
	const [maxDuration, setMaxDuration] = React.useState();
	const [explicitContent, setExplicitContent] = React.useState();
	const [commerceId, setCommerceId] = React.useState('');
	const [searchLoader, setSearchLoader] = React.useState(false);
	const [submitLoader, setSubmitLoader] = React.useState(false);

	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const filterTracks = tracks => {
		let tracksFirstFilter = tracks.filter(track =>
			track.duration_ms > maxDuration * 60000 ? false : true,
		);
		let tracksSecondFilter = tracksFirstFilter.filter(track => {
			if (explicitContent === 1) {
				return true;
			} else {
				if (track.explicit) {
					return false;
				} else {
					return true;
				}
			}
		});
		let result = [];
		tracksSecondFilter.map((track, index) => {
			result.push({
				...track,
				checked: false,
			});
			return true;
		});

		return result;
	};

	const getCurrentPlaylist = React.useCallback(commerceId => {
		const path = `playlist/current/${commerceId}`;
		http.instance
			.get(path)
			.then(res => {
				if (res.data.result.tracks !== undefined) {
					setCurrentPlaylist(res.data.result.tracks);
				}
			})
			.catch(error => console.log(error));
	}, []);

	React.useEffect(() => {
		if (commerceId !== '') {
			getCurrentPlaylist(commerceId);
			getCommerce(commerceId);
		}
	}, [commerceId, getCurrentPlaylist]);

	React.useEffect(() => {
		setCommerceId(params.commerceId);
	}, [params.commerceId]);

	React.useEffect(() => {
		setTracksFiltered(tracks.slice((page - 1) * perPage, (page - 1) * perPage + perPage));
	}, [page, tracks]);

	const handlePagination = (page, value) => {
		setPage(value);
	};

	const getCommerce = commerceId => {
		const path = `commerce/${commerceId}`;
		http.instance
			.get(path)
			.then(res => {
				setSpotifyAuth(res.data.result.spotifyAuth);

				//TODO: CHANGE HOW TO GET THE RESTRICTION, THIS IS NOT VERY WELL
				setMaxDuration(
					res.data.result.restrictions.find(
						item => item.restrictionName === 'max_duration',
					).value,
				);
				setExplicitContent(
					res.data.result.restrictions.find(
						item => item.restrictionName === 'explicit_content',
					).value,
				);
			})
			.catch(error => console.log(error));
	};

	const onSearchHandler = async () => {
		if (!search.length) {
			setSearchError('Introduce un dato válido');
			return;
		}

		setSearchError();
		setSearchLoader(true);
		const path = `spotify/search?`;
		const params = new URLSearchParams({
			query: search,
		}).toString();

		try {
			const res = await http.instance.post(path + params, {
				commerceId: commerceId,
			});
			const filterTrackss = filterTracks(res.data.body.tracks.items);
			setTracks(filterTrackss);
			setPage(1);
			setMaxPage(Math.ceil(filterTrackss.length / perPage));
			setSearchLoader(false);
		} catch (error) {
			console.log(error);
			setSearchLoader(false);
		}
	};

	const onChangeSearch = ({ target }) => {
		setSearch(target.value);
	};

	const onSubmit = async () => {
		setSubmitLoader(true);
		const path = `playlist/add-to-queue?`;

		const body = {
			commerceId: commerceId,
			tracks: spotifyIds,
			isFreePack: location.state.amount === 0 ? true : false,
		};
		try {
			await http.instance.post(path, body);

			logEvent(analytics, 'cloudJukebox_web_add_to_quoue', {
				tracksName: spotifyIds.map(track => track.name),
			});

			navigate('/finish/');
			setSubmitLoader(false);
		} catch (error) {
			console.log(error);
			setSubmitLoader(false);
		}
	};

	const onAddTrackHandler = track => {
		const { id, name, artists, album, uri } = track;
		const trackObj = {
			spotifyId: id,
			name,
			artist: artists[0].name,
			image: album.images[0].url,
			uri,
		};
		Swal.fire({
			title: 'Are you sure?',
			text: `You will add to the queue: ${track.name}`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			cancelButtonColor: 'red',
			confirmButtonColor: 'green',
			showLoaderOnConfirm: true,
			allowEnterKey: false,
			allowEscapeKey: false,
			allowOutsideClick: false,
			preConfirm: async () => {
				const path = `playlist/add-to-queue?`;

				const body = {
					commerceId: commerceId,
					tracks: [{ ...trackObj }],
					customerId: location.state.customerId,
					isFreePack: location.state.amount === 0 ? true : false,
				};
				try {
					await http.instance.post(path, body, {
						headers: {
							access_token: spotifyAuth.accessToken,
						},
					});

					logEvent(analytics, 'cloudJukebox_web_add_to_quoue', {
						tracksName: spotifyIds.map(track => track.name),
					});

					navigate('/finish/');
					setSubmitLoader(false);
					return true;
				} catch (error) {
					console.log(error);
					setSubmitLoader(false);
					return false;
				}
			},
			reverseButtons: true,
		}).then(result => {
			if (result.isConfirmed) {
				Swal.fire({
					title: 'Succesfull!',
					text: `Your track has been added.`,
					icon: 'success',
					allowEnterKey: false,
					allowEscapeKey: false,
					allowOutsideClick: false,
					confirmButtonColor: 'green',
					confirmButtonText: 'Ok',
					timer: 2000,
					timerProgressBar: true,
				});
			}
		});

		//TODO: CHANGE THIS
		// if (location.state !== null) {
		// 	const { maxTrack } = location.state;
		// 	if (maxTrack >= spotifyIds.length + 1) {
		// 		setSpotifyIds(prev => [...prev, trackObj]);
		// 		alert('Song selected. Please confirm your selected songs to finish');
		// 	} else {
		// 		alert('You have selected the maximum number of tracks');
		// 		return;
		// 	}
		// } else {
		// 	if (commerce.restrictions[1].value >= spotifyIds.length + 1) {
		// 		setSpotifyIds(prev => [...prev, trackObj]);
		// 		alert('Song selected. Please confirm your selected songs to finish');
		// 	} else {
		// 		alert('You have selected the maximum number of tracks');
		// 		return;
		// 	}
		// }
	};

	const onDeleteTrackHandler = trackId => {
		const updatedSpotifyIds = [...spotifyIds];
		const existIndex = updatedSpotifyIds.findIndex(item => item.spotifyId === trackId);
		if (existIndex > -1) {
			updatedSpotifyIds.splice(existIndex, 1);
			setSpotifyIds(updatedSpotifyIds);
		}
	};

	const onUpdateTracksHandler = (track, state) => {
		// if (state) {
		// 	setSeletedTracksQty(value => value + 1);
		// } else {
		// 	setSeletedTracksQty(value => value - 1);
		// }

		// const index = tracksFiltered.findIndex(item => item.id === track.id);
		// let tracksFilteredUpdated = [...tracksFiltered];
		// tracksFilteredUpdated[index].checked = state;
		// setTracksFiltered(tracksFilteredUpdated);

		if (state) {
			onAddTrackHandler(track);
		} else {
			onDeleteTrackHandler(track.id);
		}
	};

	// React.useEffect(() => {
	// 	if (location.state !== null) {
	// 		const { maxTrack } = location.state;
	// 		if (seletedTracksQty === maxTrack) {
	// 			// console.log(maxTrack);
	// 			// console.log(seletedTracksQty);
	// 			eventBus.dispatch('disabled');
	// 		}
	// 	}
	// }, [seletedTracksQty]);

	return (
		<div>
			<div className={`${styles.playlistContainer}`}>
				<div className={`row`}>
					<div className={`col-md-4 ${styles.searchContainer}`}>
						<h4 style={{ color: '#ffffff', margin: '20px 0' }}>
							Search and select your song
						</h4>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
							<input
								type="text"
								placeholder="saoko, bad bunny..."
								onChange={onChangeSearch}
								className={styles.inputSearch}
							/>
							<button className={styles.btnSearch} onClick={onSearchHandler}>
								{searchLoader ? (
									<CircularProgress
										color="inherit"
										size={18}
										style={{ marginTop: 5 }}
									/>
								) : (
									<p style={{ margin: 0 }}>Search</p>
								)}
							</button>
						</div>
						{searchError && (
							<p style={{ color: '#9c3028', fontSize: '1em' }}>{searchError}</p>
						)}
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							{tracksFiltered.length > 0 ? (
								<>
									{tracksFiltered.map((track, index) => (
										<TrackCard
											track={track}
											maxDuration={maxDuration}
											explicitContent={explicitContent}
											onAddTrackHandler={() => onAddTrackHandler(track)}
											onUpdateTracksHandler={onUpdateTracksHandler}
											index={index}
											key={index}
										/>
									))}
									<Pagination
										count={maxPage}
										style={{ background: 'white', borderRadius: '50px' }}
										onChange={handlePagination}
										page={page}
									/>
								</>
							) : (
								<div style={{ marginTop: '20%' }}>
									<img
										src={`${pathImage}/images/loupe.png`}
										width={150}
										alt="img"
									></img>
								</div>
							)}
						</div>
					</div>

					<div className={`col-md-8`}>
						<div style={{ justifyContent: 'center' }}>
							{spotifyIds.length > 0 && (
								<div
									className="column"
									style={{
										margin: '20px 0',
										justifyContent: 'center',
										alignItems: 'center',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<h4 style={{ color: '#ffffff' }}>Selected Songs</h4>
									{spotifyIds.map((track, index) => (
										<div
											className={`${styles.selectedCard} col-md-12 col-xl-3`}
											key={index}
										>
											<img src={track.image} alt="img" height={100} />
											<div className={styles.selectedCardDetails}>
												<p style={{ fontSize: '16px', margin: 0 }}>
													{track.name}
												</p>
												<p
													style={{
														fontSize: '14px',
														fontWeight: 'bold',
														margin: 0,
													}}
												>
													{track.artist}
												</p>
											</div>
											<div
												style={{
													backgroundColor: '#fff',
													display: 'flex',
													width: '50px',
													justifyContent: 'center',
													alignItems: 'center',
												}}
												onClick={() =>
													onDeleteTrackHandler(track.spotifyId)
												}
											>
												<Icon sx={{ color: red[700] }}>delete</Icon>
											</div>
										</div>
									))}
									<div>
										<button
											className={styles.btnSubmit}
											onClick={() => onSubmit()}
										>
											{submitLoader ? (
												<CircularProgress color="inherit" size={18} />
											) : (
												<p>Confirm</p>
											)}
										</button>
									</div>
								</div>
							)}

							{currentPlaylist.length > 0 && (
								<div className="column" style={{ margin: '20px 0' }}>
									<h4 style={{ color: '#ffffff' }}>Today's playlist</h4>
									<div
										style={{
											justifyContent: 'center',
											marginBottom: '200px',
											maxHeight: '600px',
											overflowX: 'hidden',
											alignItems: 'center',
											display: 'flex',
											flexWrap: 'wrap',
										}}
									>
										{currentPlaylist.map((track, index) => (
											<div
												className={`${styles.selectedCard} col-md-12 col-xl-3`}
												key={index}
											>
												<img
													src={track.item.image}
													alt="img"
													height={100}
												/>
												<div className={styles.selectedCardDetails}>
													<p style={{ fontSize: '16px', margin: 0 }}>
														{track.item.name}
													</p>
													<p
														style={{
															fontSize: '14px',
															fontWeight: 'bold',
															margin: 0,
														}}
													>
														{track.item.artist}
													</p>
												</div>
											</div>
										))}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
