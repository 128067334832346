import { createSlice } from '@reduxjs/toolkit';

const appCheckSlice = createSlice({
	name: 'appCheck',
	initialState: {
		token: '',
	},
	reducers: {
		addToken: (state, action) => ({
			...state,
			token: action.payload,
		}),
	},
});

export const { addToken } = appCheckSlice.actions;

export default appCheckSlice.reducer;
