import { useState, useEffect } from 'react';
import { Typography, Grid, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button/Button';
import { ReactComponent as InactiveBusiness } from '../../assets/Inactive_Business.svg';

const OffBusiness = () => {
	const { t } = useTranslation();

	const [size, setSize] = useState(window.innerWidth);

	useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	return (
		<Container
			maxWidth="sm"
			sx={{
				height: '85vh',
			}}
		>
			<Grid
				container
				justifyContent="center"
				sx={{ height: '100%', paddingTop: '20%', paddingBottom: '20%' }}
			>
				<Grid
					container
					justifyContent={size < 600 ? 'center' : 'space-evenly'}
					alignItems="center"
				>
					{size < 600 ? (
						<>
							<Grid
								item
								xs={12}
								md={3}
								container
								spacing={0}
								direction="column"
								alignItems="center"
								justifyContent="center"
							>
								<InactiveBusiness width={130} />
							</Grid>
							<Grid item xs={12} md={9} style={{ marginBottom: 50 }}>
								<Grid container justifyContent="center" alignItems="flex-end">
									<Typography
										sx={{
											fontFamily: 'Inter',
											fontWeight: '700',
											fontSize: '20px',
											textAlign: 'center',
											color: '#FFFFFF',
										}}
									>
										{t('INACTIVEBUSINESS_title')}
									</Typography>
								</Grid>
								<Typography
									sx={{
										fontFamily: 'Inter',
										fontWeight: '400',
										fontSize: '20px',
										color: '#FFFFFF',
										flexShrink: 1,
										textAlign: 'center',
									}}
								>
									{t('INACTIVEBUSINESS_body')}
								</Typography>
							</Grid>
						</>
					) : (
						<>
							<Grid
								item
								xs={12}
								md={3}
								container
								spacing={0}
								direction="column"
								alignItems="center"
								justifyContent="center"
							>
								<InactiveBusiness width={100} />
							</Grid>
							<Grid item xs={12} md={9}>
								<Grid container>
									<Typography
										sx={{
											fontFamily: 'Inter',
											fontWeight: '700',
											fontSize: '20px',
											color: '#FFFFFF',
										}}
									>
										{t('INACTIVEBUSINESS_title')}
									</Typography>
								</Grid>
								<Typography
									sx={{
										fontFamily: 'Inter',
										fontWeight: '400',
										fontSize: '20px',
										color: '#FFFFFF',
										flexShrink: 1,
									}}
								>
									{t('INACTIVEBUSINESS_body')}
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
				<Grid container justifyContent="center">
					<Button
						buttonText={t('BUTTONS_text_accept')}
						buttonStyle={{ width: '100%' }}
						onClick={() => window.location.reload()}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default OffBusiness;
