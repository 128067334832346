import React from 'react';
import { colors } from '../../utils/colors';
import ButtonComponent from './Button';
import globalStyles from '../../utils/globalStyles.module.scss';

const ActionButtons = ({
	height = 60,
	width = '20%',
	acceptText,
	cancelText,
	textSize = '24px',
	acceptButtonColor = colors.blue,
	cancelButtonColor = colors.pink,
	onAccept,
	onCancel,
	disabledCancelButton = false,
	disabledAcceptButton = false,
}) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
				justifyContent: 'center',
			}}
		>
			<ButtonComponent
				backgroundColor={cancelButtonColor}
				buttonText={cancelText}
				height={height}
				buttonStyle={{
					marginRight: '21px',
				}}
				textStyle={{ fontFamily: globalStyles.fontFamily.fontFamily, fontSize: textSize }}
				width={width}
				onClick={onCancel}
				disabled={disabledCancelButton}
			/>
			<ButtonComponent
				backgroundColor={acceptButtonColor}
				buttonText={acceptText}
				height={height}
				textStyle={{
					fontFamily: globalStyles.fontFamily.fontFamily,
					fontSize: textSize,
				}}
				width={width}
				onClick={onAccept}
				disabled={disabledAcceptButton}
			/>
		</div>
	);
};

export default ActionButtons;
