import http from '../../http/http';
import React, { useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebaseInit';
import TheItem from './TheItem';

function BusinessType() {
	const location = useLocation();
	const params = useParams();
	const navigate = useNavigate();
	const [type] = React.useState(location.state.type);
	const [serviceDetails] = React.useState(location.state.serviceDetails);
	const [packs, setPacks] = React.useState([]);
	const [packSelected, setPackSelected] = React.useState();
	const [counter, setCounter] = React.useState(0);
	const codeRef = useRef();
	const onCounterHandler = type => {
		if (type === 'plus') {
			setCounter(prev => prev + 1);
		} else {
			setCounter(prev => prev - 1);
			if (counter - 1 === 0) {
				setPackSelected(undefined);
			}
		}
	};

	const onClickBuyHandler = data => {
		console.log(data);
		logEvent(analytics, 'cloudJukebox_web_model_selected', {
			modelName: 'Buy',
			price: data.item.price,
			tracksQty: data.item.itemsQty,
			packName: data.item.name,
		});

		navigate(`/checkout/${params.commerceId}`, {
			state: { maxTrack: +data.item.itemsQty, packId: data.item._id, price: data.item.price },
		});
		// navigate(`/playlist/${params.commerceId}`, {
		// 	state: { maxTrack: +data.item.itemsQty, packId: data.item._id },
		// });
		// if (params.playlistId) {
		// 	navigate(`/closed-playlist/${params.commerceId}/${params.playlistId}`, {
		// 		state: { maxTrack: +data.item.itemsQty, packId: data.item._id },
		// 	});
		// } else {
		// 	navigate(`/playlist/${params.commerceId}`, {
		// 		state: { maxTrack: +data.item.itemsQty, packId: data.item._id },
		// 	});
		// }
	};

	//TODO: THE PACK NAME MUST BE DINAMYC
	const onClickCodeHandler = () => {
		logEvent(analytics, 'cloudJukebox_web_model_selected', {
			modelName: 'Promo code',
			price: packSelected.price,
			tracksQty: packSelected.itemsQty,
			packName: packSelected.name,
			discount: packSelected.discount,
		});
		if (codeRef.current.value.trim() === '') {
			alert('You must select the promo code');
			return;
		}

		if (packSelected === undefined) {
			alert('You must select the pack');
			return;
		}
		// Info Here
		if (params.playlistId) {
			navigate(`/closed-playlist/${params.commerceId}/${params.playlistId}`, {
				state: { maxTrack: +packSelected.itemsQty, packId: packSelected._id },
			});
		} else {
			navigate(`/playlist/${params.commerceId}`, {
				state: { maxTrack: +packSelected.itemsQty, packId: packSelected._id },
			});
		}
	};
	React.useEffect(() => {
		const path = `pack/${params.commerceId}`;
		http.instance
			.get(path)
			.then(res => {
				setPacks(res.data.result);
			})
			.catch(error => {
				console.log(error);
			});
	}, [params]);

	return (
		<>
			{packs.length > 0 && (
				<div className={styles.container}>
					<div className={`${styles.header} row`}></div>
					<div
						style={{
							width: '90%',
							marginBottom: '25px',
							display: 'flex',
							justifyContent: 'space-evenly',
							marginTop: '60px',
						}}
						className="row"
					>
						<p
							style={{
								fontSize: '30px',
								fontWeight: 'bold',
								marginBottom: '60px',
							}}
						>
							Packs
						</p>

						{packs.map((item, index) => (
							<div className="col-sm-6 col-lg-2" key={item._id}>
								<TheItem
									key={item._id}
									styles={styles}
									counter={counter}
									onClickHandler={
										type === 'buy'
											? onClickBuyHandler
											: data => {
													setPackSelected({
														...data.item,
														discount: '20%',
													});
													onCounterHandler(data.type);
											  }
									}
									item={item}
									index={index}
								></TheItem>
							</div>
						))}

						{type === 'code' && (
							<div className={styles.container}>
								<p style={{ fontSize: '20px', fontWeight: 'bold' }}>
									Promotional Code
								</p>
								<input
									type="text"
									placeholder="A1B2C3"
									className={styles.inputSearch}
									ref={codeRef}
								/>
								<button className={styles.btnSearch} onClick={onClickCodeHandler}>
									Confirm
								</button>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default BusinessType;
