import http from '../../http/http';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from '../playlist/styles.module.scss';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebaseInit';
import { useSelector } from 'react-redux';

const ClosedPlaylist = () => {
	const [commerceId, setCommerceId] = React.useState('');
	const [currentPlaylist, setCurrentPlaylist] = React.useState([]);
	const [spotifyAuth, setSpotifyAuth] = React.useState({});
	const [tracks, setTracks] = React.useState([]);
	const [maxQty, setMaxQty] = React.useState();
	const [activeClosedPlaylistId, setActiveClosedPlaylistId] = React.useState('');
	const [spotifyIds, setSpotifyIds] = React.useState([]);
	const { customerData } = useSelector(state => state.customer);
	const { tokensFCM } = useSelector(state => state.customer);
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const onSubmit = async () => {
		const path = `playlist/add-to-queue?`;
		const transactionPath = `transaction/create`;

		const customer = { ...customerData, tokensFCM, commerceId: commerceId };
		const res = await http.instance.post(transactionPath, {
			customer,
		});

		const body = {
			commerceId: commerceId,
			tracks: spotifyIds,
			customerId: res.data.result.customerId,
			isFreePack: location.state.amount === 0 ? true : false,
		};

		await http.instance.post(path, body, {
			headers: {
				access_token: spotifyAuth.accessToken,
			},
		});

		logEvent(analytics, 'cloudJukebox_web_add_to_quoue', {
			tracksName: spotifyIds.map(track => track.name),
		});

		navigate('/finish/');
	};

	const getCurrentPlaylist = React.useCallback(
		commerceId => {
			const path = `playlist/current/${commerceId}`;
			http.instance
				.get(path, {
					headers: {
						access_token: spotifyAuth.accessToken,
					},
				})
				.then(res => {
					if (res.data.result.tracks !== undefined) {
						setCurrentPlaylist(res.data.result.tracks);
					}
				})
				.catch(error => console.log(error));
		},
		[spotifyAuth.accessToken],
	);

	const getTracks = (activeClosedPlaylistId, param) => {
		const search = param ?? '';
		const path = `playlist/get-tracks/${activeClosedPlaylistId}?search=${search}`;
		http.instance
			.get(path)
			.then(res => {
				if (res.data.result) {
					setTracks(res.data.result.tracks);
				}
			})
			.catch(error => console.log(error));
	};

	const getCommerce = commerceId => {
		const path = `commerce/${commerceId}`;
		http.instance
			.get(path)
			.then(res => {
				setSpotifyAuth(res.data.result.spotifyAuth);
				setActiveClosedPlaylistId(params.playlistId);
				setMaxQty(
					res.data.result.restrictions.find(
						item => item.restrictionName === 'quantity_by_hour',
					).value,
				);
			})
			.catch(error => console.log(error));
	};

	const onAddTrackHandler = track => {
		const { spotifyId, name, artist, image, uri } = track;
		const trackObj = {
			spotifyId,
			name,
			artist,
			image,
			uri,
		};

		if (location.state !== null) {
			const { maxTrack } = location.state;
			if (maxTrack >= spotifyIds.length + 1) {
				setSpotifyIds(prev => [...prev, trackObj]);
			} else {
				alert('You have selected the maximum number of tracks');
			}
		} else {
			if (maxQty >= spotifyIds.length + 1) {
				setSpotifyIds(prev => [...prev, trackObj]);
			} else {
				alert('You have selected the maximum number of tracks');
			}
		}
	};

	const onDeleteTrackHandler = index => {
		const updatedSpotifyIds = [...spotifyIds];
		const existIndex = updatedSpotifyIds.indexOf(index);
		if (existIndex > -1) {
			updatedSpotifyIds.splice(existIndex, 1);
			setSpotifyIds(updatedSpotifyIds);
		}
	};

	const onChangeSearch = ({ target }) => {
		getTracks(activeClosedPlaylistId, target.value);
	};

	React.useEffect(() => {
		setCommerceId(params.commerceId);
	}, []);

	React.useEffect(() => {
		if (activeClosedPlaylistId !== '') {
			getTracks(activeClosedPlaylistId);
		}
	}, [activeClosedPlaylistId]);

	React.useEffect(() => {
		if (commerceId !== '') {
			getCurrentPlaylist(commerceId);
			getCommerce(commerceId);
		}
	}, [commerceId]);

	return (
		<div className={`${styles.playlistContainer}`}>
			<div className={`row`}>
				<div className={`col-md-4 ${styles.searchContainer}`}>
					<p
						style={{
							fontSize: '20px',
							fontWeight: 'bold',
							color: '#ffffff',
							marginTop: '10px',
						}}
					>
						Search Tracks
					</p>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<input
							type="text"
							placeholder="saoko, bad bunny..."
							onChange={onChangeSearch}
							className={styles.inputSearch}
							style={{
								borderTopRightRadius: '8px',
								borderBottomRightRadius: '8px',
							}}
						/>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}
					>
						{tracks.map((track, index) => (
							<div
								className={`${styles.card} ${styles.boxShadow}`}
								key={index}
								onClick={() => onAddTrackHandler(track.item)}
								style={{
									display: 'flex',
									margin: '20px 0',
								}}
							>
								<img src={track.item.image} alt="img" height={95} />
								<div className={styles.card_details}>
									<p style={{ fontSize: '0.8em', margin: 0 }}>
										{track.item.name}
									</p>
									<p
										style={{
											fontSize: '0.7em',
											fontWeight: '650',
											margin: 0,
										}}
									>
										{track.item.artist}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className={`col-md-8`}>
					<div className={'row'} style={{ justifyContent: 'center', marginTop: '30px' }}>
						<div className="row">
							{spotifyIds.length > 0 && (
								<>
									<h3 style={{ color: '#ffffff', marginBottom: '15px' }}>
										Seleccionados
									</h3>
									{spotifyIds.map((track, index) => (
										<div
											className={`${styles.selectedCard} col-md-12 col-lg-3`}
											key={index}
											onClick={() => onDeleteTrackHandler(track)}
											style={{ margin: '0 auto' }}
										>
											<img src={track.image} alt="img" height={100} />
											<div className={styles.selectedCardDetails}>
												<p style={{ fontSize: '16px', margin: 0 }}>
													{track.name}
												</p>
												<p
													style={{
														fontSize: '14px',
														fontWeight: 'bold',
														margin: 0,
													}}
												>
													{track.artist}
												</p>
											</div>
										</div>
									))}
									<div>
										<button
											className={styles.btnSubmit}
											onClick={() => onSubmit()}
										>
											Submit
										</button>
									</div>
								</>
							)}
						</div>
						<div
							className="row"
							style={{ justifyContent: 'space-evenly', marginTop: '10px' }}
						>
							<h3 style={{ color: '#ffffff' }}>Lista</h3>

							{currentPlaylist.length > 0 ? (
								<>
									{currentPlaylist.map((track, index) => (
										<div
											className={`${styles.selectedCard} ${styles.boxShadow} col-md-12 col-xl-3`}
											key={index}
											onClick={() => onDeleteTrackHandler(track)}
										>
											<img src={track.item.image} alt="img" height={80} />
											<div className={styles.selectedCardDetails}>
												<p style={{ fontSize: '0.8em', margin: 0 }}>
													{track.item.name}
												</p>
												<p
													style={{
														fontSize: '0.7em',
														fontWeight: 'bold',
														margin: 0,
													}}
												>
													{track.item.artist}
												</p>
											</div>
										</div>
									))}
								</>
							) : (
								<>
									<p>No current playlist</p>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClosedPlaylist;
