import { createSlice } from '@reduxjs/toolkit';

const trackSlice = createSlice({
	name: 'track',
	initialState: {
		trackData: [],
	},
	reducers: {
		addTrackData: (state, action) => ({
			...state,
			trackData: [...action.payload],
		}),
	},
});

export const { addTrackData } = trackSlice.actions;

export default trackSlice.reducer;
