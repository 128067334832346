import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebaseInit';
import http from '../../http/http';
import 'react-phone-number-input/style.css';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { addCustomerData } from '../../redux/reducers/customerReducer';

function BusinessModel() {
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const [, setBusinessModelData] = React.useState([]);
	//TODO: CHANGE THIS. MAKE IT DYNAMIC. (TRAER LOS MODELOS COMERCIALES Y RECORRERLOS CON UN MAP). DE ESE MODO SI SE AGREGA UNO NUEVO EN BD NO ES NECESARIO CAMBIAR EL CODIGO AQUI
	const [freeModelData, setFreeModelData] = React.useState([]);
	const [buyModelData, setbuyModelData] = React.useState([]);
	const [isActiveSession, setIsActiveSession] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const [promotionalCodeData, setPromotionalCodeData] = React.useState([]);

	React.useEffect(() => {
		checkSession();
	}, []);

	const checkLastAccess = (email, phone, commerceId) => {
		const path = `customer/check-last-access`;
		const body = {
			email,
			phone,
			commerceId,
		};

		return http.instance.post(path, body);
	};

	const checkSession = () => {
		const email = localStorage.getItem('email');
		const phone = localStorage.getItem('phone');

		checkLastAccess(email, phone, params.commerceId)
			.then(resp => {
				const body = {
					email,
					phone,
				};
				dispatch(addCustomerData(body));
				setIsActiveSession(true);
			})
			.catch(err => {
				console.log(err);
				const { response } = err;
				const { data } = response;
				let error;
				if (Array.isArray(data.message)) {
					setErrorMessage(data.message[0]);
				} else {
					setErrorMessage(data.message);
				}

				setIsActiveSession(false);
			});
	};

	React.useEffect(() => {
		const path = `business-model-commerce/get-business-model/${params.commerceId}`;
		http.instance
			.get(path, {})
			.then(res => {
				setBusinessModelData(res.data.result);

				const freeModelData = res.data.result.find(
					data => data.businessModelId.name === 'Free',
				);

				setFreeModelData(freeModelData);

				const buyModelData = res.data.result.find(
					data => data.businessModelId.name === 'Buy',
				);

				setbuyModelData(buyModelData);

				const promotionalCodeData = res.data.result.find(
					data => data.businessModelId.name === 'Promotional code',
				);

				setPromotionalCodeData(promotionalCodeData);
			})
			.catch(error => console.log(error));
	}, [params.commerceId]);

	const onFreeHandler = () => {
		if (isActiveSession) {
			logEvent(analytics, 'cloudJukebox_web_model_selected', {
				modelName: 'Free',
				price: 0,
				tracksQty: location.state.restrictions[1].value,
			});

			if (freeModelData.active && freeModelData.activeClosedPlaylist) {
				navigate(
					`/closed-playlist/${params.commerceId}/${freeModelData.activeClosedPlaylist}`,
				);
			} else {
				navigate(`/playlist/${params.commerceId}`);
			}
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: errorMessage,
				allowOutsideClick: false,
				allowEscapeKey: false,
				showCloseButton: true,
				confirmButtonText: 'Use another account',
				confirmButtonColor: 'green',
			}).then(result => {
				if (result.isConfirmed) {
					localStorage.clear();
					navigate(`/home/${params.commerceId}`);
				}
			});
		}
	};

	const onBuyHandler = async () => {
		console.log(isActiveSession);
		if (isActiveSession) {
			if (buyModelData.active && buyModelData.activeClosedPlaylist) {
				navigate(
					`/business-type/${params.commerceId}/${buyModelData.activeClosedPlaylist}`,
					{
						state: { ...location.state, type: 'buy' },
					},
				);
			} else {
				navigate(`/business-type/${params.commerceId}`, {
					state: { ...location.state, type: 'buy' },
				});
			}
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: errorMessage,
				allowOutsideClick: false,
				allowEscapeKey: false,
				showCloseButton: true,
				confirmButtonText: 'Use another account',
				confirmButtonColor: 'green',
			}).then(result => {
				if (result.isConfirmed) {
					localStorage.clear();
					navigate(`/home/${params.commerceId}`);
				}
			});
		}
	};

	const onPromotionalCode = () => {
		if (isActiveSession) {
			if (promotionalCodeData.active && promotionalCodeData.activeClosedPlaylist) {
				navigate(
					`/business-type/${params.commerceId}/${promotionalCodeData.activeClosedPlaylist}`,
					{
						state: { ...location.state, type: 'code' },
					},
				);
			} else {
				navigate(`/business-type/${params.commerceId}`, {
					state: { ...location.state, type: 'code' },
				});
			}
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: errorMessage,
				allowOutsideClick: false,
				allowEscapeKey: false,
				showCloseButton: true,
				confirmButtonText: 'Use another account',
			}).then(result => {
				if (result.isConfirmed) {
					localStorage.clear();
					navigate(`/home/${params.commerceId}`);
				}
			});
		}
	};

	return (
		<>
			<div className={styles.container}>
				<div className={`${styles.header} row`}></div>
				<div
					style={{
						width: '90%',
						marginBottom: '25px',
						display: 'flex',
						justifyContent: 'space-around',
						marginTop: '60px',
					}}
					className="row"
				>
					{/* TODO: RECORRER ESTOS MODELOS DINAMICAMENTE */}
					<p style={{ fontSize: '30px', fontWeight: 'bold', marginBottom: '60px' }}>
						Commercial Models
					</p>
					{freeModelData && freeModelData.active && (
						<div className={`${styles.item} col-5`} onClick={onFreeHandler}>
							<p className={styles.itemTitle}>Free</p>
							<p className={styles.itemDescription}>
								Choose songs for free but with certain limitations
							</p>
						</div>
					)}
					{buyModelData && buyModelData.active && (
						<div
							className={`${styles.item} ${styles.coloredItem} col-5`}
							onClick={onBuyHandler}
						>
							<p className={styles.itemTitle}>Buy</p>
							<p className={styles.itemDescription}>
								Select and pay for a pack and get access to many songs
							</p>
						</div>
					)}
					{promotionalCodeData && promotionalCodeData.active && (
						<div className={`${styles.item} col-5`} onClick={onPromotionalCode}>
							<p className={styles.itemTitle}>Promotional code</p>
							<p className={styles.itemDescription}>
								Apply a promotional code and get a discount on packs
							</p>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default BusinessModel;
