/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { AES, enc } from 'crypto-js';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import http from '../../http/http';
import Swal from 'sweetalert2';

import styles from './styles.module.scss';

const key = process.env.REACT_APP_KEY;
export default function ResetPassword() {
	const { token } = useParams();
	const [tokenError, setTokenError] = React.useState('');
	const [incorrectTokenError, setIncorrectTokenError] = React.useState('');
	const [data, setData] = React.useState('');
	const [formSubmitted, setFormSubmitted] = React.useState(false);
	const [password, setPassword] = React.useState('');
	const [passwordConfirm, setPasswordConfirm] = React.useState('');
	const [errorPassword, setErrorPassword] = React.useState('');

	useEffect(() => {
		const cleanData = token.replaceAll('*', '/');
		const dataDecrypt = AES.decrypt(cleanData, key).toString(enc.Utf8);
		if (!dataDecrypt) {
			setIncorrectTokenError('The token is invalid');
			return;
		}
		const data = JSON.parse(dataDecrypt);

		setData(data);
		const now = new Date();
		const createdAt = new Date(data.createdAt);
		createdAt.setHours(createdAt.getHours() + 1);

		if (now > createdAt) {
			setTokenError('The token has expired');
		}
	}, []);

	const onSubmit = async event => {
		event.preventDefault();
		if (password !== passwordConfirm) {
			setErrorPassword('The passwords do not match');
			return;
		}

		const path = `firebase/update-password/${data.uid}`;
		const passwordEncrypt = AES.encrypt(password, key).toString();

		http.instance
			.post(path, { password: passwordEncrypt })
			.then(resp => {
				Swal.fire({
					icon: 'success',
					title: 'Password updated',
					text: 'You can go back and login',
				});
				setFormSubmitted(true);
			})
			.catch(error => {
				console.log(error);
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Something went wrong',
				});
			});
	};

	return (
		<div className={styles.authFormContainer}>
			{!formSubmitted ? (
				tokenError.length > 0 || incorrectTokenError ? (
					<div>{tokenError || incorrectTokenError}</div>
				) : (
					<form className={styles.authForm}>
						<div className={styles.authFormContent}>
							<div className="form-group mt-3">
								<label style={{ color: '#ffffff' }}>Password</label>
								<input
									type="password"
									className="form-control"
									placeholder="Enter password"
									onInput={event => {
										setErrorPassword('');
										setPassword(event.target.value);
									}}
								/>
							</div>
							<div className="form-group mt-3">
								<label style={{ color: '#ffffff' }}>Confirm password</label>
								<input
									type="password"
									className="form-control"
									placeholder="Confirm password"
									onInput={event => {
										setErrorPassword('');
										setPasswordConfirm(event.target.value);
									}}
								/>
							</div>
							<div className="d-grid gap-2 mt-3">
								<button
									type="submit"
									className={`${styles.btnSubmit} btn`}
									onClick={event => onSubmit(event)}
								>
									Submit
								</button>
							</div>
							<p style={{ color: 'red', fontSize: '12px' }}>{errorPassword}</p>
						</div>
					</form>
				)
			) : (
				<p>Form submitted</p>
			)}
		</div>
	);
}
