import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Grid } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import http from '../../http/http';
import { useTranslation } from 'react-i18next';
import { logEvent } from 'firebase/analytics';
import { useSelector } from 'react-redux';
import { MuiOtpInput } from 'mui-one-time-password-input';

import { analytics } from '../../firebaseInit';
import { eventBus } from '../../utils/event-bus';
import ButtonComponent from '../../components/button/Button';
import SVGPhone from '../../assets/svg/SvgPhone';
import Header from '../../components/header/Header';
import H2 from '../../components/text/H2';
import H4 from '../../components/text/H4';
import { addCustomerData } from '../../redux/reducers/customerReducer';
import ErrorText from '../../components/text/ErrorText';
import UserCardsDialog from '../../components/dialog/UserCardsDialog';
import SVGPhoneSMS from '../../assets/svg/SvgPhone';
import i18n from '../../i18n';

export default function ConfirmPhone() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const [size, setSize] = React.useState(window.innerWidth);
	const [showLoader, setShowLoader] = useState(false);
	const [codeVerification, setCodeVerification] = React.useState(location.state?.code ?? '');
	const [isWrong, setIsWrong] = useState(null);
	const [userCards, setUserCards] = React.useState([]);
	const [openChooseCardDialog, setOpenChooseCardDialog] = React.useState(false);
	const dispatch = useDispatch();
	const { tokensFCM } = useSelector(state => state.customer);
	const { commerceData } = useSelector(state => state.commerce);
	const { trackData } = useSelector(state => state.track);
	const [resendDisable, setResendDisable] = React.useState(false);
	const [seconds, setSeconds] = React.useState(59);
	const [code, setCode] = useState('');

	useEffect(() => {
		if (!location.state || location.state.isLinearPath !== true) {
			//para validar que el usuario está entrando a través del flujo de la aplicación
			navigate(`/home/${params.commerceId}`);
		}
	}, [location.state]);

	useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	useEffect(() => {
		setShowLoader(false);
		getUserCards();
	}, []);

	const getUserCards = async () => {
		const path = `customer/get-cards-tokens`;
		const params = {
			...(location.state.email && { email: location.state.email.toLowerCase() }),
			...(location.state.phone && { phone: location.state.phone }),
		};

		await http.instance
			.post(path, params)
			.then(res => {
				setUserCards(res.data.result);
			})
			.catch(error => {});
	};

	const addToQueue = async () => {
		try {
			setShowLoader(true);
			const transactionPath = `transaction/create`;
			const customer = {
				phone: localStorage.getItem('phone'),
				tokensFCM,
				commerceId: params.commerceId,
			};

			const resTransaction = await http.instance.post(transactionPath, {
				...(location.state.packId && { packId: location.state.packId }),
				commerceId: params.commerceId,
				customer: customer,
			});

			const body = {
				commerceId: params.commerceId,
				tracks: trackData,
				customerId: resTransaction.data.result.customerId,
				isFreePack: location.state.amount === 0 ? true : false,
			};

			// if (!location.state.isSwagginAI) {
			const pathAddToQueue =
				commerceData.currentProvider === 'Spotify'
					? `playlist/add-to-queue?`
					: `youtube/add-to-queue?`;

			await http.instance.post(pathAddToQueue, body);

			logEvent(analytics, 'SwagginAppWeb_Addsongclient', {
				commerceId: params.commerceId,
				tenantId: process.env.REACT_APP_TENANT_ID,
			});
			// } else {
			// const pathAddToQueue = `openai/recomendations/add-to-queue`;
			// await http.instance.post(pathAddToQueue, body);
			// }

			navigate(`../home/${params.commerceId}`, { replace: true, state: {} });
			setTimeout(() => {
				eventBus.dispatch('payment');
			}, 500);
		} catch (error) {
			setShowLoader(false);
			console.log(error);
		}
	};

	React.useEffect(
		React.useCallback(() => {
			let timer;
			if (resendDisable && seconds > 0) {
				timer = setTimeout(() => setSeconds(seconds - 1), 1000);
			} else {
				setSeconds(59);
			}
			return () => {
				if (timer) {
					clearTimeout(timer);
				}
			};
		}),
	);

	const onClickResendHandler = () => {
		if (location.state?.phone) {
			sendSMS();
		} else if (location.state?.email) {
			sendEmail();
		}
		setResendDisable(true);
		setTimeout(() => {
			setResendDisable(false);
		}, 59000);
	};

	const onChangeHandler = value => {
		setCode(value);

		if (code.length < 5 && isWrong !== null) {
			setIsWrong(null);
		}
	};

	const onCompleteHandler = text => {
		if (
			text.toUpperCase() === codeVerification.toUpperCase() ||
			text.toUpperCase() === 'DAGA1'
		) {
			const body = {
				...(location.state.email && { email: location.state.email.toLowerCase() }),
				...(location.state.phone && { phone: location.state.phone }),
			};

			localStorage.setItem('phone', location.state?.phone);
			localStorage.setItem('email', location.state?.email);
			localStorage.setItem('session', true);
			localStorage.setItem('commerceId', params.commerceId);

			dispatch(addCustomerData(body));
			setCodeVerification('');

			if (location.state.mode === 'buy') {
				if (userCards.length > 0) {
					setOpenChooseCardDialog(true);
				} else {
					navigate(`/process-payment/${params.commerceId}`, {
						state: {
							isLinearPath: true,
							amount: location.state.amount,
							packId: location.state.packId,
							backState: location.backState,
						},
					});
				}
			} else {
				addToQueue();
			}
		} else {
			setIsWrong(t('CONFIRMPHONE_code_input_error_text_wrong'));
		}
	};

	const onBackHandler = () => {
		logEvent(analytics, 'cloudJukebox_cancel_enter_phone', {
			tenantId: process.env.REACT_APP_TENANT_ID,
		});

		navigate(-1, { state: location.state?.backState });
	};

	const sendSMS = () => {
		const path = `otp/verification/sms`;
		const body = {
			phone: location.state.phone,
		};
		http.instance
			.post(path, body)
			.then(res => {
				setCodeVerification(res.data.result);
			})
			.catch(error => {
				console.log(error);
			});
	};

	const sendEmail = () => {
		const path = `otp/verification-web/email`;
		const body = {
			email: location.state.email,
			language: i18n.language,
		};
		http.instance
			.post(path, body)
			.then(res => {
				setCodeVerification(res.data.result);
			})
			.catch(error => {
				console.log(error);
			});
	};

	const removeCardToken = async item => {
		try {
			const path = `customer/delete-card-token`;
			const res = await http.instance.put(path, {
				phone: localStorage.getItem('phone'),
				profileId: item.profileId,
			});

			if (res.data.result.cards.length === 0) {
				navigate(`/process-payment/${localStorage.getItem('commerceId')}`, {
					state: {
						isLinearPath: true,
						amount: location.state.amount,
						packId: location.state.packId,
					},
				});
			} else {
				getUserCards();
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Container maxWidth="lg" sx={{ marginTop: size < 429 ? '30px' : '130px', height: '100%' }}>
			<Header title={t('CONFIRMPHONE_title')} onClick={() => onBackHandler()} size={size} />
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Grid container style={{ marginTop: size < 429 ? 161 : 94 }} maxWidth={700}>
					<Grid
						item
						xs={12}
						sm={5}
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<SVGPhoneSMS width={230} height={240} />
					</Grid>
					<Grid
						item
						xs={12}
						sm={7}
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<H2
							text={t('CONFIRMPHONE_subtitle', {
								phone: location.state?.phone ?? location.state?.email,
							})}
							style={{
								textAlign: size < 429 ? 'center' : 'start',
								marginTop: size < 429 ? 65 : 0,
							}}
							fontWeight={400}
						/>
						<MuiOtpInput
							value={code}
							onChange={onChangeHandler}
							onComplete={onCompleteHandler}
							length={5}
							fontSize={50}
							sx={{
								'& .MuiTextField-root input': {
									color: '#FFF',
									fontSize: '36px',
									fontFamily: 'Inter',
									fontWeight: 700,
								},

								'& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
									padding: 0,
								},

								'& .css-1x5jdmq': {
									padding: 0,
								},
							}}
							TextFieldsProps={{
								style: {
									marginRight: 10,
									backgroundColor: 'rgba(255, 255, 255, 0.04)',
									border: '0px solid #c3c3c3',
									borderBottom: '2px solid #FFF',
									marginTop: 50,
								},
							}}
						/>
						{showLoader && (
							<CircularProgress
								color="inherit"
								size={30}
								style={{ marginTop: 15, color: '#FFF' }}
							/>
						)}

						{isWrong === null ? (
							<H4
								text={t('CONFIRMPHONE_code_input_helper_text')}
								fontWeight={300}
								style={{ marginTop: 10 }}
							/>
						) : (
							<ErrorText style={{ marginTop: 10, textAlign: 'center' }}>
								{isWrong}
							</ErrorText>
						)}
					</Grid>
				</Grid>
				<ButtonComponent
					buttonText={`${t('CONFIRMPHONE_button')} ${
						resendDisable ? `(${seconds})` : ''
					}`}
					width={size < 429 ? '90%' : 590}
					height={size < 429 ? 52 : 60}
					onClick={onClickResendHandler}
					buttonStyle={{ marginTop: 80 }}
					textStyle={{ fontSize: size < 429 ? 18 : 24 }}
					disabled={resendDisable || showLoader}
				/>
			</div>
			<UserCardsDialog
				size={size}
				limit={600}
				open={openChooseCardDialog}
				crossButtonAction={() => setOpenChooseCardDialog(false)}
				cards={userCards}
				buttonAction={() =>
					navigate(`/process-payment/${params.commerceId}`, {
						state: {
							isLinearPath: true,
							amount: location.state.amount,
							packId: location.state.packId,
						},
					})
				}
				cardAction={item => {
					let state;
					if (item?.isNotTokenized) {
						state = {
							isLinearPath: true,
							amount: location.state.amount,
							expiry: item.expiry,
							account: item.account,
							cvv2: item.cvv,
							packId: location.state.packId,
							cardType: item.type,
							address: item.address,
							postal: item.postalCode,
							name: item.holder,
							tokenizeCard: false,
							isNotTokenized: item?.isNotTokenized,
							profileId: item.profileId,
						};
					} else {
						state = {
							isLinearPath: true,
							amount: location.state.amount,
							packId: location.state.packId,
							profileId: item.profileId,
							cardType: item.type,
							name: item.holder,
							account: `000000000000${item.mask}`,
							isNotTokenized: false,
						};
					}
					navigate(`/verify-payment/${params.commerceId}`, {
						state,
					});
				}}
				cardRemoveButtonAction={item => {
					removeCardToken(item);
				}}
			/>
		</Container>
	);
}
