import React from 'react';
import AmericaExpress from './AmericaExpress';
import Generic from './Generic';
import MasterCard from './MasterCard';
import Maestro from './Maestro';
import Visa from './Visa';
import DinersClub from './DinersClub';
import Discover from './Discover';
import UnionPay from './UnionPay';
import Jcb from './Jcb';

export default function CreditCardIcon({ brand, width }) {
	switch (brand) {
		case 'visa':
			return <Visa width={`${width}px`} />;
		case 'mastercard':
			return <MasterCard width={`${width}px`} />;
		case 'american-express':
			return <AmericaExpress width={`${width}px`} />;
		case 'maestro':
			return <Maestro width={`${width}px`} />;
		case 'diners-club':
			return <DinersClub width={`${width}px`} />;
		case 'discover':
			return <Discover width={`${width}px`} />;
		case 'unionpay':
			return <UnionPay width={`${width}px`} />;
		case 'jcb':
			return <Jcb width={`${width}px`} />;
		case '':
			return <Generic width={`${width}px`} />;
	}
}
