import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import styles from '../track-card/styles.module.scss';
import Checkbox from '@mui/material/Checkbox';
import { eventBus } from '../../utils/event-bus';

export default function TrackCard(props) {
	const trackRef = useRef();
	// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
	// const [checked, setChecked] = React.useState(false);
	// const [disabled, setDisabled] = React.useState(false);

	useEffect(() => {
		trackRef.current.classList.remove(styles.selected_card);
	}, [props.track]);

	// const onChangeHandler = event => {
	// 	setChecked(event.target.checked);
	// 	props.onUpdateTracksHandler(props.track, event.target.checked);
	// };

	// React.useEffect(() => {
	// 	eventBus.on('disabled', () => {
	// 		if (checked === false) {
	// 			setDisabled(true);
	// 		} else {
	// 			console.log('hola');
	// 			setDisabled(false);
	// 		}
	// 	});

	// 	eventBus.on('enabled', () => {
	// 		setDisabled(false);
	// 	});
	// }, []);

	return (
		<div
			className={`${styles.card} ${styles.box_shadow}`}
			key={props.index}
			ref={trackRef}
			onClick={() => props.onAddTrackHandler(props.track)}
		>
			<img src={props.track.album.images[0].url} alt="img" height={95} />
			<div className={styles.card_details}>
				<p className={styles.name}>{props.track.name}</p>
				<p className={styles.description}>{props.track.artists[0].name}</p>
			</div>
			{/* <div style={{ backgroundColor: '#fff', display: 'flex' }}>
				{disabled}
				<Checkbox
					{...label}
					color="success"
					style={{ borderColor: 'white' }}
					checked={props.checked}
					onChange={onChangeHandler}
					disabled={disabled}
				/>
			</div> */}
		</div>
	);
}
