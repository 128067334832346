import { combineReducers } from '@reduxjs/toolkit';
import customerReducer from './customerReducer';
import commerceReducer from './commerceReducer';
import trackReducer from './trackReducer';
import appCheckReducer from './appCheckReducer';

const rootReducer = combineReducers({
	customer: customerReducer,
	commerce: commerceReducer,
	track: trackReducer,
	appCheck: appCheckReducer,
});

export default rootReducer;
