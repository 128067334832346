import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import IconCard from './IconCard';
import H2 from '../text/H2';
import H3 from '../text/H3';
import H6 from '../text/H6';
import Caption from '../text/Caption';

const PackCard = ({ index, icon, onClick, name, tracks, price, available, large, size }) => {
	const { t } = useTranslation();
	if (large) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: 14,
					paddingRight: 5,
					backgroundColor: '#233448',
					borderRadius: 15,
					cursor: 'pointer',
				}}
				onClick={onClick}
			>
				<Grid container sx={{ padding: '10px' }}>
					<Grid
						item
						xs={8}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							padding: 0,
						}}
					>
						<IconCard
							icon={icon ?? require('../../assets/pack.png')}
							containerStyle={{
								marginRight: '15px',
							}}
							width={size < 300 ? 45 : 70}
							height={size < 300 ? 45 : 65}
							iconStyle={{
								width: size < 300 ? 25 : 41,
								height: size < 300 ? 20 : 33,
							}}
						/>
						<Grid
							container
							display="flex"
							flexDirection="column"
							justifyContent="center"
						>
							<H6 text={name} fontSize={size < 300 ? 12 : undefined} />
							<Caption
								text={`${t('PACK_card_track_length1')} ${tracks} ${t(
									'PACK_card_track_length2',
								)}`}
								fontSize={size < 300 ? 10 : undefined}
								fontWeight={400}
							/>
							<Caption
								text={price === 0 ? t('PACK_card_free') : '$' + price.toFixed(2)}
								fontSize={size < 300 ? 10 : undefined}
								fontWeight={700}
							/>
						</Grid>
					</Grid>

					<Grid item xs={4}>
						<Grid
							container
							direction={'column-reverse'}
							justifyContent="center"
							style={{ height: '100%', textAlign: 'center' }}
						>
							<Caption
								fontSize={size < 300 ? 10 : undefined}
								text={
									available === 0
										? t('PACK_card_track_available_alt')
										: `${t('PACK_card_track_available1')} ${available} ${t(
												'PACK_card_track_available2',
										  )}`
								}
							/>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	} else {
		return (
			<div
				style={{
					textAlign: 'center',
					marginRight: 20,
					width: 280,
					border: 2,
					paddingTop: 30,
					paddingBottom: 30,
					borderRadius: '0 20px 0 20px',
					backgroundColor: '#233448',
					marginBottom: 20,
					cursor: 'pointer',
				}}
				onClick={onClick}
			>
				<IconCard
					icon={icon ?? require('../../assets/pack.png')}
					containerStyle={{
						marginBottom: '20px',
						marginX: 'auto',
					}}
				/>

				<H2 text={name} />
				<div style={{ marginTop: 5 }}>
					<H3
						text={`${t('PACK_card_track_length1')} ${tracks} ${t(
							'PACK_card_track_length2',
						)}`}
						fontSize={20}
					/>
					<H2
						text={price === 0 ? t('PACK_card_free') : '$' + price.toFixed(2)}
						fontSize={20}
					/>
				</div>
				<H3
					text={
						available === 0
							? t('PACK_card_track_available_alt')
							: `${t('PACK_card_track_available1')} ${available} ${t(
									'PACK_card_track_available2',
							  )}`
					}
				/>
			</div>
		);
	}
};

export default PackCard;
