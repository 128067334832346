import React from 'react';
import Typography from '@mui/material/Typography';

const H2 = ({ text, color, fontSize, fontWeight, style }) => {
	return (
		<Typography
			style={{
				fontSize: fontSize ?? 24,
				fontWeight: fontWeight ?? 700,
				color: color ?? '#FFF',
				...style,
			}}
		>
			{text}
		</Typography>
	);
};

export default H2;
