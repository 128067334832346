import React from 'react';

export default function Generic({ width }) {
	return (
		<svg width={width} viewBox="0 0 780 500" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M40 0h700c22 0 40 18 40 40v420c0 22-18 40-40 40H40c-22 0-40-18-40-40V40C0 18 18 0 40 0z"
				fill="#16366F"
			/>
			<path d="M449 250a180 180 0 11-359 0 180 180 0 01359 0" fill="#D9222A" />
			<path
				d="M510 70a179 179 0 00-138 66h36l14 19h-64l-10 19h84l8 19H340l-5 19h110a180 180 0 01-5 95H340l8 19h84l-10 19h-64l14 19h36c-5 7-12 13-18 19A180 180 0 10511 70"
				fill="#EE9F2D"
			/>
			<path d="M666 350a6 6 0 1112 0 6 6 0 01-12 0zm6 4a4 4 0 000-8c-3 0-5 2-5 4s2 4 5 4zm-1-1h-1v-5h3l1 1-1 1 1 3h-1l-1-2h-1v2zm0-3h1l1-1h-1v-1h-1v2zm-443-80l-5-1c-11 0-16 4-16 12 0 4 2 7 7 7 8 0 13-7 14-18zm14 33h-16v-8c-5 6-12 9-20 9-11 0-18-8-18-20 0-18 12-29 34-29l8 1 1-5c0-5-4-7-13-7s-17 2-20 4l2-17c10-3 16-4 24-4 16 0 25 7 25 22l-1 14-6 40zm-62 0h-20l11-70-25 70h-13l-2-70-11 70h-19l16-91h28l1 51 18-51h31l-15 91m355-33l-5-1c-11 0-16 4-16 12 0 4 2 7 7 7 8 0 13-7 14-18zm14 33h-16v-8c-5 6-12 9-20 9-11 0-18-8-18-20 0-18 12-29 34-29l8 1 1-5c0-5-4-7-13-7s-17 2-20 4l2-17c10-3 16-4 24-4 16 0 25 7 25 22l-1 14-6 40zm-221-1l-14 2c-10 0-15-6-15-16l3-20 8-51h19l-2 12h12l-3 17h-11l-6 34c0 4 2 6 7 6l5-1-3 17m60-1c-7 2-13 3-20 3-22 0-33-11-33-33 0-25 14-44 34-44 16 0 26 10 26 27l-2 18h-39c-1 11 6 15 17 15 7 0 13-1 20-4l-3 18zm-11-44c0-2 2-13-9-13-6 0-11 5-13 13h22zm-124-5c0 9 5 16 15 21 8 3 9 4 9 8s-3 7-11 7c-6 0-11-1-17-3l-3 17c5 1 8 2 20 2 21 0 30-8 30-25 0-10-4-16-13-20-8-4-9-5-9-9s3-6 9-6l14 2 3-18-17-1c-22 0-30 11-30 25m230-23c5 0 10 1 17 5l3-20c-3-1-13-8-21-8-13 0-24 7-32 18-11-4-16 3-22 11l-5 1 1-7h-18l-10 69-1 5h19c4-21 5-35 6-44l8-4c1-4 4-6 11-6l-1 16c0 24 13 39 34 39l17-3 4-20c-7 3-12 4-17 4-11 0-18-8-18-22 0-20 10-34 25-34" />
			<path
				d="M185 297h-19l11-70-25 70h-13l-2-69-12 69h-18l15-91h28l1 57 19-57h30l-15 91"
				fill="#fff"
			/>
			<path d="M648 212l-5 26c-5-7-11-12-18-12-10 0-19 7-25 18l-17-4 1-11h-18c-2 23-6 46-10 69l-1 5h20l6-43c7-6 10-11 17-11-3 7-5 16-5 24 0 19 9 31 23 31 8 0 13-3 18-8l-1 7h19l15-91h-19zm-25 74c-6 0-10-5-10-15 0-15 6-25 15-25 7 0 11 5 11 15 0 14-7 25-16 25z" />
			<path
				d="M233 264h-4c-11 0-17 4-17 11 0 5 3 8 7 8 8 0 14-8 14-19zm14 33h-16l1-7c-5 6-12 9-21 9-10 0-18-9-18-21 0-18 13-28 35-28h8v-4c0-5-3-7-12-7-10 0-18 2-21 3l3-17 23-3c17 0 26 7 26 21l-2 15-6 39zm262-88l-3 19c-7-3-12-5-18-5-14 0-25 15-25 35 0 13 7 22 19 22 4 0 10-2 16-5l-3 21-18 3c-20 0-34-16-34-40 0-32 18-55 44-55 9 0 19 4 22 5m31 55h-4c-11 0-17 4-17 11 0 5 3 8 7 8 8 0 14-8 14-19zm14 33h-16l1-7c-5 6-12 9-21 9-10 0-18-9-18-21 0-18 13-28 35-28h8v-4c0-5-3-7-12-7-10 0-18 2-21 3l3-17 23-3c17 0 26 7 26 21l-2 15-6 39zm-220-1l-14 3c-10 0-15-6-15-17-1-3 1-12 2-19l9-51h19l-2 11h10l-3 18h-10l-5 34c0 4 2 5 6 5h6l-3 16m59 0c-6 2-13 3-20 3-21 0-32-12-32-34 0-25 14-43 33-43 16 0 27 10 27 26l-3 19h-38c-2 10 5 15 16 15 7 0 14-2 21-5l-4 19zm-11-44c1-2 3-14-9-14-6 0-10 5-12 14h21zm-123-5c0 9 5 15 15 20 8 4 9 5 9 8 0 5-3 7-11 7l-18-3-2 17 20 3c21 0 30-8 30-25 0-10-4-16-14-21-8-4-9-4-9-8s3-6 10-6l14 1 2-17-17-1c-21 0-29 11-29 25m399 50h-19l1-7c-5 6-11 9-18 9-14 0-24-13-24-31 0-25 15-46 32-46 8 0 13 4 19 11l4-27h19l-14 91zm-29-17c9 0 15-10 15-25 0-9-3-14-10-14-9 0-15 10-15 25 0 9 3 14 10 14zm-57-57l-10 69-1 5h19c7-45 9-54 20-53l7-21c-8-2-12 3-18 11l1-11h-18m-160 0l-11 69-1 5h20c7-45 9-54 20-53 1-9 5-17 7-21-8-2-13 3-19 11 1-3 2-7 1-11h-17m254 68a6 6 0 1112 0 6 6 0 01-12 0zm6 5c2 0 4-2 4-5 0-2-2-4-4-4a4 4 0 000 9zm-1-2h-1v-5h3l1 1-1 2 1 2h-1l-1-2h-1v2zm0-3h1l1-1h-2v1z"
				fill="#fff"
			/>
		</svg>
	);
}
