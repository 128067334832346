import React, { useEffect } from 'react';
import { CircularProgress, Container, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import clone from 'just-clone';
import { useTranslation } from 'react-i18next';

import SongCard from '../../components/cards/SongCard';
import Header from '../../components/header/Header';
import styles from './styles.module.scss';
import H2 from '../../components/text/H2';
import ActionButtons from '../../components/button/ActionButtons';
import GeneralDialog from '../../components/dialog/GeneralDialog';
import VoidDialog from '../../components/dialog/VoidDialog';
import { useDispatch } from 'react-redux';
import { addTrackData } from '../../redux/reducers/trackReducer';
import { getFormatMMSS } from '../../utils/format-time';
import Input from '../../components/input/Input';
import UserCardsDialog from '../../components/dialog/UserCardsDialog';
import { useSelector } from 'react-redux';
import H4 from '../../components/text/H4';
import http from '../../http/http';
import SpotifyButton from '../../components/button/SpotifyButton';
import YoutubeButton from '../../components/button/YoutubeButton';
import PackCard from '../../components/cards/PackCard';

const Search = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { commerceData } = useSelector(state => state.commerce);
	const maxDurationRestriction = React.useState(
		commerceData.restrictions.find(
			restriction => restriction.restrictionName === 'max_duration',
		),
	);
	const blockExplicitContentRestriction = React.useState(
		commerceData.restrictions.find(
			restriction => restriction.restrictionName === 'block_explicit_content',
		),
	);
	const [size, setSize] = React.useState(window.innerWidth);
	const {
		packId,
		packPrice,
		packQty,
		isOpenSearch,
		commerceId,
		defaultSearch,
		blockExplicitContent,
	} = location.state;
	const [searchValue, setSearchValue] = React.useState('');
	const [showFreeConfirmationModal, setShowFreeConfirmationModal] = React.useState(false);
	const [showMissingTracksModal, setShowMissingTracksModal] = React.useState(false);
	const [showDefaultConfirmationModal, setShowDefaultConfirmationModal] = React.useState(false);
	const [showSelectedTracksModal, setShowSelectedTracksModal] = React.useState(false);
	const [showInvalidMaxDurationModal, setShowInvalidMaxDurationModal] = React.useState(false);
	const [showInvalidExplicitModal, setShowInvalidExplicitModal] = React.useState(false);
	const [showLimitTracksModal, setShowLimitTracksModal] = React.useState(false);
	const [emptyMessage, setEmptyMessage] = React.useState('');
	const [totalPages, setTotalPages] = React.useState(0);
	const [limitPerPage, setLimitPerPage] = React.useState(5);
	const [initialTracks, setInitialTracks] = React.useState([]);
	const [cancelStateTracks, setCancelStateTracks] = React.useState([]);
	const [tracks, setTracks] = React.useState([]);
	const [packs, setPacks] = React.useState([]);
	const [tracksRecomendations, setTracksRecomendations] = React.useState([]);
	const [showRecomendations, setShowRecomendations] = React.useState(false);
	const [selectedTracks, setSelectedTracks] = React.useState([]);
	const [isLoaderActive, setIsLoaderActive] = React.useState(true);
	const [isShowMoreLoaderActive, setIsShowMoreLoaderActive] = React.useState(false);
	const [isFilterActive, setIsFilterActive] = React.useState(false);
	const [hasNextPage, setHasNextPage] = React.useState(false);
	const [userCards, setUserCards] = React.useState([]);
	const [openChooseCardDialog, setOpenChooseCardDialog] = React.useState(false);
	const inputRef = React.useRef();

	const modalTitleStyle = {
		fontWeight: '600',
	};

	const modalSubtitleStyle = {
		fontWeight: '300',
		fontSize: '22px',
	};

	const buttonStyle = {
		fontSize: size < 429 ? '16' : '24px',
	};

	const checkInvalidMaxDuration = track => {
		let invalidMaxDuration = false;
		if (packPrice > 0) {
			return invalidMaxDuration;
		}
		if (track.item && track.item.ms) {
			invalidMaxDuration = maxDurationRestriction[0].value * 60000 < track.item.ms - 500;
		} else {
			invalidMaxDuration = maxDurationRestriction[0].value * 60000 < track.duration - 500;
		}

		return invalidMaxDuration;
	};

	const checkExplictContent = track => {
		if (packPrice > 0) {
			return false;
		}

		if (blockExplicitContent) {
			return track.item ? track.item.explicit : track.explicit;
		} else {
			return false;
		}
	};

	useEffect(() => {
		if (!location.state || location.state.isLinearPath !== true) {
			//para validar que el usuario está entrando a través del flujo de la aplicación
			navigate(`/home/${commerceId}`);
		}
	}, [location.state]);

	useEffect(() => {
		const search = defaultSearch ? defaultSearch : '';
		inputRef.current.value = search;
		setSearchValue(search);
	}, []);

	useEffect(() => {
		inputRef.current.value = searchValue;
		if (!isShowMoreLoaderActive) {
			setIsLoaderActive(true);
		} else {
			setIsShowMoreLoaderActive(true);
		}
		const delayDebounceFn = setTimeout(() => {
			// Realiza la petición aquí usando el término de búsqueda
			let paramsData =
				commerceData.currentProvider === 'Spotify'
					? {
							page: 1,
							limitPerPage,
							query: inputRef.current.value,
					  }
					: {
							query: inputRef.current.value,
							type: 'video',
					  };

			const params = new URLSearchParams(paramsData).toString();

			if (isOpenSearch) {
				if (commerceData.currentProvider === 'Spotify') {
					getTracksFromSpotify(params);
				} else {
					getTracksFromYoutube(params);
				}
			} else {
				getTracksfromData(params);
			}
		}, 500); // Espera 500 ms después de que el usuario deje de escribir

		return () => clearTimeout(delayDebounceFn);
	}, [searchValue, limitPerPage]);

	useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	useEffect(() => {
		if (selectedTracks.length === 0) {
			setShowSelectedTracksModal(false);
		}
	}, [selectedTracks]);

	useEffect(() => {
		getUserCards();
		getRecomendationsTracks();
	}, []);

	const getUserCards = async () => {
		const path = `customer/get-cards-tokens`;
		const haveRegisteredPhone = localStorage.getItem('phone');
		if (haveRegisteredPhone) {
			await http.instance.post(path, { phone: haveRegisteredPhone }).then(res => {
				setUserCards(res.data.result);
			});
		}
	};

	const getRecomendationsTracks = async () => {
		const path = `playlist/get-recomendations/${commerceId}`;

		await http.instance.get(path).then(res => {
			const trackData = res.data.result.data.map(track => {
				let selectedTrack;
				if (commerceData.currentProvider === 'Spotify') {
					selectedTrack = selectedTracks.find(
						tr => tr.spotifyId === track.item.spotifyId,
					);
				} else {
					selectedTrack = selectedTracks.find(
						tr => tr.youtubeId === track.item.youtubeId,
					);
				}

				let trackFormated = {};
				trackFormated = {
					...track.item,
					duration: track.item.ms,
					isSelected: selectedTrack ? true : false,
				};
				delete trackFormated.ms;
				delete trackFormated._id;

				return { ...trackFormated };
			});

			let uniqueArray;

			if (commerceData.currentProvider === 'Spotify') {
				uniqueArray = trackData.filter(
					(obj, index, self) =>
						index === self.findIndex(o => o.spotifyId === obj.spotifyId),
				);
			} else {
				uniqueArray = trackData.filter(
					(obj, index, self) =>
						index === self.findIndex(o => o.youtubeId === obj.youtubeId),
				);
			}

			setTracksRecomendations(uniqueArray);
		});
	};

	const getTracksFromSpotify = params => {
		const path = `spotify/search?`;
		if (inputRef.current.value.trim() === '' || !inputRef.current.value) {
			setTracks([]);
			setEmptyMessage(t('SEARCH_unselected_caption'));
			setShowRecomendations(true);
			setIsLoaderActive(false);
			setIsShowMoreLoaderActive(false);
			return;
		}

		http.instance
			.post(path + params, {
				commerceId: commerceId,
			})
			.then(res => {
				if (res.data.result.totalPages === 0 && res.data.result.tracks.length === 0) {
					setEmptyMessage(t('SEARCH_not_found_caption'));
				}
				setTotalPages(res.data.result.totalPages);
				const trackData = res.data.result.tracks.map(track => {
					const selectedTrack = selectedTracks.find(
						tr => tr.spotifyId === track.spotifyId,
					);
					return { ...track, isSelected: selectedTrack ? true : false };
				});
				setTracks(trackData);
				setHasNextPage(res.data.result.nextPage);
				setInitialTracks(trackData);
				setCancelStateTracks(trackData);
				setIsLoaderActive(false);
				setIsShowMoreLoaderActive(false);
				setShowRecomendations(false);
			})
			.catch(error => {
				setIsLoaderActive(false);
				setIsShowMoreLoaderActive(false);
				setShowRecomendations(false);
				console.log(error);
			});
	};

	const getTracksFromYoutube = (params, hasNextPage) => {
		const path = `youtube/search?`;
		if (inputRef.current.value.trim() === '' || !inputRef.current.value) {
			setTracks([]);
			setEmptyMessage(t('SEARCH_unselected_caption'));
			setShowRecomendations(true);
			setIsLoaderActive(false);
			setIsShowMoreLoaderActive(false);
			return;
		}

		http.instance
			.post(path + params, {
				commerceId: commerceId,
				...(hasNextPage && { nextPageToken: hasNextPage }),
			})
			.then(res => {
				if (res.data.result.totalPages === 0 && res.data.result.tracks.length === 0) {
					setEmptyMessage(t('SEARCH_not_found_caption'));
				}
				setTotalPages(res.data.result.totalPages);
				const trackData = res.data.result.tracks.map(track => {
					const selectedTrack =
						commerceData.currentProvider === 'Spotify'
							? selectedTracks.find(tr => tr.spotifyId === track.spotifyId)
							: selectedTracks.find(tr => tr.youtubeId === track.youtubeId);
					return { ...track, isSelected: selectedTrack ? true : false };
				});

				setTracks(prev => {
					if (hasNextPage !== undefined) {
						return [...prev, ...trackData];
					} else {
						return [...trackData];
					}
				});
				setHasNextPage(res.data.result.nextPage);
				setCancelStateTracks(trackData);
				setIsLoaderActive(false);
				setIsShowMoreLoaderActive(false);
				setShowRecomendations(false);
			})
			.catch(error => {
				setIsLoaderActive(false);
				setIsShowMoreLoaderActive(false);
				setShowRecomendations(false);
				console.log(error);
			});
	};

	const getTracksfromData = params => {
		const path = `pack/get-tracks/${packId}?`;
		http.instance
			.get(path + params)
			.then(res => {
				setTotalPages(res.data.result.totalPages);
				if (res.data.result?.tracks?.length > 0) {
					const trackData = res.data.result.tracks.map(track => {
						const selectedTrack = selectedTracks.find(
							tr => tr.item._id === track.item._id,
						);
						return { ...track, isSelected: selectedTrack ? true : false };
					});
					setTracks(trackData);
					setPacks([]);
					setInitialTracks(trackData);
					setCancelStateTracks(trackData);
				}

				if (res.data.result?.packs?.length >= 0) {
					setPacks(res.data.result?.packs);
					setTracks([]);
				}

				if (res.data.result?.tracks?.length === 0 && res.data.result?.packs?.length === 0) {
					setEmptyMessage(t('SEARCH_not_found_caption'));
				}

				setHasNextPage(res.data.result.nextPage);
				setIsLoaderActive(false);
				setIsShowMoreLoaderActive(false);
			})
			.catch(error => {
				setIsLoaderActive(false);
				setIsShowMoreLoaderActive(false);
				console.log(error);
			});
	};

	// const handlePaginationChange = (event, page) => {
	// 	setCurrentPage(page);
	// };

	const selectHandler = (songId, song, recomendation) => {
		if (checkInvalidMaxDuration(song)) {
			setShowInvalidMaxDurationModal(true);
			return;
		}

		if (checkExplictContent(song)) {
			setShowInvalidExplicitModal(true);
			return;
		}

		let tracksCopy = [];
		if (recomendation) {
			tracksCopy = clone(tracksRecomendations);
		} else {
			tracksCopy = clone(tracks);
		}
		const { trackData, trackIndex, selected } = getTrackData(tracksCopy, songId);

		if (selected && selectedTracks.length === packQty) {
			setShowLimitTracksModal(true);
			return;
		}

		if (recomendation) {
			setTracksRecomendations(trackData);
		} else {
			setTracks(trackData);
		}

		const selectedTracksCopy = clone(selectedTracks);

		if (isFilterActive) {
			const initialTracksCopy = clone(initialTracks);
			const { trackData } = getTrackData(initialTracksCopy, songId);
			setInitialTracks(trackData);
		} else {
			setInitialTracks(tracksCopy);
		}

		let updatedSelectedTracks = [];
		if (!selected) {
			let selectedTrackIndex;
			if (isOpenSearch) {
				selectedTrackIndex =
					commerceData.currentProvider === 'Spotify'
						? selectedTracksCopy.findIndex(track => track.spotifyId === songId)
						: selectedTracksCopy.findIndex(track => track.youtubeId === songId);
			} else {
				selectedTrackIndex = selectedTracksCopy.findIndex(
					track => track.item._id === songId,
				);
			}

			selectedTracksCopy.splice(selectedTrackIndex, 1);
			updatedSelectedTracks = [...selectedTracksCopy];
		} else {
			updatedSelectedTracks = [...selectedTracksCopy, tracksCopy[trackIndex]];
		}

		setSelectedTracks(updatedSelectedTracks);
	};

	const getTrackData = (trackData, songId) => {
		let trackIndex = [];
		if (isOpenSearch) {
			trackIndex =
				commerceData.currentProvider === 'Spotify'
					? trackData.findIndex(track => track.spotifyId === songId)
					: trackData.findIndex(track => track.youtubeId === songId);
		} else {
			trackIndex = trackData.findIndex(
				track => track.item._id === songId || track.item.youtubeId === songId,
			);
		}

		if (trackIndex < 0) {
			return;
		}
		const selected = !trackData[trackIndex].isSelected;
		trackData[trackIndex].isSelected = selected;

		return { trackData, trackIndex, selected };
	};

	const onDelete = songId => {
		const selectedTracksCopy = clone(selectedTracks);
		const recomendationsTracksCopy = clone(tracksRecomendations);
		const { tracksCopy: tracksUpdated } = deleteTrack(selectedTracksCopy, songId);
		const { tracksCopy: recomendationsUpdated } = deleteTrack(recomendationsTracksCopy, songId);
		setSelectedTracks(tracksUpdated);
		setTracksRecomendations(recomendationsUpdated);

		const trackData = getTrackData(tracks, songId)?.trackData;
		if (trackData) {
			setTracks(trackData);
		}
	};

	const deleteTrack = (tracks, songId) => {
		const tracksCopy = clone(tracks);
		let trackIndex;

		if (isOpenSearch) {
			trackIndex =
				commerceData.currentProvider === 'Spotify'
					? tracksCopy.findIndex(track => track.spotifyId === songId)
					: tracksCopy.findIndex(track => track.youtubeId === songId);
		} else {
			trackIndex = tracksCopy.findIndex(track => track.item._id === songId);
		}

		tracksCopy.splice(trackIndex, 1);

		return { tracksCopy };
	};

	const onSubmit = async mode => {
		const haveRecentPhone = localStorage.getItem('phone');
		setShowDefaultConfirmationModal(false);
		setShowFreeConfirmationModal(false);
		const mappedTracks = selectedTracks.flat().map(track => {
			return {
				...(commerceData.currentProvider === 'Spotify' && {
					spotifyId: track.item?.spotifyId ?? track.spotifyId,
				}),
				...(commerceData.currentProvider === 'Youtube' && {
					youtubeId: track.item?.youtubeId ?? track.youtubeId,
				}),
				name: track.item?.name ?? track.name,
				artist: track.item?.artist ?? track.artist,
				image: track.item?.image ?? track.image,
				uri: track.item?.uri ?? track.uri,
				ms: track.item?.ms ?? track.duration,
				explicit: track.item?.explicit ?? track.explicit,
				played: false,
			};
		});

		dispatch(addTrackData(mappedTracks));

		if (haveRecentPhone) {
			const path = `otp/verification/sms`;

			const code = await http.instance.post(path, { phone: haveRecentPhone });

			navigate(`/confirm-phone/${commerceId}`, {
				state: {
					isLinearPath: true,
					phone: localStorage.getItem('phone'),
					amount: mode === 'free' ? 0 : packPrice.toString(),
					packId: packId,
					mode,
					code: code.data.result,
					backState: location.state,
				},
			});
		} else {
			navigate(`/checkout/${commerceId}`, {
				state: {
					isLinearPath: true,
					amount: mode === 'free' ? 0 : packPrice.toString(),
					packId: packId,
					mode,
					backState: location.state,
				},
			});
		}
	};

	const handleNavigate = pack => {
		navigate('/search', {
			state: {
				isLinearPath: true, //para validar que el usuario está entrando a través del flujo de la aplicación
				packId: pack._id,
				packPrice: pack.price,
				packQty: pack.itemsQty,
				isOpenSearch: pack?.tracks?.length === 0 ?? true,
				commerceId,
				defaultSearch: inputRef.current.value,
			},
			replace: true,
		});
		window.location.reload();
	};

	const onCancel = () => {
		setTracks(cancelStateTracks);
		setSelectedTracks([]);
		dispatch(addTrackData([]));
	};

	const showMoreTracksHandler = () => {
		setIsShowMoreLoaderActive(true);
		if (commerceData.currentProvider === 'Youtube' && isOpenSearch) {
			const paramsData = {
				query: inputRef.current.value,
				type: 'video',
			};

			const params = new URLSearchParams(paramsData).toString();
			getTracksFromYoutube(params, hasNextPage);
		} else {
			setLimitPerPage(prev => prev + 5);
		}
	};

	const removeCardToken = async item => {
		try {
			const path = `customer/delete-card-token`;
			const res = await http.instance.put(path, {
				phone: localStorage.getItem('phone'),
				profileId: item.profileId,
			});

			if (res.data.result.cards.length === 0) {
				navigate(`/process-payment/${localStorage.getItem('commerceId')}`, {
					state: {
						isLinearPath: true,
						amount: location.state.amount
							? location.state.amount
							: packPrice.toString(),
						packId: location.state.packId,
						backState: location.state,
					},
				});
			} else {
				getUserCards();
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Container
			style={{
				marginTop: size < 429 ? 30 : 130,
				width: '100%',
				paddingBottom: 50,
			}}
		>
			<VoidDialog
				open={showSelectedTracksModal}
				crossButtonAction={() => setShowSelectedTracksModal(false)}
				handleClose={() => setShowSelectedTracksModal(false)}
				title={t('SEARCH_selected_body')}
			>
				<div style={{ width: '100%' }}>
					{selectedTracks.map((track, index) => (
						<SongCard
							key={index}
							songId={
								commerceData.currentProvider === 'Spotify'
									? track.item?._id ?? track.spotifyId
									: track.item?._id ?? track.youtubeId
							}
							image={track.item?.image ?? track.image}
							name={track.item?.name ?? track.name}
							artist={track.item?.artist ?? track.artist}
							duration={''}
							large={true}
							selectable={false}
							canDelete={true}
							onDeleteHandler={onDelete}
						/>
					))}
				</div>
			</VoidDialog>
			<GeneralDialog
				open={showFreeConfirmationModal}
				button1Text={t('BUTTONS_text_accept')}
				button2Text={t('BUTTONS_text2')}
				title={t('SEARCH_add_free_songs_dialog_title')}
				subtitle={t('SEARCH_add_free_songs_dialog_subtitle')}
				titleStyle={{ marginBottom: '25px', ...modalTitleStyle }}
				subtitleStyle={{ marginBottom: '20px', ...modalSubtitleStyle }}
				button1Style={buttonStyle}
				button2Style={buttonStyle}
				crossButtonAction={() => setShowFreeConfirmationModal(false)}
				button1Action={() => onSubmit('free')}
				button2Action={() => setShowFreeConfirmationModal(false)}
				dialogPaperStyle={{ width: '750px' }}
			/>
			<GeneralDialog
				open={showMissingTracksModal}
				button1Text={t('BUTTONS_text1')}
				button2Text={t('BUTTONS_text2')}
				title={t('SEARCH_add_minimun_songs_alert_title')}
				subtitle={t('SEARCH_add_minimun_songs_alert_subtitle')}
				titleStyle={modalTitleStyle}
				subtitleStyle={{
					marginBottom: '27px',
					lineHeight: '29.05px',
					...modalSubtitleStyle,
				}}
				button1Style={buttonStyle}
				button2Style={buttonStyle}
				crossButtonAction={() => setShowMissingTracksModal(false)}
				button1Action={() => setShowMissingTracksModal(false)}
				button2Action={() => setShowMissingTracksModal(false)}
			/>
			<GeneralDialog
				open={showDefaultConfirmationModal}
				button1Text={t('BUTTONS_text_accept')}
				button2Text={t('BUTTONS_text2')}
				title={t('SEARCH_add_songs_dialog_title')}
				subtitle={t('SEARCH_add_songs_dialog_subtitle')}
				titleStyle={{ marginTop: '10px', marginBottom: '30px', ...modalTitleStyle }}
				subtitleStyle={{ marginBottom: '20px', ...modalSubtitleStyle }}
				button1Style={buttonStyle}
				button2Style={buttonStyle}
				crossButtonAction={() => setShowDefaultConfirmationModal(false)}
				button1Action={() => onSubmit('buy')}
				button2Action={() => setShowDefaultConfirmationModal(false)}
			/>
			<GeneralDialog
				open={showInvalidMaxDurationModal}
				button1Text={t('BUTTONS_text_accept')}
				title={t('SEARCH_dialog_time_limit_exceed_title')}
				subtitle={t('SEARCH_dialog_time_limit_exceed_subtitle')}
				titleStyle={{ marginBottom: '25px', ...modalTitleStyle }}
				subtitleStyle={{ marginBottom: '20px', ...modalSubtitleStyle }}
				button1Style={buttonStyle}
				button2Style={buttonStyle}
				crossButtonAction={() => setShowInvalidMaxDurationModal(false)}
				button1Action={() => setShowInvalidMaxDurationModal(false)}
				handleClose={() => setShowInvalidMaxDurationModal(false)}
				dialogPaperStyle={{ width: '750px' }}
			/>

			<GeneralDialog
				open={showInvalidExplicitModal}
				button1Text={t('BUTTONS_text_accept')}
				title={t('SEARCH_dialog_explicit_content_title')}
				subtitle={t('SEARCH_dialog_explicit_content_subtitle')}
				titleStyle={{ marginBottom: '25px', ...modalTitleStyle }}
				subtitleStyle={{ marginBottom: '20px', ...modalSubtitleStyle }}
				button1Style={buttonStyle}
				button2Style={buttonStyle}
				crossButtonAction={() => setShowInvalidExplicitModal(false)}
				button1Action={() => setShowInvalidExplicitModal(false)}
				handleClose={() => setShowInvalidExplicitModal(false)}
				dialogPaperStyle={{ width: '750px' }}
			/>

			<GeneralDialog
				open={showLimitTracksModal}
				button1Text={t('BUTTONS_text_accept')}
				title={t('SEARCH_dialog_limit_tracks_title')}
				subtitle={t('SEARCH_dialog_limit_tracks_subtitle')}
				titleStyle={{ marginBottom: '25px', ...modalTitleStyle }}
				subtitleStyle={{ marginBottom: '20px', ...modalSubtitleStyle }}
				button1Style={buttonStyle}
				button2Style={buttonStyle}
				crossButtonAction={() => setShowLimitTracksModal(false)}
				button1Action={() => setShowLimitTracksModal(false)}
				handleClose={() => setShowLimitTracksModal(false)}
				dialogPaperStyle={{ width: '750px' }}
			/>

			<div style={{ paddingBottom: '100px' }}>
				<Header
					title={t('SEARCH_title')}
					onClick={() =>
						navigate(`/pack/${commerceId}`, { state: { isLinearPath: true } })
					}
					size={size}
				/>
				<div className={styles.searchInputContainer}>
					<Grid container>
						<Grid item xs={12}>
							<Input
								placeholder={t('SEARCH_input_placeholder')}
								inputStyle={{
									width: '100%',
									height: size < 429 ? '45px' : '',
									paddingTop: '20px',
									fontSize: size < 429 ? '18px' : '24px',
									borderRadius: 20,
								}}
								rightIcon={require('../../assets/Search.png')}
								rightIconStyle={
									size < 429 ? { width: '16px', height: '16px', top: 15 } : {}
								}
								onKeyDown={event => {
									setTimeout(() => {
										setLimitPerPage(5);
										setSearchValue(event.target.value);
									}, 1000);
								}}
								onChange={event => null}
								inputRef={inputRef}
							/>
						</Grid>
					</Grid>
				</div>

				{commerceData.currentProvider === 'Spotify' && <SpotifyButton />}
				{commerceData.currentProvider === 'Youtube' && <YoutubeButton />}

				{selectedTracks.length > 0 && (
					<div
						style={{
							marginTop: 23,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							paddingRight: 35,
						}}
					>
						<div onClick={() => setShowSelectedTracksModal(true)}>
							<H2
								text={`${t('SEARCH_selected_body')} ${selectedTracks.length}`}
								fontSize={size < 429 ? 15 : null}
							/>
						</div>
						<div style={{ cursor: 'pointer' }}>
							<img
								src={require('../../assets/PlaylistArrow.png')}
								onClick={() => setShowSelectedTracksModal(true)}
							/>
						</div>
					</div>
				)}

				<div className={styles.tracksListContainer}>
					{isLoaderActive ? (
						<div className={styles.loaderContainer}>
							<CircularProgress color="primary" size={60} style={{ marginTop: 5 }} />
						</div>
					) : (
						<div>
							{tracks && tracks.length > 0 && !showRecomendations ? (
								<>
									{tracks.map((track, index) => (
										<>
											<SongCard
												key={index}
												songId={
													commerceData.currentProvider === 'Spotify'
														? track.item?._id ?? track.spotifyId
														: track.item?._id ?? track.youtubeId
												}
												image={track.item?.image ?? track.image}
												name={track.item?.name ?? track.name}
												artist={track.item?.artist ?? track.artist}
												duration={
													getFormatMMSS(track.item?.ms) ??
													getFormatMMSS(track?.duration) ??
													'3:40'
												}
												large={true}
												isSelected={track.isSelected}
												isDisabled={
													checkInvalidMaxDuration(track) ||
													checkExplictContent(track)
												}
												onSelectHandler={() => {
													selectHandler(
														commerceData.currentProvider === 'Spotify'
															? track.item?._id ?? track.spotifyId
															: track.item?._id ??
																	track?.item?.youtubeId ??
																	track?.youtubeId,
														track,
														false,
													);
												}}
											/>
										</>
									))}
									{hasNextPage && (
										<div onClick={showMoreTracksHandler}>
											{isShowMoreLoaderActive ? (
												<div className={styles.loaderContainer}>
													<CircularProgress
														color="primary"
														size={30}
														style={{ marginTop: 5 }}
													/>
												</div>
											) : (
												<H4
													style={{ textAlign: 'center' }}
													color={'#FFFFFF'}
													text={'Show more'}
												></H4>
											)}
										</div>
									)}
								</>
							) : tracks?.length === 0 &&
							  packs.length > 0 &&
							  selectedTracks.length === 0 ? (
								<>
									<H2
										fontSize={size < 429 ? 24 : 25}
										text={
											'No encontramos la canción que buscas en este pack pero puedes encontrarla en:'
										}
										style={{
											textAlign: 'center',
											marginTop: 20,
											marginBottom: 20,
											fontWeight: 400,
										}}
									/>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										{packs.map((pack, index) => {
											let countSong = 0;
											pack.playlistsItems.forEach(playlist => {
												countSong += playlist.tracksCount;
											});
											return (
												<PackCard
													key={index}
													index={index}
													icon={require('../../assets/pack.png')}
													onClick={() => handleNavigate(pack)}
													name={pack.name}
													tracks={pack.itemsQty}
													price={pack.price}
													available={countSong}
													large={size < 750 ? true : false}
													size={size}
												/>
											);
										})}
									</div>
								</>
							) : (packs.length === 0 && !showRecomendations) ||
							  (packs.length > 0 && selectedTracks.length > 0) ? (
								<H2
									fontSize={size < 429 ? 24 : 25}
									text={t('SEARCH_not_found_caption')}
									style={{
										textAlign: 'center',
										marginTop: showRecomendations ? 0 : 163,
										fontWeight: 400,
									}}
								/>
							) : (
								<>
									<H2
										fontSize={size < 429 ? 24 : 25}
										text={emptyMessage}
										style={{
											textAlign: 'center',
											marginTop: showRecomendations ? 0 : 163,
											fontWeight: 400,
										}}
									/>
									{showRecomendations && (
										<>
											{tracksRecomendations.map((track, index) => (
												<>
													<SongCard
														key={index}
														songId={
															commerceData.currentProvider ===
															'Spotify'
																? track.item?._id ?? track.spotifyId
																: track.item?._id ?? track.youtubeId
														}
														image={track.item?.image ?? track.image}
														name={track.item?.name ?? track.name}
														artist={track.item?.artist ?? track.artist}
														duration={
															getFormatMMSS(track.item?.ms) ??
															getFormatMMSS(track?.duration) ??
															'3:40'
														}
														large={true}
														isSelected={track.isSelected}
														isDisabled={
															checkInvalidMaxDuration(track) ||
															checkExplictContent(track)
														}
														onSelectHandler={() =>
															selectHandler(
																commerceData.currentProvider ===
																	'Spotify'
																	? track.item?._id ??
																			track.spotifyId
																	: track.item?._id ??
																			track.youtubeId,
																track,
																true,
															)
														}
													/>
												</>
											))}
										</>
									)}
								</>
							)}
						</div>
					)}
				</div>
				{/* <div className={styles.navigationContainer}>
					{!isLoaderActive && tracks.length > 0 && (
						<Navigation
							totalPages={totalPages}
							currentPage={currentPage}
							handlePaginationChange={handlePaginationChange}
						/>
					)}
				</div> */}
				{selectedTracks.length > 0 && (
					<div className={styles.buttonsContainer}>
						<ActionButtons
							acceptText={`${selectedTracks.length} ${t(
								'TEXT_CONNECTOR1',
							)} ${packQty}`}
							cancelText={t('BUTTONS_text2')}
							onAccept={() => {
								if (selectedTracks.length < packQty) {
									setShowMissingTracksModal(true);
									return;
								}

								if (packPrice === 0) {
									setShowFreeConfirmationModal(true);
									return;
								}

								setShowDefaultConfirmationModal(true);
							}}
							onCancel={onCancel}
							width={size < 429 ? 170 : 300}
						/>
					</div>
				)}
			</div>
			<UserCardsDialog
				size={size}
				limit={600}
				open={openChooseCardDialog}
				crossButtonAction={() => setOpenChooseCardDialog(false)}
				cards={userCards}
				buttonAction={() =>
					navigate(`/process-payment/${commerceId}`, {
						state: {
							isLinearPath: true,
							amount: packPrice.toString(),
							packId: packId,
							backState: location.state,
						},
					})
				}
				cardAction={item => {
					let state;
					if (item?.isNotTokenized) {
						state = {
							isLinearPath: true,
							amount: packPrice.toString(),
							expiry: item.expiry,
							account: item.account,
							cvv2: item.cvv,
							packId: packId,
							cardType: item.type,
							address: item.address,
							postal: item.postalCode,
							name: item.holder,
							tokenizeCard: false,
							isNotTokenized: item?.isNotTokenized,
							profileId: item.profileId,
							backState: location.state,
						};
					} else {
						state = {
							isLinearPath: true,
							amount: packPrice.toString(),
							packId: packId,
							profileId: item.profileId,
							cardType: item.type,
							name: item.holder,
							account: `000000000000${item.mask}`,
							isNotTokenized: false,
							backState: location.state,
						};
					}
					navigate(`/verify-payment/${commerceId}`, {
						state,
					});
				}}
				cardRemoveButtonAction={item => {
					removeCardToken(item);
				}}
			/>
		</Container>
	);
};

export default Search;
