import { createSlice } from '@reduxjs/toolkit';

const commerceSlice = createSlice({
	name: 'commerce',
	initialState: {
		commerceData: {},
	},
	reducers: {
		addCommerceData: (state, action) => ({
			...state,
			commerceData: { ...action.payload },
		}),
	},
});

export const { addCommerceData } = commerceSlice.actions;

export default commerceSlice.reducer;
