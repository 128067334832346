import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Playlist from '../pages/playlist/Playlist';
import Home from '../pages/home/Home';
import Finish from '../pages/finish/Finish';
import ClosedPlaylist from '../pages/closed-playlist/ClosedPlaylist';
import BusinessModel from '../pages/business-model/BusinessModel';
import BusinessType from '../pages/business-model/BusinessType';
import Landing from '../pages/landing/Landing';
import PrivateRoute from '../routes/privateRoute';
import Checkout from '../pages/checkout/Checkout';
import ResetPassword from '../pages/reset-password/ResetPassword';
import Pack from '../pages/pack/Pack';
import Search from '../pages/search/Search';
import ConfirmPhone from '../pages/checkout/ConfirmPhone';
import ProcessPayment from '../pages/checkout/ProcessPayment';
import VerifyPayment from '../pages/checkout/VerifyPayment';
import NotConnectionPage from '../pages/issues/NotConnectionPage';
import OutOfRangePage from '../pages/issues/OutOfRangePage';
import SelectMode from '../pages/select-mode/SelectMode';
import AISearch from '../pages/AI-search/AISearch';
import CheckoutEmail from '../pages/checkout/CheckoutEmail';

export default function MainRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Landing />} />
			<Route path="/home/:commerceId" element={<Home />} />
			<Route path="/mode/:commerceId" element={<SelectMode />} />
			<Route path="/AI/:commerceId" element={<AISearch />} />
			<Route path="/pack/:commerceId" element={<Pack />} />
			<Route path="/search" element={<Search />} />
			<Route
				path="/playlist/:commerceId"
				element={
					<PrivateRoute>
						<Playlist></Playlist>
					</PrivateRoute>
				}
			/>

			<Route
				path="/business-model/:commerceId"
				element={
					<PrivateRoute>
						<BusinessModel></BusinessModel>
					</PrivateRoute>
				}
			/>

			<Route
				path="playlist/:commerceId"
				element={
					<PrivateRoute>
						<Playlist></Playlist>
					</PrivateRoute>
				}
			/>

			<Route
				path="closed-playlist/:commerceId/:playlistId"
				element={
					<PrivateRoute>
						<ClosedPlaylist></ClosedPlaylist>
					</PrivateRoute>
				}
			/>

			<Route path="checkout/:commerceId" element={<Checkout />} />
			<Route path="confirm-phone/:commerceId" element={<ConfirmPhone />} />
			<Route path="checkout-email/:commerceId" element={<CheckoutEmail />} />
			<Route path="process-payment/:commerceId" element={<ProcessPayment />} />
			<Route path="verify-payment/:commerceId" element={<VerifyPayment />} />

			<Route path="/business-type">
				<Route
					path=":commerceId"
					element={
						<PrivateRoute>
							<BusinessType></BusinessType>
						</PrivateRoute>
					}
				/>
				<Route
					path=":commerceId/:playlistId"
					element={
						<PrivateRoute>
							<BusinessType></BusinessType>
						</PrivateRoute>
					}
				/>
			</Route>

			<Route path="/closed-playlist">
				<Route
					path=":commerceId"
					element={
						<PrivateRoute>
							<ClosedPlaylist></ClosedPlaylist>
						</PrivateRoute>
					}
				/>
				<Route
					path=":commerceId/:playlistId"
					element={
						<PrivateRoute>
							<ClosedPlaylist></ClosedPlaylist>
						</PrivateRoute>
					}
				/>
			</Route>

			<Route path="/finish" element={<Finish></Finish>} />
			<Route path="/reset-password/:token" element={<ResetPassword></ResetPassword>} />
			<Route path="/not_connection" element={<NotConnectionPage />} />
			<Route path="/out_of_range" element={<OutOfRangePage />} />
			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	);
}
