import http from '../../http/http';
import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import H1 from '../../components/text/H1';
import PackCard from '../../components/cards/PackCard';
import Button from '../../components/button/Button';
import Header from '../../components/header/Header';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebaseInit';
import { checkInvalidParam } from '../../utils/check-invalid-param';
import H2 from '../../components/text/H2';

const Pack = () => {
	const { t } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [size, setSize] = React.useState(window.innerWidth);
	const [packs, setPacks] = React.useState([]);
	const [packConfig, setPackConfig] = React.useState({});
	const [loadingPackConfig, setLoadingPackConfig] = React.useState(true);
	const [loadingPacks, setLoadingPacks] = React.useState(true);

	const checkError = error => {
		checkInvalidParam(error);
	};

	useEffect(() => {
		if (!location.state || location.state.isLinearPath !== true) {
			//para validar que el usuario está entrando a través del flujo de la aplicación
			navigate(`/home/${params.commerceId}`);
		}
	}, [location.state]);

	useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	const getPackConfig = () => {
		setLoadingPackConfig(true);
		const path = `pack/config`;
		http.instance
			.get(path)
			.then(res => {
				setPackConfig(res.data.result);
				getPacks(res.data.result);
				setLoadingPackConfig(false);
			})
			.catch(error => {
				checkError(error);
				setLoadingPackConfig(false);
			});
	};

	const getPacks = packConfig => {
		setLoadingPacks(true);
		const path = `pack/${params.commerceId}`;
		http.instance
			.get(path)
			.then(res => {
				if (packConfig.status) {
					let packs = [];
					res.data.result.map(pack => {
						packs.push({
							...pack,
							price: pack.price !== 0 ? packConfig.price : pack.price,
							itemsQty: pack.price !== 0 ? packConfig.itemsQty : pack.itemsQty,
						});
					});
					setPacks(packs);
				} else {
					setPacks(res.data.result);
				}
				setLoadingPacks(false);
			})
			.catch(error => {
				checkError(error);
				setLoadingPacks(false);
			});
	};

	useEffect(() => {
		getPackConfig();
	}, [params.commerceId]);

	const handleNavigate = pack => {
		logEvent(analytics, 'cloudJukebox_web_selected_pack', {
			commerceId: params.commerceId,
			packId: pack._id,
			packPrice: pack.price,
			packQty: pack.itemsQty,
			isOpenSearch: pack?.tracks ? pack?.tracks?.length === 0 : true,
			tenantId: process.env.REACT_APP_TENANT_ID,
		});

		navigate('/search', {
			state: {
				isLinearPath: true, //para validar que el usuario está entrando a través del flujo de la aplicación
				packId: pack._id,
				packPrice: pack.price,
				packQty: pack.itemsQty,
				isOpenSearch: pack?.tracks ? pack?.tracks?.length === 0 : true,
				commerceId: params.commerceId,
				blockExplicitContent: pack.blockExplicitContent,
			},
		});
	};

	return (
		<div style={{ marginTop: size < 750 ? 30 : 100, paddingRight: 34, paddingLeft: 34 }}>
			{size < 750 ? (
				<Header
					title="Swaggin"
					onClick={() => navigate(`/home/${params.commerceId}`)}
					size={size}
					limit={750}
				/>
			) : null}
			<H1
				text={t('PACK_title')}
				style={{
					textAlign: size < 750 ? 'start' : 'center',
					marginTop: size < 750 ? 62 : '',
				}}
				fontSize={size < 750 ? 18 : null}
				fontWeight={size < 750 ? 400 : null}
			/>

			{!loadingPackConfig && !loadingPacks ? (
				<>
					<Box marginX="auto">
						<div
							style={{ marginTop: size < 750 ? 47 : 70, flexWrap: 'wrap' }}
							className={size < 750 ? undefined : styles.packs}
						>
							{packs.length > 0 ? (
								packs.map((pack, index) => {
									return (
										<PackCard
											key={index}
											index={index}
											icon={require('../../assets/pack.png')}
											onClick={() => handleNavigate(pack)}
											name={pack.name}
											tracks={pack.itemsQty}
											price={pack.price}
											available={pack?.tracks ? pack?.tracks?.length : 0}
											large={size < 750 ? true : false}
											size={size}
										/>
									);
								})
							) : (
								<H2
									text={t('EMPTY_STATE_pack')}
									fontSize={size < 750 ? 18 : null}
									fontWeight={size < 750 ? 400 : null}
								/>
							)}
						</div>
					</Box>
					{size < 750 ? null : (
						<div className="d-flex justify-content-center">
							<Button
								width={size < 428 ? 353 : 590}
								buttonText={t('PACK_button')}
								onClick={() => navigate(`/mode/${params.commerceId}`)}
								buttonStyle={{
									marginTop: size < 428 ? 51 : 79,
									alignSelf: 'center',
								}}
							/>
						</div>
					)}
				</>
			) : (
				<div
					style={{
						marginTop: 50,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						verticalAlign: 'center',
					}}
				>
					<CircularProgress color="primary" size={30} style={{ marginTop: 5 }} />
				</div>
			)}
		</div>
	);
};

export default Pack;
