import React from 'react';
import Grid from '@mui/material/Grid';

import Caption from '../text/Caption';
import H3 from '../text/H3';
import H5 from '../text/H5';
import H6 from '../text/H6';
import { ReactComponent as TrashIcon } from '../../assets/svg/trash.svg';
import { ReactComponent as CurrentTrack } from '../../assets/svg/Current-track.svg';

const SongCard = ({
	image,
	name,
	artist,
	duration,
	description,
	large,
	index,
	isSelected,
	onSelectHandler,
	songId,
	selectable = true,
	canDelete = false,
	onDeleteHandler,
	isDisabled = false,
	isCurrentTrack = false,
}) => {
	const [size, setSize] = React.useState(window.innerWidth);
	const selectedCardStyles = {
		backgroundColor: 'rgba(35, 55, 78, 1)',
	};
	const disabledCardStyles = {
		backgroundColor: 'rgba(30, 42, 55, 1)',
	};

	const defaultCardStyles = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 14,
		paddingLeft: '10px',
		paddingRight: '10px',
	};

	const selectHandler = songId => {
		onSelectHandler(songId);
	};

	React.useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	const getBackgroundColor = (isSelected, isDisabled, isCurrentTrack) => {
		if (isDisabled) {
			return disabledCardStyles.backgroundColor;
		}

		if (isSelected || isCurrentTrack) {
			return selectedCardStyles.backgroundColor;
		}

		return '';
	};

	if (large) {
		return (
			<div
				key={index}
				style={{
					...defaultCardStyles,
					cursor: 'pointer',
					borderRadius: 20,
					backgroundColor: getBackgroundColor(isSelected, isDisabled, isCurrentTrack),
				}}
				onClick={() => (selectable ? selectHandler(songId) : null)}
			>
				<Grid
					container
					style={{
						paddingTop: 8,
						paddingBottom: 8,
						paddingLeft: 0,
						alignItems: 'center',
					}}
				>
					<Grid
						item
						xs={3}
						sm={2}
						md={1}
						style={{
							display: 'flex',
							justifyContent: 'center',
							position: 'relative',
							alignItems: 'center',
						}}
					>
						{image && (
							<img
								src={image}
								alt={name}
								style={{
									width: 50,
									height: 50,
								}}
							/>
						)}

						{isDisabled && (
							<div
								style={{
									backgroundColor: isDisabled ? 'rgba(30, 42, 55, 0.8)' : '',
									position: 'absolute',
									width: 50,
									height: 50,
								}}
							></div>
						)}
					</Grid>
					<Grid item xs={6} sm={8} md={9} style={{ paddingLeft: 10 }}>
						<div
							style={{
								marginBottom: 0,
							}}
						>
							<H3
								text={name}
								fontSize={size < 429 ? 14 : null}
								style={{
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									opacity: isDisabled ? 0.5 : 1,
								}}
							/>
						</div>
						<H5
							text={artist}
							fontSize={size < 429 ? 12 : null}
							style={{
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								opacity: isDisabled ? 0.5 : 1,
							}}
						/>
						{description && (
							<H5
								text={description}
								fontSize={size < 429 ? 12 : null}
								fontWeight={500}
								style={{
									paddingRight: 15,
								}}
							/>
						)}
					</Grid>
					<Grid item xs={3} sm={2} style={{ paddingLeft: '10px' }}>
						<Grid
							container
							alignItems="center"
							justifyContent="space-evenly"
							columnSpacing={2}
							rowSpacing={2}
						>
							{isCurrentTrack && (
								<div
									style={{
										marginTop: 15,
										width: 50,
										height: 50,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<CurrentTrack />
								</div>
							)}
							{/* <Grid item xs>
								<H5
									text={duration}
									fontWeight={400}
									fontSize={size < 429 ? 10 : null}
									style={{ whiteSpace: 'nowrap' }}
								/>
							</Grid> */}

							{selectable ? (
								<Grid
									item
									xs
									style={{
										display: 'flex',
										justifyContent: 'right',
										paddingRight: 15,
									}}
								>
									<img
										src={require(isSelected
											? '../../assets/Check.png'
											: isDisabled
											? '../../assets/Circle-disabled.png'
											: '../../assets/Circle.png')}
									/>
								</Grid>
							) : null}
						</Grid>

						{canDelete && (
							<TrashIcon
								style={{ marginLeft: '24px' }}
								onClick={() => onDeleteHandler(songId)}
							/>
						)}
					</Grid>
				</Grid>
			</div>
		);
	} else {
		return (
			<Grid container key={name} maxWidth={115}>
				<Grid item xs={12}>
					<img
						src={image}
						alt={name}
						style={{
							width: 85,
							height: 85,
							marginLeft: 30,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<div style={{ marginLeft: 30, marginBottom: 0, overflow: 'hidden' }}>
						<H6
							text={name}
							style={{
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								opacity: isDisabled ? 0.5 : 1,
							}}
						/>
					</div>
					<div style={{ marginLeft: 30, overflow: 'hidden' }}>
						<Caption
							text={artist}
							style={{
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								opacity: isDisabled ? 0.5 : 1,
							}}
						/>
					</div>
				</Grid>
			</Grid>
		);
	}
};

export default SongCard;
