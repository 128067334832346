import React from 'react';

export default function Maestro({ width }) {
	return (
		<svg width={width} viewBox="0 0 780 500" xmlns="http://www.w3.org/2000/svg">
			<path d="M690 250a179 179 0 11-359 0 179 179 0 01359 0" fill="#D9222A" />
			<path
				d="M372 364l-14-19h64l10-19h-84l-8-19h100a179 179 0 005-95H335l5-19h100l-8-19h-84l10-19h64l-14-19h-36c5-7 12-13 18-19a179 179 0 1018 247h-36"
				fill="#0097D0"
			/>
			<path d="M666 350a6 6 0 1112 0 6 6 0 01-12 0zm6 4c2 0 4-2 4-4s-2-4-4-4c-3 0-5 2-5 4s2 4 5 4zm-1-1h-1v-6h2l1 1 1 1-1 1 1 3h-1l-1-2h-1v2zm0-3h1l1-1h-1v-1h-1v2zm-284-51c-7 2-15 3-22 3-26-1-39-12-39-34 0-25 17-44 40-44 18 0 30 11 30 27l-3 18h-44c-2 11 6 16 19 16 8 0 15-2 23-5l-4 19zm-12-45c0-1 3-13-10-13-7 0-12 5-14 13h24zm28-5c0 10 5 16 17 21 9 4 10 5 10 8 0 5-4 7-13 7l-20-3-3 17c6 2 15 2 24 3 24 0 35-8 35-25 0-11-5-17-16-21-10-4-11-5-11-8 0-4 4-6 11-6l17 1 3-17-20-2c-26 0-35 12-34 25m-89 51h-18v-8c-6 7-13 10-24 10-12 0-20-9-20-21 0-18 14-28 39-28h10v-5c0-5-3-7-14-7-10 0-17 2-24 4l3-17c12-3 19-4 27-4 20 0 30 8 30 22l-2 15-7 39zm-16-33h-5c-13 0-19 4-19 11 0 5 3 8 8 8 9 0 16-8 16-19zm194 32l-16 3c-11 0-18-6-18-17l3-19 11-58h22l-3 18h11l-3 18h-11l-7 34c0 4 3 5 8 5h6l-3 16m99-75c-16 0-29 7-36 18l6-17c-12-4-19 2-26 11l-2 3v-13h-21l-12 69-1 5h23l5-28c5-21 13-28 25-25-3 6-4 13-4 21 0 18 10 34 35 34s43-14 43-45c0-18-12-33-35-33zm-6 59c-8 1-13-6-13-16 0-12 7-25 18-25 9 0 12 7 12 15 0 17-6 26-17 26zm-343 17h-23l13-70-30 70h-20l-4-69-14 69h-20l17-91h35l3 51 22-51h38l-17 91" />
			<path
				d="M628 289a6 6 0 0112 0 6 6 0 11-12 0zm6 4a4 4 0 100-9 4 4 0 000 9zm-1-2h-1v-5h2l1 1 1 1-1 1 1 2h-1l-1-2h-1v2zm0-2h1l1-1v-1h-2v2zm-240 4c-8 2-15 3-23 3-25 0-38-12-38-33 0-26 17-44 39-44 19 0 30 10 30 26 0 6 0 11-2 19h-45c-1 10 6 15 20 15 7 0 15-1 23-5l-4 19zm-12-44c0-2 3-13-10-14-7 0-13 5-15 14h25zm27-5c0 9 5 16 17 20 10 4 11 5 11 9s-4 7-13 6c-7 0-13 0-20-2l-3 17 23 2c24 0 35-8 35-25 0-10-4-16-16-21-9-3-10-4-10-8s3-6 11-6l16 1 3-17-20-1c-25 0-34 11-34 25m-88 51h-19l1-8c-6 6-14 9-24 9-12 0-20-8-20-20 0-19 14-29 39-29h9l1-4c0-5-4-7-14-7-11 0-18 1-24 3l3-17 27-3c19 0 29 7 29 21 1 4-1 12-1 15-1 5-7 33-7 40zm-17-34h-5c-12 0-19 4-19 11 0 5 3 8 8 8 10 0 16-8 16-19zm195 32l-16 3c-12 0-18-6-18-16l3-20 10-58h23l-4 18h12l-3 18h-12l-6 34c0 4 2 6 8 6l6-1-3 16m110-42c0 17-7 27-18 27-8 0-12-7-12-17 0-11 7-25 18-25 9 0 12 7 12 15zm24 1c0-19-12-33-35-33-27 0-44 17-44 43 0 19 10 34 35 34 26 0 44-14 44-44zm-115-32c-2 23-7 47-11 70l-1 5h22c8-45 11-58 28-53l8-21c-12-5-19 2-26 10 0-3 2-7 1-11h-21m-270 75h-22l13-70-31 70h-20l-4-70-13 70h-20l17-91h35l2 56 24-56h37l-18 91"
				fill="#fff"
			/>
		</svg>
	);
}
