import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import 'react-phone-input-2/lib/style.css';
import haversine from 'haversine';
import { AES } from 'crypto-js';
import { useDispatch, useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';

import styles from './styles.module.scss';
import 'react-phone-number-input/style.css';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebaseInit';
import { isAppleDevice } from '../../utils/is-apple-device';
import 'react-phone-number-input/style.css';
import Button from '../../components/button/Button';
import 'react-phone-input-2/lib/style.css';
import { eventBus } from '../../utils/event-bus';
import H2 from '../../components/text/H2';
import Divider from '../../components/divider/Divider';
import SongCard from '../../components/cards/SongCard';
import H4 from '../../components/text/H4';
import H1 from '../../components/text/H1';
import H3 from '../../components/text/H3';
import H6 from '../../components/text/H6';
import { colors } from '../../utils/colors';
import { ReactComponent as LogoSwaggin } from '../../assets/svg/LogoSwaggin.svg';
import Snackbar from '../../components/snackbar/Snackbar';
import { ReactComponent as TrashIcon } from '../../assets/Trash_Icon.svg';
import GeneralDialog from '../../components/dialog/GeneralDialog';
import { getFormatMMSS } from '../../utils/format-time';
import { addCommerceData } from '../../redux/reducers/commerceReducer';
import SplashDialog from '../../components/dialog/SplashDialog';
import TheFooter from '../../components/footer/TheFooter';
import CloseBusiness from '../issues/CloseBusiness';
import OutOfRangePage from '../issues/OutOfRangePage';
import OffBusiness from '../issues/OffBusiness';
import http from '../../http/http';
import Rate from './Rate';
import { checkInvalidParam } from '../../utils/check-invalid-param';
import SpotifyButton from '../../components/button/SpotifyButton';
import YoutubeButton from '../../components/button/YoutubeButton';

export default function Home() {
	window.history.pushState(null, null, window.location.pathname);
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [size, setSize] = useState(window.innerWidth);
	const [phone] = useState('');
	const [email] = useState('');
	const [commerce, setCommerce] = useState({});
	const [commerceId, setCommerceId] = useState(params.commerceId);
	const [serviceDetails, setServiceDetails] = useState();
	const [loading, setLoading] = useState(false);
	const [, setServiceStatus] = useState(false);
	const [, setServiceStatusMsg] = useState('');
	const [currentTrack, setCurrentTrack] = useState({});
	const [codeVerification] = useState('');
	const [currentPlaylist, setCurrentPlaylist] = useState([]);
	const [currentPlaylistStaging, setCurrentPlaylistStaging] = useState([]);
	const [lastPlaylistTracks, setLastPlaylistTrack] = useState([]);
	const [openSnack, setOpenSnack] = useState(false);
	const [openDeleteNumber, setOpenDeleteNumber] = useState(false);
	const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
	const [showDisabledModal, setShowDisabledModal] = useState(false);
	const [estimatedTime, setEstimatedTime] = useState('');
	const [isBusinessClosed, setIsBusinessClosed] = useState(false);
	const [isBusinessAboutToClose, setIsBusinessAboutToClose] = useState(false);
	const [showIsAboutToCloseModal, setShowIsAboutToCloseModal] = useState(false);
	const [isOutRange, setIsOutRange] = useState(false);
	const [isBusinessInactive, setIsBusinessInactive] = useState(false);
	const [isJukeboxInactive, setIsJukeboxInactive] = useState(false);
	const [progressBar, setProgressBar] = useState(0);
	const [isDelayed, setIsDelayed] = useState(true);
	const [openRate, setOpenRate] = useState(false);
	const moment = require('moment');

	const checkError = error => {
		checkInvalidParam(error);
	};

	const checkLastAccess = (phone, commerceId) => {
		const path = `customer/check-last-access`;
		const body = {
			phone,
			commerceId,
		};

		return http.instance.post(path, body);
	};

	const getEstimatedTime = async () => {
		const path = `playlist/estimated-time`;

		const body = {
			commerceId,
		};

		http.instance
			.post(path, body)
			.then(res => {
				if (res.data.result && res.data.result !== null && res.data.result !== '') {
					setEstimatedTime(res.data.result);
				}
			})
			.catch(error => {
				checkError(error);
			});
	};

	const getCommerce = useCallback(() => {
		setLoading(true);
		const path = `commerce/${commerceId}`;
		http.instance
			.get(path)
			.then(res => {
				setCommerce(res.data.result);
				setServiceDetails(res.data.result.serviceDetails);
				dispatch(addCommerceData(res.data.result));
				if (res.data.result.serviceDetails.isJukeboxActive) {
					setIsJukeboxInactive(false);
				} else {
					setIsJukeboxInactive(true);
				}
			})
			.catch(error => {
				checkError(error);
			});
	}, [commerceId]);

	const getCurrentTrack = useCallback(() => {
		const path = `spotify/current-track`;
		http.instance
			.post(path, {
				commerceId: params.commerceId,
			})
			.then(res => {
				if (
					typeof res.data.body === 'object' &&
					Object.entries(res.data.body).length > 0 &&
					res.data.body.item
				) {
					setCurrentTrack(res.data.body.item);
					setProgressBar(res.data.body.progress_ms);
				} else {
					sendPushNotification(params.commerceId);
					setIsBusinessInactive(true);
					return;
				}
			})
			.catch(error => {
				checkError(error);
			});
	}, [params.commerceId]);

	const checkDevices = devices => {
		if (devices.length === 0) {
			setServiceStatus(false);
			setServiceStatusMsg('Commerce spotify account is not active');
		} else {
			if (!devices[0].is_active) {
				setServiceStatus(false);
				setServiceStatusMsg('Commerce spotify account is not active');
			} else {
				setServiceStatus(true);
			}
		}
	};

	const sendPushNotification = useCallback(async commerceId => {
		const path = `messenger/spotify-disabled`;
		try {
			await http.instance.post(path, {
				commerceId: commerceId,
				title: t('NOTIFICATION_title'),
				subtitle: t('NOTIFICATION_subtitle'),
			});
		} catch (error) {
			checkError(error);
		}
	}, []);

	const sendAboutToClosePushNotification = useCallback(async commerceId => {
		const path = `tasks/schedule-to-close-push/${commerceId}`;
		try {
			const resp = await http.instance.get(path);
			console.log('resp', resp);
		} catch (error) {
			console.log(error);
		}
	}, []);

	const getAvailableDevices = useCallback(async () => {
		const path = `spotify/get-available-devices`;
		const pathPush = `messenger/spotify-disabled`;

		try {
			const res = await http.instance.post(path, { commerceId: commerceId });
			checkDevices(res.data.result.devices);
		} catch (error) {
			if (error.response) {
				sendPushNotification(commerceId);
				setIsBusinessInactive(true);
				checkError(error);
				return;
			}
		}
	}, [commerceId]);

	const checkSession = () => {
		if (localStorage.getItem('phone') !== undefined && localStorage.getItem('commerceId')) {
			const commerceId = localStorage.getItem('commerceId');

			if (commerceId !== params.commerceId) {
				localStorage.clear();
				return;
			}
		}
		return false;
	};

	const getCurrentPlaylist = useCallback(() => {
		const path = `playlist/current/${commerceId}`;
		http.instance
			.get(path)
			.then(res => {
				if (res.data.result.tracks !== undefined) {
					if (commerce.currentProvider === 'Spotify') {
						setCurrentPlaylistStaging(res.data.result.tracks);
					} else {
						setCurrentPlaylist(res.data.result.tracks);
						setCurrentTrack(res.data.result.tracks[0].item);
					}
					setLastPlaylistTrack(res.data.result.tracks.slice(-5));
				}
			})
			.catch(error => {
				checkError(error);
			});
	}, [commerceId]);

	useEffect(() => {
		if (commerce.currentProvider === 'Spotify') {
			if (Object.keys(currentTrack).length > 0 && currentPlaylistStaging.length > 0) {
				const indexCurrent = currentPlaylistStaging.findIndex(
					data => data.item.spotifyId === currentTrack.id,
				);
				const currentPlaylistUpdated = currentPlaylistStaging.filter((_data, index) => {
					if (index >= indexCurrent) {
						return true;
					} else {
						return false;
					}
				});

				if (
					!(currentPlaylistUpdated.length === currentPlaylistStaging.length) &&
					!(currentPlaylistUpdated[0].item.spotifyId !== currentTrack.id)
				) {
					setCurrentPlaylist(currentPlaylistUpdated);
				} else {
					setCurrentPlaylist(currentPlaylistStaging);
				}
			}
		}
	}, [currentTrack]);

	useEffect(() => {
		if (commerceId) {
			getEstimatedTime();

			eventBus.on('refresh-footer', () => {
				getCurrentTrack();
				getEstimatedTime();
			});
		}
	}, [commerceId]);

	useEffect(() => {
		checkSession();
		if (localStorage.getItem('phone')) {
			checkLastAccess(localStorage.getItem('phone'), params.commerceId)
				.then(() => {
					setIsAddButtonDisabled(false);
					setShowDisabledModal(false);
				})
				.catch(error => {
					if (error.response.data.code === 'restriction/limit-time') {
						setIsAddButtonDisabled(true);
						setShowDisabledModal(true);
						return;
					}
					checkError(error);
				});
		}
	}, [localStorage.getItem('phone'), localStorage.getItem('commerceId'), params.commerceId]);

	useEffect(() => {
		if (codeVerification !== '') {
			const path = `messenger/email/code`;
			const body = {
				email: email,
				code: AES.encrypt(codeVerification, process.env.REACT_APP_KEY).toString(),
			};
			http.instance
				.post(path, body)
				.then(res => {})
				.catch(error => {});

			const path2 = `messenger/sms/code`;
			const body2 = {
				phone: phone,
				code: AES.encrypt(codeVerification, process.env.REACT_APP_KEY).toString(),
			};
			http.instance
				.post(path2, body2)
				.then(res => {})
				.catch(error => {});
		}
	}, [codeVerification, email, phone]);

	useEffect(() => {
		if (commerce && Object.entries(commerce).length > 0) {
			// Check radius restrictions
			const radius = commerce.restrictions.find(
				restriction => restriction.restrictionName === 'radius',
			);

			if (isAppleDevice()) {
				navigator.geolocation.getCurrentPosition(
					position => {},
					error => {
						setLoading(false);
						setServiceStatusMsg('Geolocation permission state is denied');
						setServiceStatus(false);
					},
				);
			} else {
				if (!radius.streamMode) {
					navigator.permissions
						.query({ name: 'geolocation' })
						.then(function (permissionStatus) {
							if (permissionStatus.state === 'denied') {
								setLoading(false);
								setServiceStatusMsg('Geolocation permission state is denied');
								setServiceStatus(false);
								setIsOutRange(true);
								return;
							}
							permissionStatus.onchange = function () {
								if (this.state === 'denied') {
									setLoading(false);
									setServiceStatusMsg('Geolocation permission state is denied');
									setServiceStatus(false);
									setIsOutRange(true);
									return;
								}
							};
						});
				}
			}

			// Check Spotify devices
			if (commerce.currentProvider === 'Spotify') {
				getAvailableDevices();
				setTimeout(() => {
					getCurrentTrack();
				}, 500);
			}
		}
	}, [params.commerceId, commerce]);

	useEffect(() => {
		if (commerce && Object.entries(commerce).length > 0) {
			const radius = commerce.restrictions.find(
				restriction => restriction.restrictionName === 'radius',
			);

			navigator.geolocation.getCurrentPosition(
				position => {
					let distance = haversine(
						{
							latitude: position.coords.latitude,
							longitude: position.coords.longitude,
						},
						// {
						// 	latitude: 10.4790467,
						// 	longitude: -66.9613033,
						// },
						{
							latitude: commerce.location.latitude,
							longitude: commerce.location.longitude,
						},
						{ unit: 'meter' },
					);

					let radiusCondition;

					if (!radius.streamMode) {
						if (isAppleDevice()) {
							radiusCondition = distance > radius.value;
						} else {
							radiusCondition = navigator.userAgentData.mobile
								? distance > radius.value
								: distance - radius.value - 3000 > radius.value;
						}
					} else {
						radiusCondition = false;
					}

					if (radiusCondition) {
						//solución temporal donde se valida que mientras la precisión sea menor a 100 y la distancia actual es mayor al limite del comercio, mande alerta
						setServiceStatus(false);
						setServiceStatusMsg('You are not in the commerce');
						setLoading(false);
						// fuera del radio de servicio
						setIsOutRange(true);
						return;
					} else {
						setLoading(false);
						setServiceStatus(true);
						if (!serviceDetails.isPlaybackScheduleActive) {
							setIsBusinessClosed(false);
							return;
						}

						const getServerTime = async () => {
							const path = `server/getTime`;
							return await http.instance.get(path);
						};

						if (serviceDetails !== undefined) {
							let serviceStatus = false;

							getServerTime()
								.then(res => {
									const serverTime = new Date(res.data.result);

									const date =
										serverTime.getUTCDate() > 9
											? serverTime.getUTCDate()
											: `0${serverTime.getUTCDate()}`;
									const month =
										serverTime.getUTCMonth() + 1 > 9
											? serverTime.getUTCMonth() + 1
											: `0${serverTime.getUTCMonth() + 1}`;
									const year = serverTime.getUTCFullYear();

									let serverDate = `${year}/${month}/${date} ${
										new Date(res.data.result).getHours() > 9
											? new Date(res.data.result).getHours()
											: `0${new Date(res.data.result).getHours()}`
									}:${
										new Date(res.data.result).getMinutes() > 9
											? new Date(res.data.result).getMinutes()
											: `0${new Date(res.data.result).getMinutes()}`
									}:00`;

									const { schedule } = serviceDetails;
									const today = {
										dayId: new Date(res.data.result).getDay(),
										hour: new Date(res.data.result).getHours(),
										min: new Date(res.data.result).getMinutes(),
									};

									let todaySchedule = schedule.find(
										item => item.dayId === today.dayId,
									);

									if (todaySchedule) {
										let { hours } = todaySchedule;

										const horarios = hours?.map(hour => {
											const tempStart = {
												hour: Number.parseInt(hour.startHour.split(':')[0]),
												min: Number.parseInt(hour.startHour.split(':')[1]),
											};
											const tempEnd = {
												hour: Number.parseInt(hour.endHour.split(':')[0]),
												min: Number.parseInt(hour.endHour.split(':')[1]),
											};
											return {
												startHour: tempStart,
												endHour: tempEnd,
											};
										});

										horarios.every(horario => {
											const date =
												serverTime.getUTCDate() > 9
													? serverTime.getUTCDate()
													: `0${serverTime.getUTCDate()}`;
											const month =
												serverTime.getUTCMonth() + 1 > 9
													? serverTime.getUTCMonth() + 1
													: `0${serverTime.getUTCMonth() + 1}`;
											const year = serverTime.getUTCFullYear();

											let startDate = `${year}/${month}/${date} ${
												horario.startHour.hour > 9
													? horario.startHour.hour
													: `0${horario.startHour.hour}`
											}:${
												horario.startHour.min > 9
													? horario.startHour.min
													: `0${horario.startHour.min}`
											}:00`;

											let endDate = `${year}/${month}/${date} ${
												horario.endHour.hour > 9
													? horario.endHour.hour
													: `0${horario.endHour.hour}`
											}:${
												horario.endHour.min > 9
													? horario.endHour.min
													: `0${horario.endHour.min}`
											}:00`;

											const validateSchedule =
												new Date(serverDate).valueOf() >
													new Date(startDate).valueOf() &&
												new Date(serverDate).valueOf() <
													new Date(endDate).valueOf();

											if (validateSchedule) {
												serviceStatus = true;

												const todayDate = moment(
													serverDate.replaceAll('/', '-'),
													'YYYY-MM-DD HH:mm:ss',
												);

												const EndDate = moment(
													endDate.replaceAll('/', '-'),
													'YYYY-MM-DD HH:mm:ss',
												);
												const minutesToClose = Math.abs(
													EndDate.diff(todayDate, 'minutes'),
												);

												const estimatedWaitingMinutes =
													+estimatedTime.split(':')[0];

												if (estimatedWaitingMinutes > minutesToClose) {
													sendAboutToClosePushNotification(
														params.commerceId,
													);
													setShowIsAboutToCloseModal(true);
													setIsBusinessAboutToClose(true);
												} else {
													setIsBusinessAboutToClose(false);
												}

												return false;
											} else {
												return true;
											}
										});

										if (!serviceStatus) {
											setIsBusinessClosed(true);
											return;
										}
									} else {
										setIsBusinessClosed(true);
										return;
									}
								})
								.catch(error => {
									setLoading(false);
									setServiceStatus(true);
								});
						} else {
							setIsBusinessClosed(true);
							return;
						}
						return;
					}
				},
				() => {
					setServiceStatus(false);
					setServiceStatusMsg(
						'We were unable to determine your location. Check your device settings to make sure location detection is active.',
					);
					setLoading(false);
				},
				{ enableHighAccuracy: true },
			);
		}
	}, [serviceDetails, commerce, estimatedTime]);

	useEffect(() => {
		if (commerceId !== '') {
			getCommerce();
			getCurrentPlaylist();
		}
	}, [commerceId, getAvailableDevices, getCommerce, getCurrentPlaylist, getCurrentTrack]);

	useEffect(() => {
		setCommerceId(params.commerceId);
	}, [params.commerceId]);

	useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	useEffect(() => {
		eventBus.on('payment', () => {
			setOpenSnack(true);
			if (!localStorage.getItem('rateVersion')) {
				setOpenRate(true);
			}
		});
	}, []);

	const dataIsOnLoading = () => {
		setTimeout(() => {
			setIsDelayed(false);
		}, 1650);
		return (
			!commerce ||
			!commerce?.restrictions ||
			commerce?.restrictions?.length < 1 ||
			loading === true
		);
	};

	const getTemplate = () => {
		// if (commerce.currentProvider === 'Spotify') {
		// 	if (isBusinessInactive) {
		// 		return <OffBusiness></OffBusiness>;
		// 	}
		// }

		if (isJukeboxInactive) {
			return <OffBusiness></OffBusiness>;
		}

		if (isBusinessClosed) {
			return <CloseBusiness></CloseBusiness>;
		}
		if (isOutRange) {
			return <OutOfRangePage></OutOfRangePage>;
		}

		return (
			<div style={{ paddingRight: 34, paddingLeft: 34 }}>
				<GeneralDialog
					open={showDisabledModal}
					button1Text={t('BUTTONS_text_accept')}
					title={t('SEARCH_add_songs_dialog_subtitle')}
					titleStyle={{ maxWidth: '65%', marginBottom: '25px' }}
					subtitleStyle={{ marginBottom: '20px' }}
					crossButtonAction={() => setShowDisabledModal(false)}
					button1Action={() => setShowDisabledModal(false)}
					dialogPaperStyle={{ width: '750px' }}
					handleClose={() => setShowDisabledModal(false)}
				/>
				<GeneralDialog
					open={showIsAboutToCloseModal}
					button1Text={t('BUTTONS_text_accept')}
					title={t('HOME_business_about_to_close')}
					titleStyle={{ maxWidth: '65%', marginBottom: '25px' }}
					subtitleStyle={{ marginBottom: '20px' }}
					crossButtonAction={() => setShowIsAboutToCloseModal(false)}
					button1Action={() => setShowIsAboutToCloseModal(false)}
					dialogPaperStyle={{ width: '750px' }}
					handleClose={() => setShowIsAboutToCloseModal(false)}
				/>
				<Box maxWidth={968} marginX="auto" sx={{ paddingBottom: 50 }}>
					<div className={styles.titleContainer}>
						<Grid container justifyContent="space-between">
							<H1 text={t('HOME_title')} fontSize={size < 429 ? 18 : null} />
							{localStorage.getItem('phone') !== null &&
							localStorage.getItem('phone') !== 'undefined' ? (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<H4
										text={`${t('LOGOUT_current_number')} ${localStorage.getItem(
											'phone',
										)}`}
									/>
									<IconButton
										onClick={() => setOpenDeleteNumber(true)}
										style={{ marginLeft: '10px' }}
									>
										<LogoutIcon
											sx={{
												fontSize: 25,
												color: 'rgb(255, 77, 130)',
											}}
										/>
									</IconButton>
								</div>
							) : localStorage.getItem('email') !== null &&
							  localStorage.getItem('email') !== 'undefined' ? (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<H4
										text={`${t('LOGOUT_current_email')} ${localStorage.getItem(
											'email',
										)}`}
									/>
									<IconButton
										onClick={() => setOpenDeleteNumber(true)}
										style={{ marginLeft: '10px' }}
									>
										<LogoutIcon
											sx={{
												fontSize: 25,
												color: 'rgb(255, 77, 130)',
											}}
										/>
									</IconButton>
								</div>
							) : null}
						</Grid>
					</div>

					<div className={styles.playlist}>
						<div className={styles.playlist}>
							<div className={styles.playlistHeader}>
								<Grid
									container
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									{currentPlaylist.length > 0 && (
										<div style={{ width: '100%' }}>
											<Grid
												style={{
													display: 'flex',
													justifyContent: 'center',
													marginBottom: 50,
													marginTop: 30,
												}}
											>
												<Button
													height={size < 429 ? 38 : null}
													buttonText={t('HOME_add_button')}
													buttonStyle={{ justifyContent: 'center' }}
													icon={
														<Add
															sx={{
																fontSize: 30,
																color: isAddButtonDisabled
																	? '#333A43'
																	: '#FFFFFF',
															}}
														/>
													}
													textStyle={{
														fontSize: size < 429 ? 12 : 16,
													}}
													disabled={isAddButtonDisabled}
													onClick={() => {
														if (isBusinessAboutToClose) {
															setShowIsAboutToCloseModal(true);
															return;
														}
														if (serviceDetails.isJukeboxAI) {
															navigate(`/mode/${params.commerceId}`);
														} else {
															navigate(`/pack/${params.commerceId}`, {
																state: {
																	isLinearPath: true,
																},
															});
														}
													}}
												/>
											</Grid>
											<Grid>
												<H2
													text={t('HOME_subtitle2')}
													fontSize={size < 429 ? 14 : null}
													style={{ marginTop: 8 }}
												/>
											</Grid>
										</div>
									)}
								</Grid>
							</div>
							{currentPlaylist.length > 0 ? (
								currentPlaylist.map((track, index) => (
									<SongCard
										key={track.item._id + '-' + index}
										image={track.item.image}
										name={track.item.name}
										artist={track.item.artist}
										large={true}
										selectable={false}
										duration={getFormatMMSS(track.item.ms)}
										isCurrentTrack={
											Object.entries(currentTrack).length > 0 &&
											commerce.currentProvider === 'Spotify'
												? currentTrack.id === track.item.spotifyId
												: currentTrack.youtubeId === track.item.youtubeId
										}
									/>
								))
							) : (
								<Box
									style={{
										marginTop: 131,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
									maxWidth={422}
									marginX={'auto'}
								>
									<H3
										text={t('HOME_empty_title')}
										fontSize={size < 429 ? 15 : 25}
										style={{
											marginTop: 10,
											textAlign: 'center',
										}}
									/>
									<H4
										text={t('HOME_empty_body')}
										fontSize={size < 429 ? 15 : 25}
										fontWeight={400}
										style={{
											marginTop: size < 429 ? 9 : 0,
											textAlign: 'center',
										}}
										color={colors.blue}
									/>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											marginTop: 15,
										}}
									>
										<Button
											height={size < 429 ? 38 : null}
											buttonText={t('HOME_add_button')}
											buttonStyle={{ justifyContent: 'center' }}
											icon={
												<Add
													sx={{
														fontSize: 30,
														color: isAddButtonDisabled
															? '#333A43'
															: '#FFFFFF',
													}}
												/>
											}
											textStyle={{ fontSize: size < 429 ? 12 : 16 }}
											disabled={isAddButtonDisabled}
											onClick={() => {
												if (isBusinessAboutToClose) {
													setShowIsAboutToCloseModal(true);
													return;
												}
												if (serviceDetails.isJukeboxAI) {
													navigate(`/mode/${params.commerceId}`);
												} else {
													navigate(`/pack/${params.commerceId}`, {
														state: { isLinearPath: true },
													});
												}
											}}
										/>
									</div>
								</Box>
							)}
						</div>
					</div>
				</Box>
				<Snackbar
					open={openSnack}
					setOpen={setOpenSnack}
					message={t('HOME_success_payment_snackbar_text')}
					temporary={true}
				/>
				<GeneralDialog
					open={openDeleteNumber}
					title={
						localStorage.getItem('phone') !== null &&
						localStorage.getItem('phone') !== 'undefined'
							? t('LOGOUT_current_number_delete')
							: t('LOGOUT_current_email_delete')
					}
					titleStyle={{ marginBottom: '40px' }}
					button1Text={t('BUTTONS_text_accept')}
					button1Action={() => {
						localStorage.removeItem('phone');
						localStorage.removeItem('email');
						localStorage.removeItem('session');
						setIsAddButtonDisabled(false);
						setOpenDeleteNumber(false);
					}}
					button2Text={t('BUTTONS_text2')}
					button2Action={() => setOpenDeleteNumber(false)}
					crossButtonAction={() => setOpenDeleteNumber(false)}
				/>
				<Rate
					open={localStorage.getItem('phone') && openRate}
					setOpen={setOpenRate}
					commerceId={commerceId}
				/>

				{Object.keys(currentTrack).length > 0 && (
					<TheFooter
						currentTrack={currentTrack}
						progressBar={progressBar}
						estimatedTime={estimatedTime}
					/>
				)}
			</div>
		);
	};

	return dataIsOnLoading() || isDelayed ? (
		<SplashDialog open={true} size={size} limit={650} />
	) : (
		<>{getTemplate()}</>
	);
}

// console.log(' radius.value', radius.value);
// console.log('distance', distance);
// console.log('radiusCondition', radiusCondition);
// console.log(
// 	'distance - radius.value - 3500',
// 	distance - radius.value - 3500 > radius.value,
// );
