import React from 'react';

import styles from './styles.module.scss';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
const placesLibrary = ['places'];

export default function Input({
	label,
	showLabel = true,
	onChange,
	onBlur,
	onKeyDown,
	showError,
	inputContainerStyle,
	inputStyle,
	labelStyle,
	errorStyle,
	rightIconStyle,
	placeholder,
	errorLabel,
	children,
	type = 'normal',
	rightIcon,
	onClickRightIcon,
	onClickInput,
	size,
	inputRef,
	value,
	autocompleteGoogleMaps = false,
}) {
	const [inputValue, setInputValue] = React.useState('');
	const [searchResult, setSearchResult] = React.useState('Result: none');

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
		libraries: placesLibrary,
	});

	function onLoad(autocomplete) {
		setSearchResult(autocomplete);
	}

	function onPlaceChanged() {
		if (searchResult != null) {
			const place = searchResult.getPlace();
			const formattedAddress = place.formatted_address;
			setInputValue(formattedAddress);
			onChange(formattedAddress);
			// const name = place.name;
			// const status = place.business_status;
			// console.log(place);
		}
	}

	return (
		<div className={styles.inputContainer} style={{ ...inputContainerStyle }}>
			{showLabel && (
				<label
					htmlFor=""
					style={{
						color: '#FFF',
						margin: 0,
						textAlign: 'left',
						marginBottom: '5px',
						fontSize: size < 429 ? 16 : 24,
						fontWeight: 400,
						...labelStyle,
					}}
				>
					{label}
				</label>
			)}

			{type === 'custom' ? (
				children
			) : (
				<div style={{ position: 'relative' }}>
					{autocompleteGoogleMaps && isLoaded ? (
						<Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
							<input
								onChange={event => {
									onChange(event.target.value);
								}}
								onBlur={onBlur}
								className={styles.input}
								style={{ ...inputStyle }}
								placeholder={placeholder}
								onClick={onClickInput}
								ref={inputRef}
								defaultValue={value}
							/>
						</Autocomplete>
					) : (
						<input
							onChange={event => {
								onChange(event);
								setInputValue(event.target.value);
							}}
							onBlur={onBlur}
							className={styles.input}
							style={{ ...inputStyle }}
							placeholder={placeholder}
							onKeyDown={e => onKeyDown && onKeyDown(e)}
							onClick={onClickInput}
							ref={inputRef}
							value={value}
						></input>
					)}

					{rightIcon && (
						<img
							style={{ position: 'absolute', top: 25, right: 25, ...rightIconStyle }}
							src={rightIcon}
							onClick={onClickRightIcon ? onClickRightIcon : null}
						></img>
					)}
				</div>
			)}

			{showError ? (
				<p
					style={{
						color: 'red',
						fontSize: size < 429 ? 12 : 18,
						fontWeight: '300',
						marginTop: 5,
						...errorStyle,
					}}
				>
					{errorLabel}
				</p>
			) : null}
		</div>
	);
}
