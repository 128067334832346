import React from 'react';
import { IconButton } from '@mui/material';

import styles from './styles.module.scss';
import H1 from '../text/H1';
import { ReactComponent as BackIcon } from '../../assets/Back_Icon.svg';

const Header = ({ title, onClick, size, limit }) => {
	return (
		<div className={styles.headerContainer}>
			{onClick && (
				<IconButton onClick={onClick}>
					<BackIcon
						width={size < (limit ? limit : 429) ? 5.63 : 12}
						height={size < (limit ? limit : 429) ? 11.25 : 23}
					/>
				</IconButton>
			)}
			<div className={styles.titleContainer}>
				<H1
					text={title}
					style={{
						textAlign: size < (limit ? limit : 429) ? 'start' : 'center',
						marginLeft: size < (limit ? limit : 429) ? '5px' : '',
					}}
					fontSize={size < (limit ? limit : 429) ? 23 : null}
				/>
			</div>
		</div>
	);
};

export default Header;
