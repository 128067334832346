import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import http from '../../http/http';
import { useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';

import ButtonComponent from '../../components/button/Button';
import CreditCardIcon from '../../components/credit-card-icon/CreditCardIcon';
import { analytics } from '../../firebaseInit';
import Header from '../../components/header/Header';
import H2 from '../../components/text/H2';
import H3 from '../../components/text/H3';
import { eventBus } from '../../utils/event-bus';
import { colors } from '../../utils/colors';
import Loading from '../../components/loading/Loading';
import GeneralDialog from '../../components/dialog/GeneralDialog';
import OTPdialog from '../../components/dialog/OTPdialog';
import Discount_Icon from '../../assets/Discount_Icon.svg';
import Check_Discount from '../../assets/Check_Discount.svg';

export default function VerifyPayment() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const [size, setSize] = React.useState(window.innerWidth);
	const [open, setOpen] = React.useState(false);
	const [showLoader, setShowLoader] = React.useState(false);
	const { customerData } = useSelector(state => state.customer);
	const { tokensFCM } = useSelector(state => state.customer);
	const { commerceData } = useSelector(state => state.commerce);
	const { trackData } = useSelector(state => state.track);
	const [cardType] = React.useState(location.state.cardType);
	const [cardMask] = React.useState(location.state.account.match(/.{1,4}/g));
	const [discountModal, setDiscountModal] = React.useState(false);
	const [discountCode, setDiscountCode] = React.useState('');
	const [discountPercentage, setDiscountPercentage] = React.useState(0);
	const [discountActive, setDiscountActive] = React.useState(false);
	const [discountError, setDiscountError] = React.useState(false);
	const [discountErrorText, setDiscountErrorText] = React.useState();
	const [amount, setAmount] = React.useState(location.state.amount);
	const [promoId, setPromoId] = React.useState(location.state.promoId);
	const [discountValidModal, setDiscountValidModal] = React.useState(false);
	const [openInvalidCardModal, setOpenInvalidCardModal] = React.useState(false);
	const [error, setError] = React.useState('');

	React.useEffect(() => {
		if (!location.state || location.state.isLinearPath !== true) {
			//para validar que el usuario está entrando a través del flujo de la aplicación
			navigate(`/home/${params.commerceId}`);
		}
	}, [location.state]);

	React.useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	const onBackHandler = () => {
		logEvent(analytics, 'cloudJukebox_cancel_verify_payment', {
			tenantId: process.env.REACT_APP_TENANT_ID,
		});
		if (location.state.profileId) {
			navigate(`/home/${params.commerceId}`);
		} else {
			navigate(`/process-payment/${params.commerceId}`, {
				state: {
					...location.state,
				},
			});
		}
	};

	const checkDiscountCode = async () => {
		const dCodeSplited = discountCode.match(/.{1,4}/g);
		const dCode = `${dCodeSplited[0]}-${dCodeSplited[1]}`;

		try {
			const checkDiscountPath = `promotional-code/check`;

			const res = await http.instance.post(checkDiscountPath, {
				code: dCode,
				commerceId: params.commerceId,
				packId: location.state.packId,
				phone:
					Object.keys(customerData).length > 0
						? customerData.phone
						: localStorage.getItem('phone'),
			});

			if (res.data.result) {
				const amountWithDiscount = +amount - (+amount * res.data.result.discount) / 100;
				setAmount(amountWithDiscount.toString());
				setDiscountModal(false);
				setDiscountActive(true);
				setPromoId(res.data.result.promoId);
				setDiscountPercentage(res.data.result.discount);
				setDiscountModal(false);
				setDiscountValidModal(true);
			} else {
				setDiscountError(true);
				setDiscountErrorText(t('ERRORTEXT5'));
				setDiscountError(true);
				setTimeout(() => {
					setDiscountError(false);
				}, 5000);
			}
		} catch (error) {
			setDiscountError(true);
			if (error.response.data.message) {
				setDiscountErrorText(error.response.data.message);
			} else {
				setDiscountErrorText(t('ERRORTEXT5'));
			}
			setTimeout(() => {
				setDiscountError(false);
			}, 5000);
		}
	};

	const onClickPayTokenHandler = async () => {
		try {
			console.log('Making a paymment');
			setError('');
			setShowLoader(true);
			const salePath = `payment/sale-with-token`;
			const transactionPath = `transaction/create`;

			const customer = {
				phone: localStorage.getItem('phone'),
				tokensFCM,
				commerceId: params.commerceId,
			};

			const body = {
				amount: amount.includes('.') ? amount : `${amount}.00`,
				customer: customer,
				profileId: location.state.profileId,
			};
			const resCheckout = await http.instance.post(salePath, body);

			if (resCheckout.data.result.payStatus.respstat === 'A') {
				console.log('Success Waoflex');

				const resTransaction = await http.instance.post(transactionPath, {
					...(promoId && { promoId }),
					packId: location.state.packId,
					customerId: resCheckout.data.result.customerId,
					commerceId: params.commerceId,
					payStatus: { ...resCheckout.data.result.payStatus },
				});

				logEvent(analytics, 'SwagginAppWeb_Packagepayment', {
					commerceId: params.commerceId,
					phone: customerData.phone,
					customerId: resTransaction.data.result.customerId,
					amount: +resCheckout.data.result.payStatus.amount,
					tracksQty: trackData.length,
					tenantId: process.env.REACT_APP_TENANT_ID,
				});

				logEvent(analytics, 'SwagginAppWeb_Addsongclient', {
					commerceId: params.commerceId,
					tenantId: process.env.REACT_APP_TENANT_ID,
				});

				const pathAddToQueue =
					commerceData.currentProvider === 'Spotify'
						? `playlist/add-to-queue?`
						: `youtube/add-to-queue?`;

				const body = {
					commerceId: params.commerceId,
					tracks: trackData,
					customerId: resTransaction.data.result.customerId,
					isFreePack: amount === 0 ? true : false,
				};

				try {
					await http.instance.post(pathAddToQueue, body);

					navigate(`../home/${params.commerceId}`, { replace: true, state: {} });
					setTimeout(() => {
						eventBus.dispatch('payment');
					}, 500);
				} catch (error) {
					console.log(error);
				}
			} else if (resCheckout.data.result.payStatus.respstat === 'B') {
				setError(t('VERIFYPAYMENT_retry_again'));
			} else if (resCheckout.data.result.payStatus.respstat === 'C') {
				if (resCheckout.data.result.payStatus.resptext === 'Wrong expiration') {
					setError(t('VERIFYPAYMENT_declined_wrong_expiration'));
				} else {
					setError(t('VERIFYPAYMENT_declined'));
				}
			} else {
				setError(t('VERIFYPAYMENT_declined'));
			}
			setShowLoader(false);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const onClickPayHandler = async () => {
		console.log('Making a paymment');
		try {
			setShowLoader(true);
			const salePath = `payment/sale`;
			const transactionPath = `transaction/create`;

			const customer = {
				phone: localStorage.getItem('phone'),
				tokensFCM,
				commerceId: params.commerceId,
			};

			const body = {
				amount: amount.includes('.') ? amount : `${amount}.00`,
				expiry: location.state.expiry.replaceAll('/', '').trim(),
				account: location.state.account.replaceAll(' ', '').trim(),
				name: location.state.name,
				cvv2: location.state.cvv2,
				customer: customer,
				tokenization: location.state.tokenizeCard,
				type: location.state.cardType,
				address: location.state.address,
				postal: location.state.postal,
			};
			const resCheckout = await http.instance.post(salePath, body);

			if (resCheckout.data.result.payStatus.respstat === 'A') {
				console.log('Success Waoflex');
				const resTransaction = await http.instance.post(transactionPath, {
					...(promoId && { promoId }),
					packId: location.state.packId,
					customerId: resCheckout.data.result.customerId,
					commerceId: params.commerceId,
					payStatus: { ...resCheckout.data.result.payStatus },
				});

				logEvent(analytics, 'SwagginAppWeb_Packagepayment', {
					commerceId: params.commerceId,
					phone: customerData.phone,
					customerId: resTransaction.data.result.customerId,
					amount: +resCheckout.data.result.payStatus.amount,
					tracksQty: trackData.length,
					tenantId: process.env.REACT_APP_TENANT_ID,
				});

				logEvent(analytics, 'SwagginAppWeb_Addsongclient', {
					commerceId: params.commerceId,
					tenantId: process.env.REACT_APP_TENANT_ID,
				});

				const pathAddToQueue =
					commerceData.currentProvider === 'Spotify'
						? `playlist/add-to-queue?`
						: `youtube/add-to-queue?`;

				const body = {
					commerceId: params.commerceId,
					tracks: trackData,
					customerId: resTransaction.data.result.customerId,
					isFreePack: location.state.amount === 0 ? true : false,
				};

				try {
					await http.instance.post(pathAddToQueue, body);

					navigate(`../home/${params.commerceId}`, { replace: true, state: {} });
					setTimeout(() => {
						eventBus.dispatch('payment');
					}, 500);
				} catch (error) {
					console.log(error);
				}
			} else if (resCheckout.data.result.payStatus.respstat === 'B') {
				setError(t('VERIFYPAYMENT_retry_again'));
			} else if (resCheckout.data.result.payStatus.respstat === 'C') {
				if (resCheckout.data.result.payStatus.resptext === 'Wrong expiration') {
					setError(t('VERIFYPAYMENT_declined_wrong_expiration'));
				} else {
					setError(t('VERIFYPAYMENT_declined'));
				}
			} else {
				setError(t('VERIFYPAYMENT_declined'));
			}

			setShowLoader(false);
		} catch (error) {
			setShowLoader(false);
			console.log('error', error);
			if (error.response.data.message) {
				if (error.response.data.message.some(err => err === 'type should not be empty')) {
					setOpenInvalidCardModal(true);
				}
			}
		}
	};

	return (
		<Container
			style={{
				marginTop: size < 429 ? 30 : 130,
				width: '100%',
				maxWidth: '80%',
			}}
			maxWidth=""
		>
			<OTPdialog
				size={size}
				limit={451}
				open={discountModal}
				handleClose={() => setDiscountModal(false)}
				crossButtonAction={() => setDiscountModal(false)}
				icon={Discount_Icon}
				title={t('VERIFYPAYMENT_dialog_discount_title')}
				buttonText={t('BUTTONS_text_accept')}
				buttonAction={checkDiscountCode}
				code={discountCode}
				onChange={text => {
					setDiscountCode(text);
				}}
				isError={discountError}
				isErrorText={discountErrorText}
			/>

			<GeneralDialog
				open={discountValidModal}
				handleClose={() => setDiscountValidModal(false)}
				crossButtonAction={() => setDiscountValidModal(false)}
				button1Text={t('BUTTONS_text_accept')}
				icon={Check_Discount}
				title={t('VERIFYPAYMENT_dialog_success_discount_title')}
				titleStyle={{
					marginTop: 3,
				}}
				subtitle={`${discountPercentage}%`}
				subtitleStyle={{ marginBottom: 3 }}
				button1Action={() => setDiscountValidModal(false)}
			/>
			<GeneralDialog
				open={openInvalidCardModal}
				handleClose={() => setOpenInvalidCardModal(false)}
				crossButtonAction={() => setOpenInvalidCardModal(false)}
				button1Text={t('BUTTONS_text1')}
				title={t('VERIFYPAYMENT_invalid_card_model_title')}
				titleStyle={{ marginBottom: 6 }}
				button1Action={() => setOpenInvalidCardModal(false)}
			/>

			<Header title={t('VERIFYPAYMENT_title')} size={size} />
			<div style={{ marginTop: size < 429 ? 40 : 50 }}>
				{size < 429 ? (
					<H3
						text={t('VERIFYPAYMENT_form_title')}
						fontWeight={300}
						style={{ marginBottom: size < 429 ? 15 : 78 }}
					/>
				) : (
					<H2
						text={t('VERIFYPAYMENT_form_title')}
						style={{ marginBottom: size < 429 ? 15 : 78 }}
					/>
				)}
				<Grid container columnSpacing={20}>
					<Grid item xs={12} lg={6}>
						<H2
							text={location.state.name}
							fontWeight={size < 429 ? 500 : 400}
							style={{ marginBottom: size < 429 ? 5 : 10 }}
						/>

						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								paddingBottom: 14,
								borderBottom: '1px solid #FFF',
								marginBottom: 40,
							}}
						>
							<H2
								text={`**** **** **** ${cardMask[3]}`}
								fontSize={size < 429 ? 18 : 24}
								fontWeight={300}
							/>
							<CreditCardIcon brand={cardType} width={60} />
						</div>
						<H2
							text={t('PROCESSPAYMENT_form_input_address')}
							fontWeight={size < 429 ? 500 : 400}
							style={{ marginBottom: size < 429 ? 5 : 10 }}
						/>

						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								paddingBottom: 14,
								borderBottom: '1px solid #FFF',
								marginBottom: 40,
							}}
						>
							<H2
								text={location.state.address}
								fontSize={size < 429 ? 18 : 24}
								fontWeight={300}
							/>
						</div>
						<H2
							text={t('PROCESSPAYMENT_form_input_postal')}
							fontWeight={size < 429 ? 500 : 400}
							style={{ marginBottom: size < 429 ? 5 : 10 }}
						/>

						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								paddingBottom: 14,
								borderBottom: '1px solid #FFF',
							}}
						>
							<H2
								text={location.state.postal}
								fontSize={size < 429 ? 18 : 24}
								fontWeight={300}
							/>
						</div>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Grid
							container
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								marginTop: 20,
							}}
						>
							{trackData.map(track => (
								<Grid item xs={5} md={2}>
									<div style={{ display: 'flex' }}>
										<img src={track.image} width={'100%'}></img>
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<div style={{ width: '100%' }}>
											<p
												style={{
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													fontWeight: 'bold',
													marginBottom: 0,
												}}
											>
												{track.name}
											</p>
											<p
												style={{
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
											>
												{track.artist}
											</p>
										</div>
									</div>
								</Grid>
							))}
						</Grid>
						<H2
							text={t('VERIFYPAYMENT_form_pay_label')}
							fontSize={size < 429 ? 16 : 24}
							fontWeight={400}
							style={{ marginBottom: 20, marginTop: 23 }}
						/>
						<span
							style={{
								padding: 10,
								width: 74,
								height: 43,
								backgroundColor: '#D9D9D929',
								borderRadius: '4px',
								fontWeight: 'bold',
								fontSize: '24px',
								color: '#FFF',
							}}
						>
							US${amount}
						</span>

						{location.state.profileId && (
							<button
								style={{
									display: 'flex',
									background: 'transparent',
									border: 'none',
									marginTop: size < 429 ? 21 : 31,
								}}
								onClick={() => setDiscountModal(true)}
								disabled={discountActive}
							>
								<H3
									text={t('PROCESSPAYMENT_discount_button')}
									fontSize={size < 429 ? 16 : 20}
									fontWeight={300}
									style={{ textDecoration: 'underline' }}
								/>
								{discountActive && (
									<img src={Check_Discount} s style={{ marginLeft: 10 }}></img>
								)}
							</button>
						)}
					</Grid>
				</Grid>
			</div>

			<div style={{ display: 'flex', marginTop: 20 }}>
				<H3
					text={error}
					fontSize={size < 429 ? 16 : 20}
					fontWeight={300}
					style={{ color: 'red' }}
				/>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: 100,
					marginBottom: 20,
				}}
			>
				<ButtonComponent
					buttonText={t('VERIFYPAYMENT_button_back')}
					height={size < 429 ? 52 : 60}
					width={size < 429 ? 170 : 300}
					backgroundColor={colors.pink}
					onClick={onBackHandler}
					textStyle={{ fontSize: size < 429 ? 18 : 24, fontWeight: 700 }}
					buttonStyle={{ marginRight: 20 }}
				/>
				<ButtonComponent
					buttonText={t('VERIFYPAYMENT_button_pay')}
					height={size < 429 ? 52 : 60}
					width={size < 429 ? 170 : 300}
					backgroundColor={colors.blue}
					onClick={
						location.state?.isNotTokenized ? onClickPayHandler : onClickPayTokenHandler
					}
					textStyle={{ fontSize: size < 429 ? 18 : 24, fontWeight: 700 }}
					showLoader={showLoader}
				/>
			</div>
			<Loading open={open} handleClose={() => setOpen(false)} />
		</Container>
	);
}
