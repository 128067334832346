export const getFormatMMSS = ms => {
	let min = Math.floor(ms / (1000 * 60)) % 60;
	let seg = Math.floor(ms / 1000) % 60;

	if (min < 10) {
		min = `0${min}`;
	}
	if (seg < 10) {
		seg = `0${seg}`;
	}

	if (!min || !seg) {
		return undefined;
	}
	return `${min}:${seg}`;
};
