import React from 'react';
import Typography from '@mui/material/Typography';

const ButtonText = ({ text, fontSize, fontWeight, color, style }) => {
	return (
		<Typography
			style={{
				fontSize: fontSize ?? 16,
				fontWeight: fontWeight ?? 700,
				color: color ?? '#FFF',
				textTransform: 'none',
				...style,
			}}
		>
			{text}
		</Typography>
	);
};

export default ButtonText;
