import React from 'react';
import { Button } from '@mui/material';
import { ReactComponent as SpotifyIcon } from '../../assets/svg/Spotify_Logo_CMYK_Green.svg';
import H1 from '../text/H1';
import H6 from '../text/H6';
import H4 from '../text/H4';

export default function SpotifyButton({ width, height, buttonStyle }) {
	const [size, setSize] = React.useState(window.innerWidth);
	return (
		<div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
			<Button
				onClick={() => window.open('http://spotify.com')}
				style={{
					width: size < 429 ? '200px' : '250px',
					height: size < 429 ? '38px' : '48px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '10px',
					textDecoration: 'none',
					border: '1px solid #1DB954',
					padding: '25px 0px',
					...buttonStyle,
				}}
			>
				<SpotifyIcon />
				<H4
					text={'Open Spotify'}
					style={{
						color: '#1AB26B',
						marginLeft: '5px',
						textTransform: 'none',
						fontFamily: 'Arial',
					}}
					fontSize={18}
				/>
			</Button>
		</div>
	);
}
