import React from 'react';

export default function Visa({ width }) {
	return (
		<svg width={width} viewBox="0 0 780 500" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M40 0h700c22 0 40 18 40 40v420c0 22-18 40-40 40H40c-22 0-40-18-40-40V40C0 18 18 0 40 0z"
				fill="#0E4595"
			/>
			<path
				d="M293 349l34-196h53l-33 196h-54zm246-192c-10-4-27-8-48-8-52 0-89 27-90 65 0 28 27 43 47 53 21 9 28 15 28 24 0 13-17 19-32 19-22 0-33-3-50-10l-7-3-8 44c13 5 36 10 60 10 56 0 92-26 93-67 0-22-14-39-45-53-19-9-30-15-30-24 0-8 9-17 30-17 18 0 30 3 40 7l5 3 7-43m138-4h-42c-12 0-22 3-28 16l-79 180h56l11-30h69l6 30h50l-43-196zm-66 126l21-54 8-19 3 17 12 56h-44zM248 153l-52 133-6-27c-10-31-40-65-74-82l48 171h56l84-195h-56"
				fill="#fff"
			/>
			<path
				d="M147 153H61l-1 4c67 16 111 55 130 102l-19-90c-3-12-12-16-24-16"
				fill="#F2AE14"
			/>
		</svg>
	);
}
