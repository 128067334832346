import { Dialog, DialogContent, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import { ReactComponent as CrossIcon } from '../../assets/Cross_Icon.svg';

const VoidDialog = ({
	open,
	handleClose,
	title,
	crossButtonAction,
	dialogStyle,
	dialogPaperStyle,
	crossStyle,
	childrenStyles,
	...props
}) => {
	const [size, setSize] = React.useState(window.innerWidth);

	React.useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			sx={{
				'& .MuiDialog-paper': {
					backgroundColor: 'rgba(45, 108, 206, 0.2)',
					backdropFilter: 'blur(10px)',
					maxWidth: '100%',
					width: '700px',
					height: '585px',
					borderRadius: '25px',
					border: '1px solid #DDDDDD',
					padding: '15px',
					'& .MuiDialogContent-root': {
						...(size < 429 && { padding: 0 }),
					},
					...dialogPaperStyle,
				},
				background: 'rgba(21, 33, 47, 0.97)',

				...dialogStyle,
			}}
		>
			<DialogContent>
				<Grid container sx={{ height: '100%', width: '100%' }} spacing={0}>
					<Grid
						item
						container
						justifyContent={title ? 'space-between' : 'flex-end'}
						alignItems="baseline"
						alignSelf="flex-start"
						sx={{ height: '5%', marginBottom: '10%' }}
					>
						{title ? (
							<Typography
								sx={{
									fontFamily: 'Inter',
									fontSize: size < 429 ? '13px' : '19px',
									fontWeight: '500',
								}}
							>
								{title}
							</Typography>
						) : null}
						<IconButton onClick={crossButtonAction}>
							<CrossIcon width={13} height={13} style={{ ...crossStyle }} />
						</IconButton>
					</Grid>
					<Grid
						item
						container
						justifyContent="center"
						alignItems="flex-start"
						sx={{
							height: '83%',
							width: '100%',
							overflowX: 'hidden',
							overflowY: 'auto',
							padding: 0,
							margin: 0,
							...childrenStyles,
						}}
					>
						{props.children}
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default VoidDialog;
