import { Typography, Container, Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import ButtonComponent from '../../components/button/Button';
import Header from '../../components/header/Header';
import { colors } from '../../utils/colors';
import globalStyles from '../../utils/globalStyles.module.scss';
import { analytics } from '../../firebaseInit';
import { logEvent } from 'firebase/analytics';

const SelectMode = () => {
	const { t } = useTranslation();
	const [size, setSize] = React.useState(window.innerWidth);
	const params = useParams();
	const navigate = useNavigate();
	const options = [
		{
			name: t('SELECTMODE_traditional_option'),
			navigation: () => {
				logEvent(analytics, 'SwagginAppWebWeb_Traditionalsearch', {
					commerceId: params.commerceId,
					tenantId: process.env.REACT_APP_TENANT_ID,
				});
				navigate(`/pack/${params.commerceId}`, {
					state: { isLinearPath: true },
				});
			},
		},
		{
			name: t('SELECTMODE_AI_option'),
			navigation: () => {
				logEvent(analytics, 'SwagginAppWeb_AIsearch', {
					commerceId: params.commerceId,
					tenantId: process.env.REACT_APP_TENANT_ID,
				});
				navigate(`/AI/${params.commerceId}`);
			},
		},
	];

	useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	return (
		<div>
			<Container style={{ marginTop: size < 429 ? 30 : 80, width: '100%' }}>
				<Header
					title={t('SELECTMODE_header')}
					size={size}
					limit={541}
					onClick={() => navigate(`/home/${params.commerceId}`)}
				/>
				<div
					style={{
						marginTop: 120,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{options.map((option, index) => (
						<Box
							key={index}
							style={{
								borderRadius: '6px',
								padding: size < 541 ? '20px 40px' : '30px 60px',
								width: '100%',
								marginBottom: 50,
								border: '1px solid #FFF',
								display: 'flex',
								flexDirection: size < 541 ? 'column-reverse' : 'row',
								backgroundColor: 'rgba(255,255,255,0.1)',
								borderColor: 'rgba(255,255,255,0.1)',
							}}
							onClick={() => option.navigation()}
						>
							<div
								style={{
									width: size < 541 ? '100%' : '30%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<ButtonComponent
									backgroundColor={colors.blue}
									buttonText={option.name}
									height={60}
									textStyle={{
										fontFamily: globalStyles.fontFamily.fontFamily,
										fontSize: size < 541 ? 16 : 18,
									}}
									width={size < 541 ? '92%' : '90%'}
								></ButtonComponent>
							</div>
							<div style={{ width: size < 541 ? '100%' : '70%' }}>
								<Typography
									style={{
										fontSize: size < 541 ? 18 : 24,
										fontWeight: 400,
										color: '#FFF',
										textAlign: size < 541 ? 'center' : 'left',
										marginBottom: size < 541 ? 10 : 0,
									}}
								>
									{index === 0
										? t('SELECTMODE_traditional_option_description')
										: t('SELECTMODE_AI_option_description')}
								</Typography>
							</div>
						</Box>
					))}
					<ButtonComponent
						backgroundColor={colors.pink}
						buttonText={t('BUTTONS_text2')}
						height={60}
						buttonStyle={{
							marginTop: '80px',
						}}
						textStyle={{
							fontFamily: globalStyles.fontFamily.fontFamily,
							fontSize: size < 541 ? 18 : 25,
						}}
						width={size < 541 ? '92%' : '55%'}
						onClick={() => navigate(`/home/${params.commerceId}`)}
					></ButtonComponent>
				</div>
			</Container>
		</div>
	);
};

export default SelectMode;
