import React from 'react';
import { Button, CircularProgress } from '@mui/material';

import { colors } from '../../utils/colors';
import ButtonText from '../text/ButtonText';

const ButtonComponent = ({
	buttonText,
	width,
	height,
	backgroundColor,
	icon,
	onClick,
	buttonStyle,
	textStyle,
	showLoader,
	disabled,
}) => {
	const getBackgroundColor = () => {
		if (disabled) {
			return colors.disable;
		}

		return backgroundColor ?? colors.blue;
	};

	const getTextColor = () => {
		if (disabled) {
			return colors.textDisable;
		}

		return '';
	};

	return (
		<Button
			onClick={onClick}
			disabled={disabled}
			style={{
				width: width ?? '183px',
				height: height ?? '48px',
				backgroundColor: getBackgroundColor(),
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '5px',
				textDecoration: 'none',
				...buttonStyle,
			}}
		>
			{!showLoader ? (
				<ButtonText text={buttonText} style={{ ...textStyle, color: getTextColor() }} />
			) : (
				<CircularProgress
					color="inherit"
					size={30}
					style={{ marginTop: 5, color: '#FFF' }}
				/>
			)}
			{icon}
		</Button>
	);
};

export default ButtonComponent;
