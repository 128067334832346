import React from 'react';
import { Button } from '@mui/material';
import { ReactComponent as YoutubeIcon } from '../../assets/svg/YouTube-Logo.wine.svg';
import H1 from '../text/H1';
import H6 from '../text/H6';
import H4 from '../text/H4';

export default function YoutubeButton({ width, height, buttonStyle }) {
	const [size, setSize] = React.useState(window.innerWidth);
	return (
		<div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
			<Button
				onClick={() => window.open('http://youtube.com')}
				style={{
					width: size < 429 ? '200px' : '250px',
					height: size < 429 ? '38px' : '48px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '10px',
					textDecoration: 'none',
					padding: '25px 0px',
					...buttonStyle,
				}}
			>
				<YoutubeIcon />
			</Button>
		</div>
	);
}
