import { createSlice } from '@reduxjs/toolkit';

const customerSlice = createSlice({
	name: 'customer',
	initialState: {
		customerData: {},
		tokensFCM: [],
	},
	reducers: {
		addCustomerData: (state, action) => ({
			...state,
			customerData: { ...action.payload },
		}),
		addTokensFCM: (state, action) => ({
			...state,
			tokensFCM: [...action.payload],
		}),
	},
});

export const { addCustomerData, addTokensFCM } = customerSlice.actions;

export default customerSlice.reducer;
