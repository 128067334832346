import React, { useEffect } from 'react';
import { Snackbar as MuiSnackbar, Slide } from '@mui/material';

const TransitionRight = props => {
	return <Slide {...props} direction="right" />;
};

const Snackbar = ({
	open,
	setOpen,
	onClose,
	message,
	action,
	snackbarStyle,
	snackbarPaperStyle,
	anchorOrigin,
	timeOut,
	temporary,
}) => {
	return (
		<MuiSnackbar
			sx={{
				width: '100%',
				...snackbarStyle,
				'& .MuiSnackbarContent-root': {
					width: '100%',
					marginX: '15px',
					background: '#486CCB',
					borderRadius: '5px',
					color: '#FFFFFF',
					fontFamily: 'Inter',
					fontWeight: '700',
					fontSize: '20px',
					paddingX: '4%',
					...snackbarPaperStyle,
				},
			}}
			open={open}
			onClose={() => {
				if (onClose) {
					onClose();
				}
				if (setOpen) {
					setOpen(false);
				}
			}}
			message={message}
			action={action}
			anchorOrigin={
				anchorOrigin ? anchorOrigin : { vertical: 'bottom', horizontal: 'center' }
			}
			TransitionComponent={TransitionRight}
			autoHideDuration={temporary ? (timeOut ? timeOut : 3000) : undefined}
		/>
	);
};

export default Snackbar;
