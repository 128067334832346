import { Dialog, Grid } from '@mui/material';

import { ReactComponent as LogoSwagginXl } from '../../assets/LogoSwagginXl.svg';

const SplashDialog = ({ open, size, limit }) => {
	return (
		<Dialog
			open={open}
			fullScreen
			sx={{
				'& .css-m9glnp-MuiPaper-root-MuiDialog-paper': {
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					backgroundAttachment: 'fixed',
					position: 'relative',
				},
			}}
		>
			<img
				src={require('../../assets/splash.png')}
				alt={'SplashDialog'}
				style={{
					width: '100vw',
					height: '100vh',
					objectFit: 'cover',
					objectPosition: 'right',
				}}
			/>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				sx={{ height: '100%', position: 'absolute' }}
			>
				<LogoSwagginXl
					width={size < (limit ? limit : 429) ? 196 : 513.07}
					height={size < (limit ? limit : 429) ? 55.02 : 144.02}
				/>
			</Grid>
		</Dialog>
	);
};

export default SplashDialog;
