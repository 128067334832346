class TokenSingleton {
	static instance = new TokenSingleton();

	constructor() {
		if (typeof TokenSingleton.instance === 'object') {
			return TokenSingleton.instance;
		}

		TokenSingleton.instance = this;
		return this;
	}

	setToken(appCheckToken) {
		this.appCheckToken = appCheckToken;
	}

	getToken() {
		return this.appCheckToken;
	}
}

export default TokenSingleton;
