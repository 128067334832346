import { Container, Grid, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import http from '../../http/http';

import { logEvent } from 'firebase/analytics';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as BackIcon } from '../../assets/Back_Icon.svg';
import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import H1 from '../../components/text/H1';
import H2 from '../../components/text/H2';
import H3 from '../../components/text/H3';
import H6 from '../../components/text/H6';
import { analytics } from '../../firebaseInit';
import i18n from '../../i18n';
import { colors } from '../../utils/colors';
const CheckoutEmail = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const params = useParams();
	const [size, setSize] = React.useState(window.innerWidth);
	const [phone, setPhone] = React.useState('');
	const [showLoader, setShowLoader] = React.useState(false);
	const [errorEmail, setErrorEmail] = React.useState();
	const [errorEmailText, setErrorEmailText] = React.useState();

	React.useEffect(() => {
		function resizeListener() {
			setSize(window.innerWidth);
		}
		window.addEventListener('resize', resizeListener);
	}, []);

	const location = useLocation();

	React.useEffect(() => {
		if (!location.state || location.state.isLinearPath !== true) {
			//para validar que el usuario está entrando a través del flujo de la aplicación
			navigate(`/home/${params.commerceId}`);
		}
	}, [location.state]);

	const onSubmitHandler = async () => {
		try {
			setShowLoader(true);
			const res = await checkLastAccess();

			const path = `otp/verification-web/email`;

			const code = await http.instance.post(path, {
				email: values.email,
				language: i18n.language,
			});

			if (res.data.result) {
				setShowLoader(false);
				navigate(`/confirm-phone/${params.commerceId}`, {
					state: {
						isLinearPath: true,
						email: values.email,
						amount: location.state.amount,
						...(location.state.packId && { packId: location.state.packId }),
						mode: location.state.mode,
						code: code.data.result,
						isSwagginAI: location.state.isSwagginAI ?? false,
						backState: location.state.backState,
					},
				});
			} else {
				setShowLoader(false);
			}
		} catch (error) {
			console.log(error);
			setShowLoader(false);
			setErrorEmail(true);
			setErrorEmailText(error.response.data.message ?? t('CHECKOUT_input_email_error'));
			setTimeout(() => {
				setErrorEmail(false);
			}, 5000);
		}
	};

	const EmailSchema = Yup.object().shape({
		email: Yup.string().required(t('ERRORTEXT1')).email(t('ERRORTEXT8')),
	});

	const { setFieldTouched, values, setFieldValue, handleSubmit, errors, touched } = useFormik({
		initialValues: {
			email: '',
		},
		onSubmit: onSubmitHandler,
		validationSchema: EmailSchema,
	});

	const checkLastAccess = () => {
		const path = `customer/check-last-access`;
		const body = {
			phone: phone,
			commerceId: params.commerceId,
		};

		return http.instance.post(path, body);
	};

	const onBackHandler = () => {
		logEvent(analytics, 'cloudJukebox_cancel_enter_phone', {
			tenantId: process.env.REACT_APP_TENANT_ID,
		});
		navigate(-1, { state: location.state?.backState });
	};
	return (
		<Container maxWidth="lg" sx={{ height: '100vh' }}>
			<Grid
				container
				alignItems={size < 429 ? 'flex-start' : 'center'}
				sx={{ height: '100%' }}
			>
				<Grid container justifyContent="center" alignItems="center">
					{size < 429 ? (
						<Grid container sx={{ paddingY: '10%' }}>
							<IconButton onClick={onBackHandler}>
								<BackIcon width={5.63} height={11.25} />
							</IconButton>
						</Grid>
					) : null}
					<Grid item>
						<img
							src={require('../../assets/swaggin.png')}
							alt="swaggin svg"
							width={69}
							height={90}
							style={{}}
						/>
					</Grid>
					<Grid item xs={12}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							{size > 429 ? (
								<IconButton onClick={onBackHandler}>
									<BackIcon
										width={size < 429 ? 5.63 : 12}
										height={size < 429 ? 11.25 : 23}
									/>
								</IconButton>
							) : null}

							{size < 429 ? (
								<H2
									text={t('CHECKOUT_title')}
									style={{ width: '100%', textAlign: 'center' }}
								/>
							) : (
								<H1
									text={t('CHECKOUT_title')}
									style={{ width: '100%', textAlign: 'center' }}
								/>
							)}
						</div>
					</Grid>

					<Grid container justifyContent="center">
						<div style={{ maxWidth: 635, width: '100%', marginTop: 70 }}>
							{size < 429 ? (
								<H6
									text={t('CHECKOUT_email_input_label')}
									fontWeight={300}
									style={{ textAlign: 'center' }}
								/>
							) : (
								<H2 text={t('CHECKOUT_email_input_label')} fontWeight={400} />
							)}
							<Input
								size={size}
								inputStyle={{
									borderWidth: 0,
									borderBottom: '1px solid #FFFFFF',
									borderRadius: 0,
									fontSize: size < 429 ? 18 : 24,
									fontWeight: 300,
									height: size < 429 ? '45px' : '',
								}}
								onChange={e => {
									if (e.target.value.length === 1) {
										setFieldTouched('email', true);
									}
									setFieldValue('email', e.target.value);
								}}
								onBlur={() => {
									setFieldTouched('email', true);
								}}
								placeholder={t('CHECKOUT_email_placeholder')}
								showError={errors.email && touched.email}
								errorLabel={errors.email}
								value={values.email}
							/>
							{errorEmail && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'Left',
										marginTop: 5,
									}}
								>
									{size < 429 ? (
										<H6
											text={errorEmailText}
											fontWeight={100}
											color="red"
											style={{
												textAlign: 'center',
											}}
										/>
									) : (
										<H3
											text={errorEmailText}
											fontWeight={100}
											color="red"
											style={{
												textAlign: 'center',
											}}
										/>
									)}
								</div>
							)}
						</div>
					</Grid>

					<Grid container justifyContent="center">
						<Button
							buttonText={t('CHECKOUT_button')}
							width={size < 429 ? '100%' : 590}
							height={size < 429 ? 52 : 60}
							backgroundColor={colors.blue}
							onClick={handleSubmit}
							buttonStyle={{ marginTop: size < 429 ? 45 : 96 }}
							textStyle={{ fontSize: size < 429 ? 18 : 24 }}
							showLoader={showLoader}
							disabled={errors.email ? true : false}
						/>
					</Grid>

					<p
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							fontSize: size < 429 ? 14 : 20,
							fontWeight: size < 429 ? 200 : 300,
							width: '100%',
							maxWidth: 587,
							textAlign: 'center',
							marginTop: 20,
						}}
					>
						{t('CHECKOUT_another_method_email')}{' '}
						<p
							onClick={() =>
								navigate(`/checkout/${params.commerceId}`, {
									state: {
										isLinearPath: true,
										amount:
											location.state.mode === 'free'
												? 0
												: location.state.backState.packPrice.toString(),
										packId: location.state.backState.packId,
										mode: location.state.mode,
										backState: location.state.backState,
									},
								})
							}
							style={{
								color: '#FFF',
								marginLeft: 10,
								cursor: 'pointer',
								borderBottom: '1px solid white',
							}}
							target="_blank"
						>
							{t('CHECKOUT_another_method_try_another')}
						</p>{' '}
					</p>
				</Grid>
			</Grid>
		</Container>
	);
};

export default CheckoutEmail;
