import {
	Dialog,
	DialogContent,
	DialogContentText,
	IconButton,
	Grid,
	Button,
	Typography,
	Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import CustomButton from '../button/Button';
import { ReactComponent as CrossIcon } from '../../assets/Cross_Icon.svg';
import CredicardIcon from '../credit-card-icon/CreditCardIcon';

const UserCardsDialog = ({
	open,
	handleClose,
	dialogStyle,
	dialogPaperStyle,
	crossStyle,
	titleStyle,
	buttonStyle,
	crossButtonAction,
	buttonAction,
	cards,
	cardAction,
	cardRemoveButtonAction,
	size,
	limit,
}) => {
	const { t } = useTranslation();

	const CardItem = ({ holder, mask, type, action, removeAction }) => {
		return (
			<Grid container sx={{ width: '100%', margin: '50px 0', height: '20%' }}>
				<Button
					onClick={action}
					sx={{
						fontFamily: 'Inter',
						color: '#FFF',
						fontSize: size && size < (limit ? limit : 429) ? '17px' : '20px',
						fontWeight: '500',
						textTransform: 'none',
						padding: 0,
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
					}}
				>
					<p style={{ width: '100%', margin: 0, textAlign: 'left' }}>{holder}</p>
					<Grid
						container
						justifyContent="space-between"
						style={{ borderBottom: '1px solid #FFFFFF' }}
					>
						<Typography
							sx={{
								fontFamily: 'Inter',
								color: '#FFF',
								fontSize: size && size < (limit ? limit : 429) ? '18px' : '21px',
								fontWeight: '300',
							}}
						>{`**** **** **** ${mask}`}</Typography>
						<Grid item sx={{ marginRight: '10px', paddingBottom: '10px' }}>
							<CredicardIcon brand={type} width={38} />
						</Grid>
					</Grid>
				</Button>
				<Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
					<Button
						onClick={removeAction}
						sx={{
							fontFamily: 'Inter',
							fontWeight: '400',
							fontSize: size && size < (limit ? limit : 429) ? '13px' : '15px',
							color: '#FF0000CC',
							textDecoration: 'underline',
							textTransform: 'none',
						}}
					>
						{t('REGISTEREDNUMBER_dialog_remove_button')}
					</Button>
				</Grid>
			</Grid>
		);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			sx={{
				'& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
					margin: size && size < (limit ? limit : 429) ? '15px' : '',
				},

				'& .MuiDialog-paper': {
					backgroundColor: 'rgba(45, 108, 206, 0.2)',
					backdropFilter: 'blur(10px)',
					maxWidth: '100%',
					width: '751px',
					height: size && size < (limit ? limit : 429) ? '633px' : '750px',
					borderRadius: '25px',
					border: '1px solid #DDDDDD',
					paddingBottom: '10px',
					...dialogPaperStyle,
				},
				background: 'rgba(21, 33, 47, 0.97)',
				...dialogStyle,
			}}
		>
			<DialogContent>
				<Grid container justifyContent="center" sx={{ height: '100%' }}>
					<Grid container justifyContent="flex-end" alignSelf="flex-start"></Grid>

					<Grid item xs={12}>
						<DialogContentText
							sx={{
								color: '#FFF',
								textAlign: 'center',
								fontFamily: 'Inter',
								fontWeight: size && size < (limit ? limit : 429) ? '300' : '400',
								fontSize: size && size < (limit ? limit : 429) ? '20px' : '22px',
								margin: 'auto',
								...titleStyle,
							}}
						>
							{t('REGISTEREDNUMBER_dialog_title')}
						</DialogContentText>
					</Grid>

					<Grid
						container
						sx={{
							width: size && size < (limit ? limit : 429) ? '100%' : '80%',
							height: '300px',
							overflowY: 'auto',

							'&::-webkit-scrollbar': {
								width: '0.1em',
							},
							'&::-webkit-scrollbar-track': {
								boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
								webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: 'rgba(0,0,0,.1)',
								outline: '1px solid slategrey',
							},
						}}
					>
						{cards?.map((item, index) => {
							return (
								<CardItem
									key={index}
									holder={item.holder}
									mask={item.mask}
									type={item.type}
									action={() => cardAction(item)}
									removeAction={() => cardRemoveButtonAction(item)}
								/>
							);
						})}
					</Grid>

					<CustomButton
						onClick={buttonAction}
						width="80%"
						buttonText={t('REGISTEREDNUMBER_dialog_button')}
						textStyle={{
							fontFamily: 'Inter',
							fontSize: size && size < (limit ? limit : 429) ? '18px' : '24px',
							...buttonStyle,
						}}
					/>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default UserCardsDialog;
